import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { languageConfig } from 'src/environments/language.config';

@Component({
  selector: 'mb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  public currentLanguage: string;

  public languages = languageConfig.languages.map((isoLang) => ({
    isoLang,
    label: isoLang.split('-')[0].toUpperCase(),
  }));

  constructor(
    public translateService: TranslateService,
    private router: Router
  ) {
    this.currentLanguage = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
    });
  }

  get showCCPALink() {
    return this.translateService.currentLang === 'en-US';
  }

  get supportUrl() {
    const subject = this.translateService.instant('FOOTER.SUPPORT.SUBJECT');
    return new URL(`mailto:mb.media.support@appsfactory.de?subject=${subject}`).toString();
  }

  public changeLanguage(language: string): void {
    this.translateService.use(language);
  }

  public wbLinkClick(event: Event, routerLink: string[], extras?: NavigationExtras): void {
    event.stopPropagation();
    event.preventDefault();
    this.router.navigate(routerLink, extras);
  }
}
