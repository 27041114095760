import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

export type Breadcrumb = {
  label: string;
  link: string;
};

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _breadcrumbs: Breadcrumb[] = [];
  private _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>(this._breadcrumbs);

  public breadcrumbs$ = this._breadcrumbs$.asObservable();
  public keepBreadcrumbs = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const activatedChild = this.lastChild(this.activatedRoute);

        // do not clear breadcrumbs if previous page wants to keep breadcrumbs and
        // current page accepts kept breadcrumbs
        if (!(this.keepBreadcrumbs && activatedChild.snapshot.data.acceptsKeptBreadcrumbs)) {
          this._breadcrumbs = [];
        }
        this.buildBreadcrumbs(this.activatedRoute);
        this._breadcrumbs$.next(this._breadcrumbs);

        // remember this route in order to decide if breadcrumbs should be kept for next navigation
        this.keepBreadcrumbs = activatedChild.snapshot.data.keepBreadcrumbs;
      }
    });
  }

  // Set breadcrumbs for current page, if not feasable via router
  public setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    this._breadcrumbs = breadcrumbs;
    this._breadcrumbs$.next(this._breadcrumbs);
  }

  // Swap last breadcrumb with new breadcrumb (translation without navigation)
  public swapBreadcrumbs(breadcrumbs: Breadcrumb | Breadcrumb[] | undefined) {
    if (breadcrumbs) {
      if (Array.isArray(breadcrumbs)) {
        // swap last breadcrumbs with new breadcrumbs
        this._breadcrumbs.splice(0, breadcrumbs.length, ...breadcrumbs);
      } else {
        this._breadcrumbs.pop();
        this._breadcrumbs.push(breadcrumbs);
      }

      this._breadcrumbs$.next(this._breadcrumbs);
    }
  }

  public rebuildBreadcrumbs() {
    this._breadcrumbs = [];
    this.buildBreadcrumbs(this.activatedRoute);
    this._breadcrumbs$.next(this._breadcrumbs);
  }

  public getBreadcrumbString(separator: string): string {
    return this._breadcrumbs.map((breadcrumb) => this.translateService.instant(breadcrumb.label)).join(separator);
  }

  private lastChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.lastChild(activatedRoute.firstChild);
    }
    return activatedRoute;
  }

  private buildBreadcrumbs(activatedRoute: ActivatedRoute) {
    if (activatedRoute) {
      if (Array.isArray(activatedRoute.snapshot.data.breadcrumb)) {
        // topic routes pre-create their breadcrumbs array
        this._breadcrumbs.push(...activatedRoute.snapshot.data.breadcrumb);
      } else if (activatedRoute.snapshot.data.breadcrumb) {
        // this is either a fixed breadcrumb for static routes, or a single breadcrumb resolved
        const lastBreadcrumbLink =
          this._breadcrumbs.length !== 0 ? this._breadcrumbs[this._breadcrumbs.length - 1].link : '';

        this._breadcrumbs.push({
          label: activatedRoute.snapshot.data.breadcrumb.label,
          link: lastBreadcrumbLink + '/' + activatedRoute.snapshot.data.breadcrumb.link,
        } as Breadcrumb);
      }

      if (activatedRoute.firstChild !== null) {
        this.buildBreadcrumbs(activatedRoute.firstChild);
      }
    }
  }
}
