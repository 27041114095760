import { Component } from '@angular/core';

/**
 * Small blue dot
 */
@Component({
  selector: 'mb-live-indicator',
  template: ``,
  styleUrls: ['./live-indicator.component.scss'],
})
export class LiveIndicatorComponent {}
