import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@modules/shared/shared.module';

import { FooterComponent } from './footer/footer.component';
import { FlyInComponent } from './header/fly-in/fly-in.component';
import { HeaderBarComponent } from './header/header-bar/header-bar.component';
import { HeaderSearchInputFieldComponent } from './header/header-search-input-field/header-search-input-field.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [HeaderComponent, HeaderSearchInputFieldComponent, HeaderBarComponent, FlyInComponent, FooterComponent],
  exports: [HeaderComponent, FooterComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BaseModule {}
