import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const baseImports = [
  FormsModule,
  MatInputModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  ReactiveFormsModule,
  MatSlideToggleModule,
  MatRadioModule,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...baseImports],
  exports: [...baseImports],
})
export class MaterialFormsModule {}
