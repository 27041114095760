import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { MyMBMediaModalComponent } from '@modules/shared/modal/my-mb-media-modal/my-mb-media-modal..component';
import { NavigationMenu } from '@modules/shared/schemas/navigation-menu.schema';
import { Breadcrumb, BreadcrumbService } from '@modules/shared/services/breadcrumb.service';
import { HeaderService } from '@modules/shared/services/header.service';
import { MediaQueryService } from '@modules/shared/services/media-query.service';
import { ModalService } from '@modules/shared/services/modal.service';
import { WindowService } from '@modules/shared/services/window.service';
import { TranslateService } from '@ngx-translate/core';
import { MediaQuery, MediaQueryChangeEvent } from '@workbench/core';
import { Observable } from 'rxjs';

const MAIN_NAVIGATION_MINIMIZE_OFFSET = 120;

@Component({
  selector: 'mb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Input() notificationCount!: number;

  @HostBinding('class.sticky')
  public isMinimized = false;

  // Disable header animations during resize to prevent UI jank
  @HostListener('window:wbresize', ['$event'])
  public onResize(event: CustomEvent<MediaQueryChangeEvent>) {
    this.isResizing = true;
    clearTimeout(this._debouncedResizing);
    this._debouncedResizing = setTimeout(() => {
      this.isResizing = false;
    }, 400);

    this.currentMediaQuery = event.detail.current;
  }
  @HostBinding('attr.resizing') isResizing = false;
  private _debouncedResizing?: number;

  public activeMenuItem?: NavigationMenu | undefined;
  public breadcrumbs$: Observable<Breadcrumb[]>;
  public currentLevel1Path: string = '';
  public mainMenu$ = new Observable<NavigationMenu[] | null>();
  public lightPage$: Observable<boolean>;
  public currentMediaQuery: MediaQuery;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private headerService: HeaderService,
    private modalService: ModalService,
    private router: Router,
    private translateService: TranslateService,
    private windowService: WindowService,
    private mediaQueryService: MediaQueryService
  ) {
    this.windowService.windowScroll$.subscribe((scrollTop) => {
      if (scrollTop >= MAIN_NAVIGATION_MINIMIZE_OFFSET) {
        this.minimize();
      } else {
        this.maximize();
      }
    });

    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
    this.lightPage$ = this.appService.isLightPage$;
    this.currentMediaQuery = this.mediaQueryService.getCurrentMediaQuery();
  }

  ngOnInit() {
    this.mainMenu$ = this.headerService.menu$;
  }

  public toggleMenu(level: number, menu?: NavigationMenu) {
    if (menu && this.isActive(menu)) {
      this.headerService.closeMenu();
    } else {
      this.headerService.openMenu({ level, menu });
    }
  }

  public onBreadcrumbClick(event: Event, crumb: Breadcrumb) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate([this.translateService.instant(crumb.link)]);
  }

  public openMyContent(): void {
    this.headerService.closeMenu();
    this.modalService.open(MyMBMediaModalComponent);
  }

  public scrollTop() {
    this.windowService.scrollTo(0, 0);
  }

  public scrollTo(top: number) {
    this.windowService.scrollTo(top, 0);
  }

  public isActive(item: NavigationMenu) {
    return this.headerService.activeMenuByLevel.get(2) === item;
  }

  private minimize() {
    this.isMinimized = true;
    this.cdr.markForCheck();
  }

  private maximize() {
    this.isMinimized = false;
    this.cdr.markForCheck();
  }
}
