import { FavorableContent } from '@modules/favorites/interfaces/favorable-content.interface';
import { FavoriteDataInterface } from '@modules/favorites/interfaces/favorite-data.interface';
import { LightboxMetadataInterface } from '@modules/shared/interfaces/lightbox.interface';
import { DownloadableContent } from '@modules/shared/services/download.service';
import { SharableContent } from '@modules/shared/services/share.service';

import { MediaDefaultModel } from './media-default.model';
import { MediaType } from './media.types';

export interface MediaDocumentInterface extends SharableContent, DownloadableContent, FavorableContent {
  id: string;
  marsShelfNumber: string | null;
  marsOriginId: string | null;
  title: string;
  caption: string;
  displayDate?: Date;
  type: string;
  mediaType: string;
  pageCount: number;
  formats: MediaDocumentFormatInterface[];
  favoriteData?: FavoriteDataInterface;
  fuelLabel?: string;
}

export interface MediaDocumentFormatInterface {
  id: string;
  name: string;
  filesize: number;
  url: string;
  mimeType: string;
}

export class MediaDocumentModel extends MediaDefaultModel {
  public type = MediaType.DOCUMENT;
  public mediaType = MediaType.DOCUMENT;
  public filesize: number;
  public original: MediaDocumentFormatModel;
  public formats: MediaDocumentFormatModel[] = [];
  public pageCount: number;

  constructor(data: MediaDocumentInterface) {
    super(data);
    this.id = data.id;
    this.pageCount = data.pageCount;
    this.formats = data.formats.map((f: any) => new MediaDocumentFormatModel(f));
    this.original =
      (this.formats.find((item) => item.id === 'original') as MediaDocumentFormatModel) || this.formats[0];

    this.filesize = this.original?.filesize || 0;
  }

  public getLightboxMetadata(): LightboxMetadataInterface {
    return {
      ...super.getLightboxMetadata(),
      numberOfPages: this.pageCount,
    };
  }
}

export class MediaDocumentFormatModel {
  public id: string;
  public type = MediaType.DOCUMENT;
  public name: string;
  public filesize: number;
  public url: string;
  public mimeType: string;

  constructor(data: MediaDocumentFormatInterface) {
    this.id = data.id;
    this.mimeType = data.mimeType;
    this.url = data.url;
    this.name = data.name;
    this.filesize = data.filesize;
  }
}
