import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(value: any): any {
    const calcBase = 1024;
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let currentValue = value;
    let currentUnitIndex = 0;

    if (value <= calcBase) {
      currentValue = Math.round((value / 1024) * 10) / 10;
      if (currentValue === 0) {
        currentValue = '< 1';
      }
      currentUnitIndex++;
    } else {
      while (currentValue > calcBase && currentUnitIndex < units.length - 1) {
        currentValue = Math.round((currentValue / 1024) * 10) / 10;
        currentUnitIndex++;
      }
    }
    return currentValue + ' ' + units[currentUnitIndex];
  }
}
