import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LocalizedDatePipe } from './localized-date.pipe';

/**
 * Returns a localized string containing a probably shorted notation of two
 * dates as a time period
 *
 * We use different formatting, depending on the local start and end date
 * Since we display the date in event-local timezone,
 * we need to check for sameYear, sameMonth, sameDay respective to the timezone offset
 *
 * MEDIUM
 *
 * Example (omitting time and timezone for clarity):
 * startDate    |   endDate     |   timePeriod
 * -------------------------------------------------------------------
 * 01.01.2018   |      -        |   01. Januar 2018
 * 01.01.2018   |   01.01.2019  |   01. Januar 2018 - 01. Januar 2019
 * 01.01.2018   |   01.02.2018  |   01. Januar - 01. Februar 2018
 * 01.01.2018   |   02.01.2018  |   01. - 02. Januar 2018
 * 01.01.2018   |   01.01.2018  |   01. Januar 2018
 *
 * LONG FORMAT with from/to instead of '-'
 * startDate    |   endDate     |   timePeriod
 * -------------------------------------------------------------------
 * 01.01.2018   |      -        |   am 01. Januar 2018
 * 01.01.2018   |   01.01.2019  |   vom 01. Januar 2018 bis 01. Januar 2019
 * 01.01.2018   |   01.02.2018  |   vom 01. Januar bis 01. Februar 2018
 * 01.01.2018   |   02.01.2018  |   vom 01. bis 02. Januar 2018
 * 01.01.2018   |   01.01.2018  |   am 01. Januar 2018
 **/
@Pipe({
  name: 'timePeriod',
  pure: true, // works due to hard page reload on language switch
})
export class TimePeriodPipe implements PipeTransform {
  constructor(private localizedDate: LocalizedDatePipe, private translate?: TranslateService) {}

  transform(
    startDate: Date,
    endDate?: Date,
    timezoneOffset?: string,
    format: TimePeriodPipeFormat = TimePeriodPipeFormat.MEDIUM
  ): string {
    let sameYear;
    let sameMonth;
    let sameDay;
    let delimiter;
    let prefixSingle;
    let prefixPeriod;

    // check which format to output
    // Important: include timezoneOffset
    if (endDate) {
      sameYear =
        this.localizedDate.transform(startDate, 'yearOnly', timezoneOffset) ===
        this.localizedDate.transform(endDate, 'yearOnly', timezoneOffset);
      sameMonth =
        this.localizedDate.transform(startDate, 'monthAndYear', timezoneOffset) ===
        this.localizedDate.transform(endDate, 'monthAndYear', timezoneOffset);
      sameDay =
        this.localizedDate.transform(startDate, 'longDate', timezoneOffset) ===
        this.localizedDate.transform(endDate, 'longDate', timezoneOffset);
    }

    // Format either defines "-" or "to" as separator
    switch (format) {
      case TimePeriodPipeFormat.MEDIUM: {
        prefixSingle = '';
        prefixPeriod = '';
        delimiter = ' - ';
        break;
      }
      case TimePeriodPipeFormat.LONG: {
        prefixSingle = this.translate?.instant('DATE_TIME_PERIOD.ON') + ' ';
        prefixPeriod = this.translate?.instant('DATE_TIME_PERIOD.FROM') + ' ';
        delimiter = ' ' + this.translate?.instant('DATE_TIME_PERIOD.TO') + ' ';
        break;
      }
      default: {
        prefixSingle = '';
        prefixPeriod = '';
        delimiter = ' - ';
      }
    }

    // Case: single date
    if (sameDay || !endDate) {
      return prefixSingle + this.localizedDate.transform(startDate, 'longDate', timezoneOffset);
    }

    // Case: same month in the same year
    if (sameMonth) {
      return (
        prefixPeriod +
        this.localizedDate.transform(startDate, 'startDay', timezoneOffset) +
        delimiter +
        this.localizedDate.transform(endDate, 'endDay', timezoneOffset)
      );
    }

    // Case: same year, but different months
    if (sameYear) {
      return (
        prefixPeriod +
        this.localizedDate.transform(startDate, 'startDayAndMonth', timezoneOffset) +
        delimiter +
        this.localizedDate.transform(endDate, 'endDayAndMonth', timezoneOffset)
      );
    }

    // Case: dates are completely different
    return (
      prefixPeriod +
      this.localizedDate.transform(startDate, 'longDate', timezoneOffset) +
      delimiter +
      this.localizedDate.transform(endDate, 'longDate', timezoneOffset)
    );
  }
}

export enum TimePeriodPipeFormat {
  MEDIUM,
  LONG,
}
