import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface Configuration {
  apiBaseUrl: string;
  mediaDeliveryUrl: string;
  embedUrl: string;
  loginUrl: string;
  CSRFTokenHeader: string;
  mapApiKey: string;
  frontendUrl: string;
  sitesToTrack: string[];
  connectionString: string;
}

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private appConfig: Configuration;

  constructor(private http: HttpClient) {
    // Default config, should point to production
    this.appConfig = {
      apiBaseUrl: '',
      mediaDeliveryUrl: '',
      embedUrl: '',
      loginUrl: '',
      CSRFTokenHeader: '',
      mapApiKey: '',
      frontendUrl: '',
      sitesToTrack: [],
      connectionString: '',
    };
  }

  public load(): Observable<Configuration> {
    return this.http.get<Configuration>('./assets/app-config.json').pipe(
      map((data: Configuration) => {
        this.appConfig = data;
        return data;
      })
    );
  }

  public getConfig(): Configuration {
    return this.appConfig;
  }
}
