import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ExpiredTokenInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  public constructor(public httpClient: HttpClient) {}

  /*
   * HTTP Interceptor
   * Catches an API error, when the current session token is expired and needs to be refreshed OR remeoved by the login app
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401 && error.error.error === 'Expired, malformed or invalid token.') {
          return this.reAuthenticate().pipe(
            switchMap(() => {
              // session refreshed
              return next.handle(request);
            }),
            catchError(() => {
              // session removed
              return next.handle(request);
            })
          );
        }
        throw error;
      })
    );
  }

  reAuthenticate(): Observable<any> {
    // refresh or remove the current session token
    return this.httpClient.get(`${environment.loginUrl}/refresh-token`, { withCredentials: true });
  }
}
