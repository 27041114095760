import { Injectable } from '@angular/core';
import { ContactPersonModel } from '@models/contact-person.model';
import { ContactPersonInterface } from 'src/interfaces/contact-person.interface';

import { Adapter } from './adapter';
import { MediaAdapter } from './media.adapter';

export interface ContactPersonResponse {
  id: string;
  title: string;
  email: string;
  first_name: string;
  last_name: string;
  image?: any;
  job_title: string;
  position: string;
  mobile: string;
  phone: string;
  type: string;
  twitter?: string;
  linkedin?: string;
}

interface ContactGroupResponse {
  id: string;
  title: string;
  weight: number;
  contact_persons: any[];
}

@Injectable({
  providedIn: 'root',
})
export class ContactPersonAdapter implements Adapter<ContactPersonInterface> {
  constructor(private mediaAdapter: MediaAdapter) {}

  public parse(data: ContactPersonResponse): ContactPersonInterface {
    return {
      id: data.id,
      email: data.email,
      firstname: data.first_name,
      lastname: data.last_name,
      image: data.image ? this.mediaAdapter.parse(data.image) : undefined,
      mobile: data.mobile,
      phone: data.phone,
      fax: undefined, // maybe add this in the backend
      position: data.position,
      title: data.title,
      type: data.type,
      x: data.twitter,
      linkedIn: data.linkedin,
    };
  }

  public parseContactSector(data: any) {
    return {
      id: data.id,
      title: data.title,
      type: 'contact_sector',
      contactGroups: data.contact_groups
        .map((group: ContactGroupResponse) => {
          return {
            id: group.id,
            title: group.title,
            weight: group.weight,
            contactPersons: group.contact_persons
              .map((person: ContactPersonResponse) => {
                return new ContactPersonModel(this.parse(person));
              })
              .sort((contactA: ContactPersonModel, contactB: ContactPersonModel) =>
                Intl.Collator().compare(contactA.lastname, contactB.lastname) === 0
                  ? Intl.Collator().compare(contactA.firstname, contactB.firstname)
                  : Intl.Collator().compare(contactA.lastname, contactB.lastname)
              ),
          };
        })
        .sort((groupA: any, groupB: any) => groupA.weight - groupB.weight),
    };
  }
}
