import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

/**
 * Service which stores previous scroll positions and scrolls to them on navigation end
 *
 * Note: There is a built-in feature in Angular, which currently does not seem to work a 100%
 *       for all use-cases. It might be benefitial to check it out in the future.
 *       See discussion: https://github.com/angular/angular/issues/24547
 *
 *       For now, the code below should work. Taken from inform-me-frontend.
 */
@Injectable()
export class ScrollToLastPositionService {
  private _store: { [url: string]: number } = { '/': 0 };
  private scrollTo = 0;

  private previousScrollPosition = 0;
  private previousUrl: string;

  constructor(private router: Router) {
    this.previousUrl = this.router.url;
  }

  public handleScrollPositionRestoration() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        // do not store scroll position for outlet routes (currently only used as overlay)
        if (/\(lightbox:[a-z]+\/[a-z0-9-]+\)/gi.test(this.previousUrl)) {
          return;
        }

        this.previousScrollPosition = window.scrollY;

        // store scroll position before navigating away
        if (this.previousUrl) {
          this._store[this.previousUrl] = this.previousScrollPosition;
        }
      } else if (e instanceof NavigationEnd) {
        // remember new url for future scrolling
        this.previousUrl = e.url;

        // do not change scroll position for outlet routes (currently only used as overlay)
        if (/\(lightbox:[a-z]+\/[a-z0-9-]+\)/gi.test(this.previousUrl)) {
          return;
        }

        // configurable scroll restoration per navigtion
        switch (this.router.getCurrentNavigation()?.extras?.state?.scrollRestoration) {
          case 'disabled':
            return;
          case 'top':
            this.scrollTo = 0;
            break;
          case 'enabled':
          default: {
            // check if already stored a scrolling position for this page
            if (this._store[e.url]) {
              this.scrollTo = this._store[e.url];
            } else {
              this._store[e.url] = 0;
              this.scrollTo = 0;
            }
          }
        }

        // for places where we do fetch data outside of resolvers, this scroll event might come too early...
        setTimeout(() => {
          window.scrollTo(0, this.scrollTo);
        }, 50);
      }
    });
  }
}
