import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostListener,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { MediaVideoModel } from '@modules/media/models/media-video.model';
import { DataLayerService } from '@modules/shared/services/data-layer.service';

@Component({
  selector: 'mb-simple-player',
  templateUrl: './simple-player.component.html',
  styleUrls: ['./simple-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SimplePlayerComponent implements AfterViewInit {
  @Input() controls = true;
  @Input() media!: MediaVideoModel;
  @Input() autoplay = false;
  @Input() muted = true;
  @Input() loop = true;
  @Input() hoverVideo = true;
  @Input() playsInline = true;

  private _isHoverPlay = false;

  @ViewChild('player', { static: false }) player!: ElementRef<any>;
  public isPlayingPromise?: Promise<any> = undefined;

  @HostListener('mouseenter')
  hoverPlayer() {
    if (this.hoverVideo && this.player) {
      this._isHoverPlay = true;
      this.isPlayingPromise = this.player.nativeElement.play();
    }
  }

  @HostListener('mouseleave')
  stopHoverplay() {
    if (this.hoverVideo && this.player && !!this.isPlayingPromise) {
      this.isPlayingPromise
        .then(() => {
          this.player.nativeElement.load();
          this.cdr.detectChanges();
        })
        .catch(() => {});
    }
  }
  constructor(private cdr: ChangeDetectorRef, private dataLayerService: DataLayerService) {}

  public ngAfterViewInit(): void {
    this.player?.nativeElement.addEventListener('play', () => {
      if (!this._isHoverPlay) {
        this.trackEvent('videoPlay');
      }
    });

    this.player?.nativeElement.addEventListener('pause', () => {
      if (!this._isHoverPlay) {
        this.trackEvent('videoPause');
      }
    });

    this.player?.nativeElement.addEventListener('ended', () => {
      if (!this._isHoverPlay) {
        this.trackEvent('videoStop');
      }
    });
  }

  private trackEvent(event: 'videoPlay' | 'videoPause' | 'videoStop') {
    this.dataLayerService.pushEvent({
      event,
      video: {
        id: this.media.id,
        title: this.media.title,
        marsOriginId: this.media.marsOriginId ?? undefined,
        shelfNumber: this.media.marsShelfNumber ?? undefined,
      },
      currentTime: this.player.nativeElement.currentTime,
    });
  }
}
