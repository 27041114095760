import { Component, OnInit, ChangeDetectionStrategy, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

const ResizeObserver = window.ResizeObserver || Polyfill;
@Component({
  selector: 'mb-dynamic-container',
  templateUrl: './dynamic-container.component.html',
  styleUrls: ['./dynamic-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicContainerComponent implements OnInit {
  private resizeObserver = new ResizeObserver((entries: any) => {
    for (let entry of entries) {
      this.checkWidth(entry.contentRect.width);
      this.checkHeight(entry.contentRect.height);
    }
  });
  private lastEmittedWidth = 0;
  private lastEmittedHeight = 0;
  private _observedWidths: number[] = [];
  @Input()
  set observedWidths(v: number[]) {
    this._observedWidths = v
      .map((_v) => {
        return _v || 1;
      })
      .sort((a, b) => a - b);
  }
  get observedWidths() {
    return this._observedWidths;
  }

  private _observedHeights: number[] = [];
  @Input()
  set observedHeights(v: number[]) {
    this._observedHeights = v
      .map((_v) => {
        return _v || 1;
      })
      .sort((a, b) => a - b);
  }
  get observedHeights() {
    return this._observedHeights;
  }

  @Output() widthChanged = new EventEmitter<number>();
  @Output() heightChanged = new EventEmitter<number>();

  constructor(public elRef: ElementRef) {}

  checkWidth(width: number) {
    let currentWidth = 0;
    this.observedWidths.forEach((_width) => {
      currentWidth = _width <= width ? _width : currentWidth;
    });
    if (currentWidth !== this.lastEmittedWidth) {
      this.lastEmittedWidth = currentWidth;
      this.widthChanged.emit(currentWidth);
    }
  }
  checkHeight(height: number) {
    let currentHeight = 0;
    this.observedHeights.forEach((_width) => {
      currentHeight = _width <= height ? _width : currentHeight;
    });
    if (currentHeight !== this.lastEmittedHeight) {
      this.lastEmittedHeight = currentHeight;
      this.heightChanged.emit(currentHeight);
    }
  }

  ngOnInit(): void {
    this.resizeObserver.observe(this.elRef.nativeElement);
    this.checkHeight(this.elRef.nativeElement.clientHeight);
    this.checkWidth(this.elRef.nativeElement.clientWidth);
  }
}
