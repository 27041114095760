import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
@Component({
  selector: 'mb-shared-download-modal',
  templateUrl: './shared-download-modal.component.html',
  styleUrls: ['./shared-download-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedDownloadModalComponent {
  public copied = false;
  public agreed = false;

  @Input() url = '';
  constructor(private clipboard: Clipboard) {}

  copyUrl() {
    this.clipboard.copy(this.url);
    this.copied = true;
  }
}
