import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'mb-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  @Input() checked = false;
  /**
   * type any is only to make it easier to use this
   * angular templates have strict type checks and we would need to define
   * getter functions with types to avoid issues
   */
  @Input() control?: FormControl<boolean> | null | any;
  @Output() checkChange = new EventEmitter<boolean>();

  checkChanged() {
    this.checkChange.emit(this.control ? this.control.value : this.checked);
  }
}
