<div class="video-preview-container">
  <div #videoWrapperEl class="video-preview-content-inner" [style.width.%]="(player.playerData.ratio / (16 / 9)) * 100">
    <img *ngIf="thumbUrl" [src]="thumbUrl" alt="capture" />
  </div>
</div>
<ng-container *ngIf="isSeeking && !player.playerData.screenshotUrl">
  <div class="spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-container>
