<mb-base-modal>
  <div class="content" dialog-content>
    <div class="cmp-base-error-message">
      <div>
        <span class="icon-danger mb-icon mb-icon-attention mb-icon-xxl"></span>
        <h5 class="error-headline" [innerHtml]="headline | translate"></h5>
        <p *ngIf="message" class="message" [innerHtml]="message | translate"></p>
      </div>
    </div>
  </div>
</mb-base-modal>
