<wb-modal
  variant="edit-mobile-fullscreen"
  close-on-backdrop-click
  close-on-escape-key
  [activeLevel]="activeModalLevel"
  (wblevelchange)="onLevelChange($event)"
  (wbmodalclose)="onCloseClick()"
  size="m"
  *ngIf="user$ | async as user"
>
  <wb-modal-level level="1">
    <wb-heading size="m" tag="h3" class="headline">{{ user.username }}</wb-heading>
    <wb-header-fly-in-item class="profile-btn" (click)="openProfile()">
      <div class="flex-container">
        <div class="text-badge">
          <wb-text size="l">{{ 'PROFILE_CHANGE_DATA' | translate }}</wb-text>
        </div>
      </div>
      <wb-icon slot="icon" name="bds/chevron-right/24"></wb-icon>
    </wb-header-fly-in-item>
    <wb-header-fly-in-item (click)="openMyContent()">
      <div class="flex-container">
        <div class="text-badge">
          <wb-text size="l">{{ 'MY_CONTENT' | translate }}</wb-text>
          <div class="notification-content" *ngIf="notificationCount$ | async as notificationCount">
            <wb-icon name="notification"></wb-icon>
            <wb-counter [value]="notificationCount" [max]="9" theme="dark" variant="secondary"></wb-counter>
          </div>
        </div>
      </div>
      <wb-icon slot="icon" name="bds/chevron-right/24"></wb-icon>
    </wb-header-fly-in-item>
    <wb-button slot="footer" variant="primary" size="l" (click)="logout()">{{ 'LOGOUT' | translate }}</wb-button>
  </wb-modal-level>

  <wb-modal-level level="2" show-back-button backButtonLabel="{{ 'PROFILE' | translate }}">
    <wb-heading size="m" tag="h3" class="headline"> {{ levelTwoModal | translate }}</wb-heading>

    <ng-container [ngSwitch]="levelTwoModal">
      <ng-container *ngSwitchCase="'YOUR_PERSONAL_INFO'">
        <wb-input inputmode="text" value="{{ user.username }}" readonly>
          <span slot="label">{{ 'PROFILE_NAME' | translate }}</span>
        </wb-input>
        <wb-input type="email" inputmode="email" value="{{ user.ciamEmail || user.ermEmail }}" readonly>
          <span slot="label">{{ 'PROFILE_EMAIL' | translate }}</span>
        </wb-input>
        <hr />
        <wb-text class="bottom-text-personal" size="l">{{ 'MERCEDES_ME_PROFILE.TEXT' | translate }}</wb-text>
        <wb-link [href]="profileEditUrl" variant="standalone">
          {{ 'MERCEDES_ME_PROFILE.LINK' | translate }}
        </wb-link>
      </ng-container>

      <ng-container *ngSwitchCase="'MY_CONTENT'">
        <wb-header-fly-in-item (click)="openMyFavorites()">
          <div class="flex-container">
            <div class="text-badge">
              <wb-text size="l">{{ 'MY_FAVORITES' | translate }}</wb-text
              ><wb-counter
                [max]="99"
                *ngIf="favoritesCount$ | async as favoritesCount"
                [value]="favoritesCount"
                variant="tertiary"
              ></wb-counter>
            </div>
          </div>

          <wb-icon slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon>
        </wb-header-fly-in-item>

        <wb-header-fly-in-item (click)="openMyEvents()">
          <div class="flex-container">
            <div class="text-badge">
              <wb-text size="l"> {{ 'MY_EVENTS' | translate }}</wb-text
              ><wb-counter
                [max]="99"
                *ngIf="eventsCount$ | async as eventsCount"
                [value]="eventsCount"
                variant="tertiary"
              ></wb-counter>
            </div>

            <div class="new-content" *ngIf="newEventsCount$ | async as newEventsCount">
              <wb-icon name="notification"></wb-icon>
              <wb-text size="m"
                >{{
                  newEventsCount
                    | i18nPlural : { '=1': 'OPEN_INVITATION_1', other: 'OPEN_INVITATION_N' }
                    | translate : { count: newEventsCount }
                }}
              </wb-text>
            </div>
          </div>
          <wb-icon slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon>
        </wb-header-fly-in-item>

        <wb-header-fly-in-item *ngIf="hasPreMediaAccess$ | async" (click)="openMyPreMedia()">
          <div class="flex-container">
            <div class="text-badge">
              <wb-text size="l">{{ 'MY_PRE_MEDIA' | translate }}</wb-text
              ><wb-counter
                [max]="99"
                *ngIf="preMediaCount$ | async as preMediaCount"
                [value]="preMediaCount"
                variant="tertiary"
              ></wb-counter>
            </div>
            <div class="new-content" *ngIf="newPreMediaCount$ | async as newPreMediaCount">
              <wb-icon name="notification"></wb-icon>
              <wb-text size="m"
                >{{
                  newPreMediaCount
                    | i18nPlural : { '=1': 'NEW_PRE_MEDIA_1', other: 'NEW_PRE_MEDIA_N' }
                    | translate : { count: newPreMediaCount }
                }}
              </wb-text>
            </div>
          </div>
          <wb-icon slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon>
        </wb-header-fly-in-item>
      </ng-container>
    </ng-container>
  </wb-modal-level>
</wb-modal>
