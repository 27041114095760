import { Injectable } from '@angular/core';
import { ArticleInterface } from '@modules/article/interfaces/article.interface';
import { TranslateService } from '@ngx-translate/core';

import { ArticleHeaderImageStyle } from '../domain/image-styles/article-header-image.style';
import { ArticleImageGalleryImageStyle } from '../domain/image-styles/article-image-gallery.style';

import { Adapter } from './adapter';
import { ContactPersonAdapter, ContactPersonResponse } from './contact.adapter';
import {
  MediaAdapter,
  MediaAudioResponse,
  MediaDocumentResponse,
  MediaImageResponse,
  MediaVideoResponse,
} from './media.adapter';

@Injectable({
  providedIn: 'root',
})
export class ArticleAdapter implements Adapter<ArticleInterface> {
  constructor(
    private contactPersonAdapter: ContactPersonAdapter,
    private mediaAdapter: MediaAdapter,
    private translateService: TranslateService
  ) {}

  public parse(data: any): ArticleInterface {
    const context = { id: data.id, type: 'articles' };
    const slug =
      data.slug?.find(({ lang }: { lang: string }) => lang === this.translateService.currentLang.split('-')[0])
        ?.value ||
      (data.slugs && data.slugs[0].value) ||
      '';
    return {
      id: data.id,
      marsOriginId: data.mars_origin_id,
      type: data.type,
      title: data.title,
      slug: slug,
      slugs: data.slug,
      content: data.content?.value || '',
      headerImage: data.header_image
        ? this.mediaAdapter.parseStructuredImage(data.header_image, ArticleHeaderImageStyle, context)
        : null,
      fuelLabel: data.fuel_label_text?.processed,
      displayDate: data.display_date,
      location: data.location,

      images: (data.images || []).map((image: MediaImageResponse) => {
        return this.mediaAdapter.parse(image, ArticleImageGalleryImageStyle, context, slug);
      }),
      documents: (data.documents || []).map((document: MediaDocumentResponse) =>
        this.mediaAdapter.parseDocument(document, context)
      ),
      audios: (data.audios || []).map((audio: MediaAudioResponse) =>
        this.mediaAdapter.parseAudio(audio, context, slug)
      ),
      videos: (data.videos || []).map((video: MediaVideoResponse) =>
        this.mediaAdapter.parseVideo(video, context, slug)
      ),

      contacts: (data.press || []).map((contact: ContactPersonResponse) => this.contactPersonAdapter.parse(contact)),
      audioCount: data.audio_count,
      videoCount: data.video_count,
      imageCount: data.image_count,
      documentCount: data.document_count,

      topicId: data.topic_tree?.drupal_internal__tid?.toString(),
    };
  }
}
