import { QAForm } from '@modules/content-api/adapter/qaform.adapter';

import { MediaImageInterface, MediaImageModel } from './media-image.model';
import { Camera, MediaVideoModel, VideoStatus, VideoType } from './media-video.model';
import { MediaType } from './media.types';
import { TranscriptInterface, TranscriptModel } from './transcript.model';

export interface MediaStreamConfigInterface {
  id: string;
  title: string;
  type?: VideoType;
  status?: VideoStatus;
  screenshotOffset?: number;
  screenshotUrl?: string;
  cameras?: Camera[];

  fuelLabel: string;

  embedUrl: string;
  errorText: string;
  mp4Url: string;
  // media: ?
  playlistUrl: string;
  socialVideoUrl: string;
  showErrorScreen: boolean;
  showQaButton: boolean;
  qaForm?: QAForm;
  showScreenshotButton: boolean;
  showScrubberBar: boolean;
  width: number;
  height: number;
  transcript: TranscriptInterface | null;

  externalPlayer?: string;
}

export interface MediaStreamInterface {
  id: string;
  type: string;
  title: string;
  titleHash: string;
  background: MediaImageInterface | null;
  countdownDate: string | null;
  countdownLabel: string;
  startDate: string;
  endDate: string;
  embedText: string;
  fuelLabel: string;
  icsLink: string;
  liveStatusIndicatorText: string;
  playButtonTextLive: string;
  playButtonTextVod: string;
  playerConfig?: MediaStreamConfigInterface;
  showIcsDownload: boolean;
  showEmbedCode: boolean;
  showLoadingAnimation: boolean;
  subtitleLive: string;
  subtitlePreLive: string;
  subtitleStopped: string;
  subtitleVod: string;
  // embedCard
}

export class MediaStreamModel {
  public mediaType = MediaType.STREAM;

  public id: string;
  public type = MediaType.STREAM;
  public title: string;
  public titleHash: string;
  public background: MediaImageModel | null;

  public countdownDate: Date | null;
  public startDate: Date;
  public endDate: Date;

  public showIcsDownload: boolean;
  public showEmbedCode: boolean;
  public showLoadingAnimation: boolean;

  public countdownLabel: string;
  public fuelLabel: string;

  private playButtonTextLive: string;
  private playButtonTextVod: string;

  private subtitlePreLive: string;
  private subtitleLive: string;
  private subtitleStopped: string;
  private subtitleVod: string;
  public embedText: string;

  public icsLink: string;
  public liveStatusIndicatorText: string;
  public playerConfig?: MediaStreamConfigInterface;

  public isStopped = false;

  public video?: MediaVideoModel;
  public videoStatus: VideoStatus = VideoStatus.PRE_LIVE;

  constructor(data: MediaStreamInterface) {
    this.id = data.id;
    this.title = data.title;
    this.titleHash = data.titleHash;
    this.background = data.background ? new MediaImageModel(data.background) : null;
    this.countdownDate = data.countdownDate ? new Date(data.countdownDate) : null;
    this.startDate = new Date(data.startDate);
    this.endDate = new Date(data.endDate);
    this.countdownLabel = data.countdownLabel;
    this.fuelLabel = data.fuelLabel || '';
    this.icsLink = data.icsLink;
    this.liveStatusIndicatorText = data.liveStatusIndicatorText;
    this.showIcsDownload = data.showIcsDownload;
    this.showEmbedCode = data.showEmbedCode;
    this.showLoadingAnimation = data.showLoadingAnimation;
    this.playButtonTextLive = data.playButtonTextLive;
    this.playButtonTextVod = data.playButtonTextVod;

    this.subtitleLive = data.subtitleLive;
    this.subtitlePreLive = data.subtitlePreLive;
    this.subtitleStopped = data.subtitleStopped;
    this.subtitleVod = data.subtitleVod;
    this.embedText = data.embedText;

    this.playerConfig = data.playerConfig;
    const playerConfig = data.playerConfig;
    if (playerConfig) {
      this.setPlayerConfig(playerConfig);
    }
  }

  get playButtonText(): string {
    switch (this.status) {
      case VideoStatus.LIVE:
        return this.playButtonTextLive;
      case VideoStatus.VOD:
        return this.playButtonTextVod;
    }
    return this.playButtonTextVod;
  }

  get subtitle(): string {
    switch (this.status) {
      case VideoStatus.PRE_LIVE:
        return this.subtitlePreLive;
      case VideoStatus.LIVE:
        return this.subtitleLive;
      case VideoStatus.STOPPED:
        return this.subtitleStopped;
      case VideoStatus.VOD:
        return this.subtitleVod;
      default:
        return this.subtitleVod;
    }
  }

  get status(): VideoStatus {
    if (this.startDate > new Date()) {
      // the player is set to live before sd is reached, but the user should not see this
      return VideoStatus.PRE_LIVE;
    }
    return this.videoStatus;
  }

  setPlayerConfig(playerConfig: MediaStreamConfigInterface) {
    const url = playerConfig.playlistUrl || playerConfig.mp4Url || playerConfig.screenshotUrl || '';
    const urlSrcType = url.split('.').pop();
    const format = urlSrcType === 'm3u8' ? 'application/x-mpegURL' : `video/${urlSrcType}`;
    this.videoStatus = playerConfig.status ?? VideoStatus.PRE_LIVE;
    this.video = new MediaVideoModel({
      id: playerConfig.id,
      marsShelfNumber: null,
      marsOriginId: null,
      title: playerConfig.title,
      type: playerConfig.type || VideoType.SINGLE,
      isStream: true,
      status: playerConfig.status,
      caption: this.subtitle,
      description: '',
      mediaType: 'stream',
      poster: '',
      screenshotUrl: playerConfig.screenshotUrl || '',
      screenshotOffset: playerConfig.screenshotOffset || 0,
      cameras: playerConfig.cameras || [],
      fuelLabel: playerConfig.fuelLabel,
      showScreenshotButton: playerConfig.showScreenshotButton,
      showQaButton: playerConfig.showQaButton,
      qaForm: playerConfig.qaForm,
      showScrubberBar: playerConfig.showScrubberBar,
      transcript: playerConfig.transcript
        ? new TranscriptModel(playerConfig.transcript, {
            id: this.id,
            type: 'streams',
          })
        : null,
      formats: [
        {
          id: playerConfig.id,
          url: playerConfig.playlistUrl || playerConfig.mp4Url || playerConfig.screenshotUrl || '',
          name: playerConfig.title,
          mimeType: format,
          width: playerConfig.width || 1920,
          height: playerConfig.height || 1080,
          duration: 0,
          extension: '',
          filesize: 0,
        },
      ],
      favoriteData: {
        id: playerConfig.id,
        type: 'player_config',
        context: {
          id: this.id,
          type: 'streams',
        },
      },
    });
  }
}
