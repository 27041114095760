import { FavorableContent } from '@modules/favorites/interfaces/favorable-content.interface';
import { FavoriteDataInterface } from '@modules/favorites/interfaces/favorite-data.interface';
import { LightboxMetadataInterface } from '@modules/shared/interfaces/lightbox.interface';
import { DownloadableContent } from '@modules/shared/services/download.service';
import { SharableContent } from '@modules/shared/services/share.service';

import { MediaDefaultModel } from './media-default.model';
import { MediaType } from './media.types';

// TODO: Differentiate between API interface and local (enriched) model / type
//       also use ZOD to simplify this
//       Actual API type is defined here: MediaImageResponse
export interface MediaImageInterface extends SharableContent, DownloadableContent, FavorableContent {
  id: string;
  type: string;
  name: string;
  caption?: string;
  description?: string;
  displayDate?: Date;
  filename?: string;
  filesize?: number;
  width?: number;
  height?: number;
  mimeType?: string;
  marsShelfNumber: string | null;
  marsOriginId: string | null;
  mediaType: string;
  fuelLabel?: string;

  formats: MediaImageFormatInterface[];
  lightboxFormats: MediaImageFormatInterface[];
  favoriteData?: FavoriteDataInterface;
}

export interface MediaImageFormatInterface {
  id: string;
  url: string;
  filename: string;
  mimeType: string;
  height: number;
  width: number;
  minWidth?: string;
  pattern?: string; //Actually required
  filesize: number;
  landscapeOnly?: boolean;
}

export class MediaImageModel extends MediaDefaultModel {
  public type = MediaType.IMAGE;
  public mediaType = MediaType.IMAGE;
  public original: MediaImageFormatModel;
  public formats: MediaImageFormatModel[] = [];
  public lightboxFormats: MediaImageFormatModel[] = [];
  public filesize: number = 0;
  public ratio = 0;
  public width?: number;
  public height?: number;

  constructor(data: MediaImageInterface) {
    super(data);

    this.width = data.width;
    this.height = data.height;

    this.favoriteData = data.favoriteData;

    this.formats = data.formats.map((f: any) => new MediaImageFormatModel(f)).reverse();
    this.lightboxFormats = (data.lightboxFormats || data.formats)
      .map((f: any) => new MediaImageFormatModel(f))
      .reverse();
    this.original =
      (this.formats.find((item) => item.id === 'original') as MediaImageFormatModel) ||
      this.formats[this.formats.length - 1];
    if (this.original) {
      this.filesize = this.original.filesize;
      this.ratio = this.original.ratio; // set a default ratio in case something was not imported correctly
    }
  }

  public getLightboxMetadata(): LightboxMetadataInterface {
    const resolution = !!(this.width && this.height) ? `${this.width}px x ${this.height}px` : undefined;
    return {
      ...super.getLightboxMetadata(),
      resolution,
    };
  }

  public closestToWidth(width: number): MediaImageFormatModel {
    return this.findFormatClosestTo(width, 'width');
  }

  private findFormatClosestTo(value: number, prop: 'width' | 'height' | 'ratio') {
    const format = this.formats.reduce((acc: MediaImageFormatModel, cur: MediaImageFormatModel) => {
      if (!acc) {
        acc = cur;
      }

      return Math.abs(cur[prop] - value) < Math.abs(acc[prop] - value) ? cur : acc;
    }, {} as MediaImageFormatModel);

    return format || this.original;
  }
}

export class MediaImageFormatModel {
  public id: string;
  public filename: string;
  public mimeType: string;
  public type = MediaType.IMAGE;
  public url: string;
  public ratio: number;
  public height: number;
  public width: number;
  public filesize: number;
  public minWidth?: string;
  public query?: string;

  constructor(data: MediaImageFormatInterface) {
    this.id = data.id;
    this.mimeType = data.mimeType;
    this.ratio = data.width / data.height;
    this.height = data.height;
    this.width = data.width;
    this.url = data.url;
    this.filename = data.filename;
    this.minWidth = data.minWidth;
    this.query = data.landscapeOnly
      ? `(min-width: ${data.minWidth}px) and (orientation: landscape)`
      : `(min-width: ${data.minWidth}px)`;
    this.filesize = data.filesize;
  }
}
