import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { MediaImageModel } from '../models/media-image.model';

@Component({
  selector: 'mb-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() isLightboxMedia = false;
  @Input() media!: MediaImageModel;
  @Output() imageError = new EventEmitter();
}
