<wb-modal
  variant="edit-mobile-fullscreen"
  close-on-backdrop-click
  close-on-escape-key
  (wbmodalclose)="onCloseClick()"
  size="m"
>
  <wb-modal-level level="1" close-button-label="{{ 'CLOSE' | translate }}">
    <div class="d-flex flex-column">
      <ul class="d-flex flex-row justify-content-center gap-1 mb-3">
        <li *ngFor="let shareTarget of shareTargets()">
          <a
            class="btn btn-quaternary"
            [href]="shareTarget.url"
            target="_blank"
            [title]="shareTarget.name"
            (click)="trackShareEvent(shareTarget)"
          >
            <wb-icon *ngIf="!shareTarget.legacyIcon" class="social-icon" [name]="shareTarget.icon"></wb-icon>
            <span *ngIf="shareTarget.legacyIcon" class="mb-icon {{ shareTarget.icon }}"></span>
          </a>
        </li>
      </ul>

      <div class="d-flex flex-row w-100 gap-1">
        <wb-input class="flex-grow-1" [value]="sharingData.url" readonly [success]="copied">
          <span slot="label">{{ 'PRESS_RELEASE' | translate }}</span>
        </wb-input>
        <wb-button variant="primary" (click)="copyToClipboard()">
          <wb-icon slot="icon" [name]="copied ? 'bds/checkmark/24' : 'copy-clipboard/24'"></wb-icon>
          {{ (copied ? 'COPIED' : 'COPY') | translate }}
        </wb-button>
      </div>
    </div>
  </wb-modal-level>
</wb-modal>
