<mb-base-modal>
  <div class="content download-confirmation copy-m" dialog-content>
    <h2 class="download-confirmation-headline h3">{{ 'DOWNLOAD' | translate }}</h2>

    <div class="content-text-wrapper">
      <div *ngIf="downloadIconType[type]" class="content-type-icon mb-icon {{ downloadIconType[type] }}"></div>
      <div class="content-text">
        <h3 class="content-text-headline h6">{{ downloadContentType[type] || type | translate }}</h3>
        <div class="content-title" [innerHtml]="title || ('MY_CONTENT' | translate)"></div>
        <div *ngIf="metaData" class="download-meta">
          <div *ngIf="metaData.format" class="download-meta-item">
            <div class="download-meta-label">{{ 'FORMAT' | translate }}</div>
            <div class="download-meta-value text-uppercase">{{ metaData.format }}</div>
          </div>
          <div *ngIf="metaData.size" class="download-meta-item">
            <div class="download-meta-label">{{ 'SIZE' | translate }}</div>
            <div class="download-meta-value">{{ metaData.size }}</div>
          </div>
          <div *ngIf="metaData.contents?.length" class="download-meta-item">
            <div class="download-meta-label">{{ 'Content' | translate }}</div>
            <div class="download-meta-value">
              <ul class="meta-items inherit-font">
                <li *ngFor="let content of metaData.contents" class="meta-item">
                  {{ content.key | translate: content.data }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="download-actions">
      <mb-checkbox [checked]="agreed" (checkChange)="agreed = $event">
        <!-- this should be display as inline, thats why we need this formatting -->
        <span>
          {{ 'DOWNLOAD_AGREE_PRE_TOS' | translate
          }}<a class="btn btn-plain copy-m" [routerLink]="'/legal/terms-of-use'">
            {{ 'DOWNLOAD_AGREE_TOS' | translate }}</a
          >{{ 'DOWNLOAD_AGREE_AFTER_TOS' | translate }}
        </span>
      </mb-checkbox>

      <a
        class="btn download-button"
        [class.disabled]="!agreed"
        [href]="downloadUrl"
        [target]="isFirefox || isMobile ? '_blank' : '_self'"
        download
        mat-dialog-close
        (click)="downloaded.emit()"
      >
        <span class="mb-icon mb-icon-download"></span>
        <span>{{ 'DOWNLOAD' | translate }}</span>
      </a>
    </div>
  </div>
</mb-base-modal>
