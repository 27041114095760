import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { CardLayout } from '@modules/card/enums/card-layout.enum';
import { MediaImageModel } from '@modules/media/models/media-image.model';
import { MediaType } from '@modules/media/models/media.types';
import { DownloadService } from '@modules/shared/services/download.service';
import { ShareModalService } from '@modules/shared/services/share.service';
import { TrackingService, TRACKING_TYPES } from '@modules/shared/services/tracking.service';
import { ActionIcons, ButtonItem } from '@modules/ui/action-container/action-container.component';
import { MetaItemInterface } from '@modules/ui/meta-items/meta-items.component';

import { FooterMeta } from '../interfaces/footer-meta.interface';
import { FavoritesService } from '../services/favorites.service';

@Component({
  selector: 'mb-favorite-card',
  templateUrl: './favorite-card.component.html',
  styleUrls: ['./favorite-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteCardComponent {
  public cardLayout = CardLayout;
  public mediaType = MediaType;

  public readonly pressKitType = 'presskit';

  @HostBinding('class.has-media')
  @Input()
  image: MediaImageModel | null = null;

  @HostBinding('class.has-static-media')
  @Input()
  imageUrl: string | null = null;

  @Input() headline!: string;
  @Input() icon!: string;
  @Input() actions: ButtonItem[] = [];
  @Input() data: any;

  @Input() headerMeta!: MetaItemInterface[];
  @Input() footerMeta!: FooterMeta | null;

  constructor(
    private favoriteService: FavoritesService,
    private downloadService: DownloadService,
    private shareService: ShareModalService,
    private trackingService: TrackingService
  ) {}

  actionClick(action: ActionIcons) {
    switch (action) {
      case ActionIcons.DELETE:
        this.remove();
        break;
      case ActionIcons.DOWNLOAD:
        // FIXME, consolidate so tracking can be done within download services
        this.downloadService.download({
          data: this.data,
          isShareDownload: false,
          pushToDataLayer: true,
        });
        this.trackingService.trackEvent(this.data, TRACKING_TYPES.DOWNLOAD);
        break;
      case ActionIcons.SHARE:
        // FIXME, consolidate so tracking can be done within share service
        this.shareService.openModal(this.data);
        this.trackingService.trackEvent(this.data, TRACKING_TYPES.SHARE);
        break;
    }
  }

  remove() {
    this.favoriteService.remove(this.data.id);
  }
}
