import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '@modules/shared/services/user.service';
import { map, take } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService) {}

  canActivate() {
    return this.userService.isLoggedIn().pipe(
      take(1),
      map((loggedIn) => {
        if (loggedIn) {
          return true;
        } else {
          this.userService.login();
          return false;
        }
      })
    );
  }
}
