import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mb-base-confirmation',
  templateUrl: './base-confirmation.component.html',
  styleUrls: ['./base-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseConfirmationComponent {
  public remember = false;
  @Input() headline!: string;
  @Input() text!: string;
  @Input() cancelLabel!: string;
  @Input() confirmLabel!: string;

  @Output() confirm = new EventEmitter<{ remember: boolean }>();
  @Output() cancel = new EventEmitter();
}
