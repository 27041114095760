import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { ArticleModel } from '@modules/article/models/article.model';
import { Breadcrumb, BreadcrumbService } from '@modules/shared/services/breadcrumb.service';
import { HeaderService } from '@modules/shared/services/header.service';
import { ArticlePageService } from '@pages/article-page/article-page.service';
import { EMPTY, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ArticleResolver implements Resolve<Observable<ArticleModel | null>> {
  constructor(private appService: AppService, private articlePageService: ArticlePageService, private router: Router) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<ArticleModel | null> {
    const slugOrId = route.paramMap.get('slugOrId');
    if (!slugOrId) {
      this.router.navigate(['404'], { skipLocationChange: true });
      return EMPTY;
    }

    return this.articlePageService.getArticle(slugOrId).pipe(
      map((article) => {
        if (!article) {
          this.router.navigate(['404'], { skipLocationChange: true });
          return null;
        } else {
          this.appService.darkBackgroundPage();
          this.appService.setTitle(`${article.title} | Mercedes-Benz Media`);
          return article;
        }
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ArticleBreadcrumbResolver implements Resolve<Observable<Breadcrumb[] | Breadcrumb | undefined>> {
  constructor(
    private articlePageService: ArticlePageService,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Breadcrumb[] | Breadcrumb | undefined> {
    const slugOrId = route.paramMap.get('slugOrId');
    if (slugOrId) {
      if (this.breadcrumbService.keepBreadcrumbs) {
        return of({ label: 'PRESS_INFORMATION', link: `/article/${slugOrId}` });
      } else {
        // fetch article to build "default" breadcrumbs for this article
        return this.articlePageService.getArticle(slugOrId).pipe(
          switchMap((article) => {
            if (article) {
              if (article.topicId) {
                return this.headerService.findNavigationMenuById(article.topicId).pipe(
                  switchMap((menu) => {
                    if (menu) {
                      return this.headerService.navigationMenuPath$(menu).pipe(
                        map((topicPath) => {
                          return [
                            ...topicPath.map((item) => ({
                              label: `MENU.${item.id}.NAME`,
                              link: `MENU.${item.id}.PATH`,
                            })),
                            { label: 'PRESS_INFORMATION', link: `/article/${article.id}` },
                          ];
                        })
                      );
                    } else {
                      return of([{ label: 'PRESS_INFORMATION', link: `/article/${article.id}` }]);
                    }
                  })
                );
              } else {
                return of([{ label: 'PRESS_INFORMATION', link: `/article/${article.id}` }]);
              }
            } else {
              return of(undefined);
            }
          })
        );
      }
    } else {
      return of(undefined);
    }
  }
}
