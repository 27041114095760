import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ScrollSpyDirective } from './scroll-spy.directive';
import { ScrollSpyService } from './scroll-spy.service';

@NgModule({
  imports: [CommonModule],
  declarations: [ScrollSpyDirective],
  exports: [ScrollSpyDirective],
})
export class ScrollSpyModule {
  static forRoot(): ModuleWithProviders<ScrollSpyModule> {
    return {
      ngModule: ScrollSpyModule,
      providers: [ScrollSpyService],
    };
  }
}
