import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@modules/shared/shared.module';

import { CountdownComponent } from './countdown/countdown.component';
import { CountdownTimeComponent } from './countdown-time/countdown-time.component';
import { CountdownTimeFieldsComponent } from './countdown-time-fields/countdown-time-fields.component';

@NgModule({
  declarations: [CountdownComponent, CountdownTimeComponent, CountdownTimeFieldsComponent],
  imports: [CommonModule, SharedModule],
  exports: [CountdownComponent],
})
export class CountdownModule {}
