import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ConsentOverlayComponent } from './consent-overlay/consent-overlay.component';

// Note: This is a separate module, because the ConsentOverlayComponent is being used in the MediaModule,
//       which itself is being used in the SharedModule (via Lightbox), thus creating a circilar dependency,
//       if this lives in the SharedModule.
//       FIXME: clean up the project, modules and components structure for some sanity
@NgModule({
  declarations: [ConsentOverlayComponent],
  imports: [RouterModule, CommonModule, TranslateModule],
  exports: [ConsentOverlayComponent],
})
export class ConsentModule {}
