import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CountdownModule } from '@modules/countdown/countdown.module';
import { MediaModule } from '@modules/media/media.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { SharedModule } from '@modules/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import { UiModule } from '../ui/ui.module';

import { ArticleCardComponent } from './article-card/article-card.component';
import { CardComponent } from './card/card.component';
import { CardBaseComponent } from './card-base/card-base.component';
import { CardColumnComponent } from './card-column/card-column.component';
import { CardHeroComponent } from './card-hero/card-hero.component';
import { CardRowComponent } from './card-row/card-row.component';
import { ContactPersonCardComponent } from './contact-person-card/contact-person-card.component';
import { DocumentCardComponent } from './document-card/document-card.component';
import { EventRibbonCardComponent } from './event-ribbon-card/event-ribbon-card.component';
import { ExpandableCardWrapperComponent } from './expandable-card-wrapper/expandable-card-wrapper.component';
import { StreamCardComponent } from './stream-card/stream-card.component';
import { TestCarCardComponent } from './test-car-card/test-car-card.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    PipesModule,
    UiModule,
    MediaModule,
    CountdownModule,
  ],
  declarations: [
    CardBaseComponent,
    CardHeroComponent,
    CardComponent,
    EventRibbonCardComponent,
    CardRowComponent,
    CardColumnComponent,
    ContactPersonCardComponent,
    DocumentCardComponent,
    StreamCardComponent,
    TestCarCardComponent,
    ArticleCardComponent,
    ExpandableCardWrapperComponent,
  ],
  exports: [
    CardComponent,
    CardHeroComponent,
    EventRibbonCardComponent,
    ContactPersonCardComponent,
    DocumentCardComponent,
    StreamCardComponent,
    TestCarCardComponent,
    ArticleCardComponent,
    ExpandableCardWrapperComponent,
  ],
})
export class CardModule {}
