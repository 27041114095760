import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { z } from 'zod';

import { ContentAPIService } from '../content-api.service';

const RedirectSchema = z.object({
  destination_path: z.string().optional().nullable(),
});

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(private apiService: ContentAPIService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const statusCode = route.data.statusCode;

    if (statusCode === 404) {
      return this.apiService.getUnparsedResources('redirect', { source_path: window.location.pathname }).pipe(
        map((result) => {
          return RedirectSchema.parse(result);
        }),
        map(({ destination_path }) => {
          if (destination_path) {
            this.router.navigateByUrl(destination_path);
            return false;
          } else {
            return true;
          }
        }),
        catchError(() => {
          return of(true);
        })
      );
    }

    return of(true);
  }
}
