<div class="dialog-background" [ngClass]="backgroundClass" (click)="close()"></div>
<div class="dialog-header">
  <div class="dialog-title">{{ title }}</div>

  <div *ngIf="!disableCloseBtn" class="dialog-btn-wrapper">
    <button class="btn btn-plain" (click)="close()">
      {{ 'CLOSE' | translate }}
      <span class="mb-icon mb-icon-close"></span>
    </button>
  </div>
</div>
<div class="dialog-child" [class.full-content]="data.fullContent" [ngClass]="classList" [ngStyle]="style">
  <ng-template #dialogChild></ng-template>
</div>
