import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { PreMediaService } from '@app/features/pre-media/pre-media.service';
import { FavoritesContentService } from '@modules/favorites/services/favorites-content.service';
import { FavoritesService } from '@modules/favorites/services/favorites.service';
import { User } from '@modules/shared/models/user.model';
import { EventsService } from '@modules/shared/services/events.service';
import { ModalService } from '@modules/shared/services/modal.service';
import { UserService } from '@modules/shared/services/user.service';
import { WbModalCustomEvent } from '@workbench/core';
import { combineLatest, Observable } from 'rxjs';

import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'mb-user-profile-modal',
  templateUrl: './user-profile-modal.component.html',
  styleUrls: ['./user-profile-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileModalComponent extends ModalComponent {
  public user$: Observable<User | null>;

  public favoritesCount$: Observable<number>;
  public eventsCount$: Observable<number>;
  public newEventsCount$: Observable<number>;
  public preMediaCount$: Observable<number>;
  public newPreMediaCount$: Observable<number>;
  public hasPreMediaAccess$: Observable<boolean>;
  public notificationCount$: Observable<number>;

  public activeModalLevel: number = 1;
  public levelTwoModal: 'YOUR_PERSONAL_INFO' | 'MY_CONTENT' = 'YOUR_PERSONAL_INFO';

  public profileEditUrl: string;

  constructor(
    private userService: UserService,
    private favoritesService: FavoritesService,
    private favoritesContentService: FavoritesContentService,
    private preMediaService: PreMediaService,
    private eventsService: EventsService,
    private modalService: ModalService,
    private router: Router
  ) {
    super();

    this.user$ = this.userService.user$;
    this.favoritesCount$ = this.favoritesContentService.favoritesCount$;
    this.eventsCount$ = this.eventsService.myEventsCount$;
    this.newEventsCount$ = this.eventsService.pendingRegistrationsCount$;
    this.preMediaCount$ = this.preMediaService.allPreMediaCount$;
    this.newPreMediaCount$ = this.preMediaService.preMediaCount$;
    this.hasPreMediaAccess$ = this.preMediaService.hasPreMedia$;

    this.notificationCount$ = combineLatest(
      [this.newPreMediaCount$, this.newEventsCount$],
      (newPreMediaCount, newEventsCount) => {
        return newPreMediaCount + newEventsCount;
      }
    );

    this.profileEditUrl = this.userService.profileEditUrl;
  }

  public openProfile(): void {
    this.activeModalLevel = 2;
    this.levelTwoModal = 'YOUR_PERSONAL_INFO';
  }

  public openMyContent(): void {
    this.activeModalLevel = 2;
    this.levelTwoModal = 'MY_CONTENT';
  }

  public onLevelChange(event: Event) {
    // update our active level state, when the internal back button is being used
    this.activeModalLevel = (event as WbModalCustomEvent<number>).detail;
  }

  public onCloseClick(): void {
    this.modalService.close();
  }

  public openMyFavorites(): void {
    this.modalService.close();
    this.favoritesService.openFavorites();
  }

  public openMyEvents(): void {
    this.modalService.close();
    this.router.navigate(['my-events']);
  }

  public openMyPreMedia(): void {
    this.modalService.close();
    this.router.navigate(['pre-media']);
  }

  public logout(): void {
    this.userService.logout();
  }
}
