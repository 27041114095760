<wb-modal
  variant="edit-mobile-fullscreen"
  close-on-backdrop-click
  close-on-escape-key
  (wbmodalclose)="onCloseClick()"
  size="m"
>
  <wb-modal-level level="1" close-button-label="{{ 'CLOSE' | translate }}">
    <wb-heading size="m" tag="h3" class="headline"> {{ 'MY_CONTENT' | translate }} </wb-heading>

    <wb-header-fly-in-item (click)="openMyFavorites()">
      <div class="flex-container">
        <div class="text-badge">
          <wb-text size="l">{{ 'MY_FAVORITES' | translate }}</wb-text
          ><wb-counter
            [max]="99"
            *ngIf="favoritesCount$ | async as favoritesCount"
            [value]="favoritesCount"
            variant="tertiary"
          ></wb-counter>
        </div>
      </div>

      <wb-icon slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon>
    </wb-header-fly-in-item>

    <ng-container *ngIf="isLoggedIn$ | async; else notLoggedIn">
      <wb-header-fly-in-item (click)="openMyEvents()">
        <div class="flex-container">
          <div class="text-badge">
            <wb-text size="l"> {{ 'MY_EVENTS' | translate }}</wb-text
            ><wb-counter
              [max]="99"
              *ngIf="eventsCount$ | async as eventsCount"
              [value]="eventsCount"
              variant="tertiary"
            ></wb-counter>
          </div>

          <div class="new-content" *ngIf="newEventsCount$ | async as newEventsCount">
            <wb-icon name="notification"></wb-icon>
            <wb-text size="m"
              >{{
                newEventsCount
                  | i18nPlural : { '=1': 'OPEN_INVITATION_1', other: 'OPEN_INVITATION_N' }
                  | translate : { count: newEventsCount }
              }}
            </wb-text>
          </div>
        </div>
        <wb-icon slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon>
      </wb-header-fly-in-item>

      <wb-header-fly-in-item *ngIf="hasPreMediaAccess$ | async" (click)="openMyPreMedia()">
        <div class="flex-container">
          <div class="text-badge">
            <wb-text size="l">{{ 'MY_PRE_MEDIA' | translate }}</wb-text
            ><wb-counter
              [max]="99"
              *ngIf="preMediaCount$ | async as preMediaCount"
              [value]="preMediaCount"
              variant="tertiary"
            ></wb-counter>
          </div>
          <div class="new-content" *ngIf="newPreMediaCount$ | async as newPreMediaCount">
            <wb-icon name="notification"></wb-icon>
            <wb-text size="m"
              >{{
                newPreMediaCount
                  | i18nPlural : { '=1': 'NEW_PRE_MEDIA_1', other: 'NEW_PRE_MEDIA_N' }
                  | translate : { count: newPreMediaCount }
              }}
            </wb-text>
          </div>
        </div>
        <wb-icon slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon>
      </wb-header-fly-in-item>
    </ng-container>

    <ng-template #notLoggedIn>
      <wb-text class="bottom-text" size="l">{{ 'MY_CONTENT_LOGIN_TEXT' | translate }}</wb-text>
      <wb-link (click)="login()" variant="standalone">{{ 'LOGIN' | translate }}</wb-link>
    </ng-template>
  </wb-modal-level>
</wb-modal>
