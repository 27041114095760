/**
 * Gender is needed to format the salutaton correctly
 */
export enum UserGender {
  MALE = 'm',
  FEMALE = 'f',
}

export interface PremediaData {
  total: number;
  confirmed: number;
  unconfirmed: number;
}

/**
 * User class, holding basic information about the user
 */
export class User {
  public firstName: string;
  public lastName: string;
  public username: string;
  public ciamEmail: string;
  public ermEmail: string;
  public favourites: any[] = [];
  public gender?: UserGender;
  public id: string;
  public isErmUser: boolean;
  public title: string;
  public company?: string;
  public phone?: string;
  public mobile?: string;
  public fax?: string;
  public roles?: string[] = [];
  public hasPreMediaAccess?: boolean;
  public preMedia?: PremediaData;

  constructor(data: any) {
    this.id = data.id;
    const attributes = data.attributes;
    this.ciamEmail = attributes.ciam_email;
    this.ermEmail = attributes.erm_email;
    this.favourites = attributes.favourites;
    this.firstName = attributes.firstname;
    this.gender = attributes.gender;
    this.id = attributes.id;
    this.isErmUser = attributes.is_erm_user;
    this.lastName = attributes.lastname;
    this.title = attributes.title;
    this.company = attributes.company;
    this.phone = attributes.phone;
    this.mobile = attributes.mobile;
    this.fax = attributes.fax;
    this.roles = attributes.roles;
    this.hasPreMediaAccess = attributes.has_pre_media_access;
    this.preMedia = attributes.preMedia;
    this.username = [this.firstName, this.lastName].filter(Boolean).join(' ');
  }

  public toString(): string {
    return this.getFullName() || '';
  }

  /**
   * Returns the fullname of the user containing the title, firstname and
   * lastname
   */
  public getFullName(): string {
    return [this.firstName, this.lastName]
      .filter((v) => v) // remove empty values
      .join(' ');
  }

  getDataForPatch() {
    return {
      erm_email: this.ermEmail,
      ciam_email: this.ciamEmail,
      favourites: this.favourites,
      firstname: this.firstName,
      lastname: this.lastName,
      gender: this.gender,
      id: this.id,
      is_erm_user: this.isErmUser,
      title: this.title,
      company: this.company,
      phone: this.phone,
      mobile: this.mobile,
      fax: this.fax,
      roles: this.roles,
      has_pre_Media_access: this.hasPreMediaAccess,
      pre_media: this.preMedia,
    };
  }
}
