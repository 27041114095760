import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactPersonModel } from '@models/contact-person.model';
import { LinkTypes } from '@modules/card/enums/link-types.enum';
import { TargetType } from '@modules/card/enums/target-type.enum';
import { EventAdapter } from '@modules/content-api/adapter/event.adapter';
import { ContentAPIService } from '@modules/content-api/content-api.service';
import { TimePeriodPipe, TimePeriodPipeFormat } from '@modules/pipes/time-period.pipe';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { fullname } from 'src/helpers/strings';
import { z } from 'zod';

import { Attendance, EventAttendanceStatus } from '../../section/models/attendance.model';
import { EventModel, EventStates, EventTypes } from '../../section/models/event.model';

import { UserService } from './user.service';

const EventModuleSchema = z
  .object({
    module_from: z.string(),
    module_to: z.string(),
    module_name_official: z.string().nullable(),
    module_is_main: z.boolean(),
  })
  .transform((d) => ({
    from: d.module_from,
    to: d.module_to,
    isMain: d.module_is_main,
    name: d.module_name_official,
  }));

const CARLEventSchema = z
  .object({
    erm3_id: z.number(),
    city: z.string(),
    country: z.string(),
    url: z.string().nullable(),
    start_date: z.string().nullable(),
    end_date: z.string().nullable(),
    eventtype: z.string().nullable(),
    statusresponse: z.enum([
      'pending',
      'confirmed',
      'canceled',
      'representative',
      'expired',
      'declined',
      'has_substitute',
      'open',
    ]),
    reg_deadline: z.string().nullable(),
    event_modules: z.array(EventModuleSchema).optional(),
  })
  .transform((data) => ({
    carlId: data.erm3_id,
    startDate: data.start_date,
    endDate: data.end_date,
    city: data.city,
    country: data.country,
    url: data.url,
    eventType: data.eventtype,
    statusResponse: data.statusresponse,
    regDeadline: data.reg_deadline ? new Date(data.reg_deadline) : undefined,
    eventModules: data.event_modules,
  }));

const ContactsSchema = z
  .object({
    email: z.string().email(),
    firstname: z.string(),
    lastname: z.string(),
    function: z.string(),
    phone: z.string().nullable(),
    title: z.string(),
  })
  .transform((d) => ({
    ...d,
    position: d.function,
    phone: d.phone ?? undefined,
  }));

const ExpertsSchema = z
  .object({
    end_date: z.string(),
    event_modules: z.array(EventModuleSchema),
    firstname: z.string(),
    lastname: z.string(),
    start_date: z.string(),
    img: z.string().url().nullable(),
    topic: z.string(),
  })
  .transform((d) => ({
    firstname: d.firstname,
    lastname: d.lastname,
    position: d.topic,
    image: {
      type: 'file',
      mediaType: 'image',
      caption: fullname(d.firstname, d.lastname),
      formats: [
        {
          url: d.img ?? undefined,
        },
      ],
    },
    modules: d.event_modules,
    startDate: d.start_date,
    endDate: d.end_date,
  }));

const MyEventsSchema = z.object({
  events: z.string().uuid().array(),
});

export type EventModules = z.infer<typeof EventModuleSchema>;
export type CARLEvent = z.infer<typeof CARLEventSchema>;
export type Contacts = z.infer<typeof ContactsSchema>;

export type Expert = {
  contact: ContactPersonModel;
  availabilities: string[];
  availabilityPeriod: string;
};

const EVENTS_PARAMS = new HttpParams().appendAll({
  include: ['image,page,event_page'].join(),
  'fields[event_page]': 'slug',
  'fields[page]': 'slug',
  'page[limit]': '1000',
});

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  public myEventsCount$: Observable<number>;
  public pendingRegistrationsCount$: Observable<number>;

  private CARLEvents$: Observable<CARLEvent[]>;

  public pendingUpcomingRegistrations$ = new BehaviorSubject<number>(0);
  constructor(
    private apiService: ContentAPIService,
    private eventAdapter: EventAdapter,
    private userService: UserService,
    private timePeriodPipe: TimePeriodPipe
  ) {
    this.CARLEvents$ = this.getCARLEvents();
    this.myEventsCount$ = this.CARLEvents$.pipe(map((events) => events.length));
    this.pendingRegistrationsCount$ = this.CARLEvents$.pipe(
      map((events) => events.filter((event) => this.isEventPending(event)).length)
    );
  }

  public getCARLEvents() {
    return this.userService.isLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.apiService.getUnparsedResources('erm/v3/getuserevents').pipe(
            map((data: any) => {
              const events: CARLEvent[] = data.res.events.reduce((parsedEvents: CARLEvent[], event: any) => {
                const result = CARLEventSchema.safeParse(event);
                if (result.success) {
                  parsedEvents.push(result.data);
                } else {
                  console.error('Error parsing CARL event data', result.error);
                }
                return parsedEvents;
              }, []);

              return events;
            }),
            shareReplay(1),
            catchError(() => of([]))
          );
        } else {
          return of([]);
        }
      }),
      shareReplay(1)
    );
  }

  getEvents() {
    return this.userService.isLoggedIn().pipe(
      take(1),
      switchMap((isLoggedIn) => {
        const apiRequest = this.apiService
          .getResources('events', isLoggedIn ? EVENTS_PARAMS : EVENTS_PARAMS.set('page[limit]', '999'))
          .pipe(
            map((data) => data.map((item) => new EventModel(this.eventAdapter.parse(item)))),
            catchError(() => of([]))
          );

        const carlRequest = this.CARLEvents$;

        if (!isLoggedIn) {
          // TODO: add cache bust parameter to get personalized data immediatly
          return apiRequest.pipe(
            take(1),
            map((events) => {
              return this.sortEventsByState(this.filterEventsWithoutLink(events));
            })
          );
        }

        return combineLatest([apiRequest, carlRequest]).pipe(
          map(([events, carlEvents]) => {
            const [upcomingEvents, pastEvents] = this.sortEventsByState(events);

            carlEvents.forEach((carlEvent) => {
              const foundEvent = events.find((event) => {
                return event.carlId === carlEvent.carlId.toString();
              });

              if (foundEvent) {
                foundEvent.type = carlEvent.eventType as EventTypes;
                foundEvent.attendanceStatus = carlEvent.statusResponse as EventAttendanceStatus;
                foundEvent.deadline = carlEvent.regDeadline;

                // set personalized start and end date
                if (!!carlEvent.startDate && !!carlEvent.endDate) {
                  // by Einladungszeitraum
                  foundEvent.startDate = new Date(carlEvent.startDate);
                  foundEvent.endDate = new Date(carlEvent.endDate);
                } else {
                  const mainModule = carlEvent.eventModules?.find((modules) => modules.isMain);
                  if (mainModule) {
                    // else use main_module
                    foundEvent.startDate = new Date(mainModule.from);
                    foundEvent.endDate = new Date(mainModule.to);
                  } else {
                    // else use modules to calculate start and date
                    const sortedModules = carlEvent.eventModules?.sort((a, b) => +new Date(a.from) - +new Date(b.from));
                    if (sortedModules) {
                      foundEvent.startDate = new Date(sortedModules[0].from);
                      foundEvent.endDate = new Date(sortedModules[sortedModules.length - 1].to);
                    }
                  }
                }

                if (carlEvent.eventModules?.length) {
                  foundEvent.attendances = carlEvent.eventModules.map(
                    (eventModule) =>
                      new Attendance({
                        status: carlEvent.statusResponse as EventAttendanceStatus,
                        arrival: eventModule.from,
                        departure: eventModule.to,
                        registrationDeadline: carlEvent.regDeadline,
                      })
                  ) as Attendance[];
                }

                // only override link information if there is no event page
                if (!foundEvent.urlSlug && !!carlEvent.url) {
                  foundEvent.urlSlug = carlEvent.url;
                  // TODO: refactor event model and this service to make this more elegant
                  //       we should instantiate the model after we merged with the carl data
                  foundEvent.link = {
                    type: LinkTypes.EXTERNAL,
                    url: carlEvent.url,
                    query: '',
                    target: TargetType.SELF,
                  };
                }
              }
            });

            // do not show event cards that have no page, event_page or invitation link
            const clickableUpcomingEvents = this.filterEventsWithoutLink(upcomingEvents);
            const clickablePastEvents = this.filterEventsWithoutLink(pastEvents);

            this.pendingUpcomingRegistrations$.next(
              clickableUpcomingEvents.filter(
                (event) =>
                  event.attendanceStatus === EventAttendanceStatus.PENDING ||
                  event.attendanceStatus === EventAttendanceStatus.OPEN
              ).length
            );

            return [clickableUpcomingEvents, clickablePastEvents];
          })
        );
      })
    );
  }

  /**
   * Returns a list of past and future events the user has an CARL invitation to
   * Public events without an explicit booking are not included
   */
  public getMyEvents() {
    // TODO: refactor the getEvents service to clean this all up
    return this.getEvents().pipe(
      switchMap(([upcomingEvents, pastEvents]) => {
        return this.apiService.get('my-events').pipe(
          map((data) => {
            const myEvents = MyEventsSchema.safeParse(data);
            if (myEvents.success) {
              return upcomingEvents
                .concat(pastEvents)
                .filter((event) => myEvents.data.events.includes(event.id.toString()));
            } else {
              console.error(myEvents.error);
              return [];
            }
          })
        );
      })
    );
  }

  private filterEventsWithoutLink(events: EventModel[]) {
    return events.filter((e) => !!e.urlSlug);
  }

  private sortEventsByState(events: EventModel[]) {
    return [
      events
        .filter((event) => event.state === EventStates.UPCOMING || event.state === EventStates.RUNNING)
        .sort((a, b) => a.startDate.getTime() - b.startDate.getTime()),
      events
        .filter((event) => event.state === EventStates.PAST)
        .sort((a, b) => b.startDate.getTime() - a.startDate.getTime()),
    ];
  }

  public getCARLEvent(eventId: string) {
    return this.apiService
      .getUnparsedResources(
        'erm/v3/getuserevent',
        new HttpParams().appendAll({
          eventid: eventId,
        })
      )
      .pipe(
        map((data: any) => {
          const result = CARLEventSchema.safeParse(data.res.event);
          if (result.success) {
            return result.data;
          } else {
            console.error(result.error);
            return null;
          }
        }),
        shareReplay(1),
        catchError(() => of(null))
      );
  }

  // CARL Experts are already personalised and matched with the users event booking
  public getCARLExperts(eventId: string): Observable<Expert[]> {
    if (!eventId) {
      return of([]);
    }

    const expertsRequest: Observable<any> = this.apiService
      .getUnparsedResources(
        'erm/v3/getuserexperts',
        new HttpParams().appendAll({
          eventid: eventId,
        })
      )
      .pipe(catchError(() => of(null)));

    return expertsRequest.pipe(
      take(1),
      map((data) => {
        if (data?.res?.experts?.length) {
          return data.res.experts
            .map((rawExpert: any) => {
              const result = ExpertsSchema.safeParse(rawExpert);
              if (result.success) {
                if (result.data.modules.length === 0) {
                  // This case should not be possible, if so, use startDate and endDate (invatiation dates)
                  return {
                    contact: new ContactPersonModel(result.data),
                    availabilities: [result.data.startDate],
                    availabilityPeriod: this.timePeriodPipe.transform(
                      new Date(result.data.startDate),
                      new Date(result.data.endDate),
                      undefined,
                      TimePeriodPipeFormat.MEDIUM
                    ),
                  };
                }

                const availabilities = result.data.modules.flatMap((module) => {
                  return this.expandListOfDays(new Date(module.from), new Date(module.to)).map((date) =>
                    date.toString()
                  );
                });

                return {
                  contact: new ContactPersonModel(result.data),
                  availabilities: availabilities,
                  availabilityPeriod: this.timePeriodPipe.transform(
                    new Date(result.data.modules[0].from),
                    new Date(result.data.modules[result.data.modules.length - 1].to),
                    undefined,
                    TimePeriodPipeFormat.MEDIUM
                  ),
                };
              } else {
                console.error(result.error);
                return undefined;
              }
            })
            .filter((c: any): c is Expert => !!c);
        } else {
          return [];
        }
      })
    );
  }

  public getCARLContacts(eventId: string): Observable<ContactPersonModel[]> {
    if (!eventId) {
      return of([]);
    }

    const contactsRequest = this.apiService
      .getUnparsedResources(
        'erm/v3/getusercontacts',
        new HttpParams().appendAll({
          eventid: eventId,
        })
      )
      .pipe(catchError(() => of([])));

    return contactsRequest.pipe(
      map((data: any) => {
        if (data?.res?.contacts?.length) {
          return data.res.contacts
            .map((contact: any) => {
              const result = ContactsSchema.safeParse(contact);
              if (result.success) {
                return new ContactPersonModel(result.data);
              } else {
                console.error(result.error);
                return undefined;
              }
            })
            .filter((c: any): c is ContactPersonModel => !!c);
        } else {
          return [];
        }
      })
    );
  }

  private isEventPending(event: CARLEvent) {
    return ['open', 'pending'].includes(event.statusResponse);
  }

  private expandListOfDays(from: Date, to: Date): Date[] {
    let days: Date[] = [];
    for (let dt = new Date(from); dt <= new Date(to); dt.setDate(dt.getDate() + 1)) {
      days.push(new Date(dt)); // create new non-referenced date
    }
    return days;
  }
}
