import { z } from 'zod';

export const QAFormSchema = z
  .object({
    id: z.string().uuid(),
    author: z.string().nullable(),
    event_date: z.string().nullable(),
    submission_mode: z.enum(['email', 'store', 'liveqa']),
    title: z.string(),
    selectable_contact_people: z
      .object({
        // TODO: replace with contact person schemata
        id: z.string().uuid(),
        email: z.string().email(),
        first_name: z.string().nullable(),
        last_name: z.string().nullable(),
        position: z.string().nullable(),
      })
      .array()
      .nullable(),
  })
  .transform((data) => ({
    id: data.id,
    title: data.title,
    author: data.author,
    eventDate: data.event_date,
    submissionMode: data.submission_mode,
    selectableContactPeople: data.selectable_contact_people?.map((cp) => ({
      id: cp.id,
      firstname: cp.first_name,
      lastname: cp.last_name,
      position: cp.position,
    })),
  }));
export type QAForm = z.infer<typeof QAFormSchema>;
