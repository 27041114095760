<ng-container *ngIf="media">
  <ng-container [ngSwitch]="media.mediaType">
    <ng-container *ngSwitchCase="mediaType.IMAGE">
      <ng-container *ngTemplateOutlet="imageTpl; context: { image: media }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="mediaType.VIDEO">
      <ng-container *ngTemplateOutlet="videoTpl; context: { video: media }"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #imageTpl let-image="image">
  <mb-image [media]="image" (imageError)="imageError.emit($event)"></mb-image>
</ng-template>

<ng-template #videoTpl let-video="video">
  <mb-video
    [autoplay]="autoplay"
    [hoverVideo]="hoverplay"
    [media]="video"
    [muted]="muted"
    [controls]="controls"
    [hideTitle]="hideTitles"
    [hideBottomTitle]="hideTitles"
    [loop]="loop"
    [playerType]="playerType"
    [playsInline]="playsInline"
  ></mb-video>
</ng-template>
