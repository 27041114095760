<div class="time-control" *ngIf="media.status !== videoStatusStates.LIVE && player.playerData.showScrubberBar">
  <mb-video-scrubber class="control-element" [player]="player" [media]="media"></mb-video-scrubber>
</div>

<div class="control-items copy-s">
  <div class="state-btn control-element">
    <button
      class="btn btn-round"
      attr.aria-label="{{ ((player.playing$ | async) ? 'PAUSE' : 'PLAY') | translate }}"
      (click)="player.togglePlay()"
    >
      <span class="mb-icon mb-icon-player-{{ (player.playing$ | async) ? 'pause' : 'play' }}"></span>
    </button>
  </div>
  <ng-container *ngIf="media.status !== videoStatusStates.LIVE; else liveIndicator">
    <mb-duration
      class="control-element"
      *ngIf="!smallControls && player.playerData.showScrubberBar"
      [currentTime]="player.currentTime"
      [duration]="player.duration"
    ></mb-duration>
  </ng-container>

  <ng-template #liveIndicator>
    <div class="live-indicator copy-s control-element">
      <mb-live-indicator></mb-live-indicator>
      {{ 'LIVE' | translate }}
    </div>
  </ng-template>

  <mb-dropdown
    *ngIf="tracks.length > 1"
    class="control-element"
    [hideLabel]="smallControls"
    [options]="tracks"
    [openTop]="true"
    [icon]="'mb-icon-language-country-switch'"
    [value]="currentLang"
    (selectionChanged)="changeTrack($event)"
  ></mb-dropdown>

  <div *ngIf="player.hasVolume$ | async" class="audio-control control-element">
    <div *ngIf="!isIosDevice" class="audio-scrubber-wrapper">
      <mb-scrubber
        *ngIf="!smallControls"
        [value]="(player.volume$ | async) || 0"
        (progressChanging)="player.setVolume($event)"
      ></mb-scrubber>
    </div>
    <ng-container *ngIf="(player.muted$ | async) === false; else mutedItcon">
      <span
        *ngIf="this.player.volume$ | async as volume; else mutedItcon"
        class="mb-icon mb-icon-sound-{{ volume > 50 ? 'high' : 'low' }}"
        (click)="player.mute()"
      ></span>
    </ng-container>
    <ng-template #mutedItcon>
      <span class="mb-icon mb-icon-sound-mute" (click)="player.unmute()"></span>
    </ng-template>
  </div>
  <div class="icon-btn-controls">
    <ng-container *ngIf="player.resolutions$ | async as resolutions">
      <mb-resolution-controller
        *ngIf="!smallControls"
        class="control-element"
        [openBottom]="smallControls"
        [resolutions]="resolutions"
        [isComposed]="player.playerData.isComposed"
        [activeResolution]="player.activeBitrate"
        (resolutionIndexChanged)="player.setBitrate($event)"
      ></mb-resolution-controller>
    </ng-container>
    <div
      *ngIf="allowPictureInPicture && !smallControls"
      class="control-element btn btn-quaternary"
      (click)="toggleFlyout()"
    >
      <span class="mb-icon mb-icon-picture-in-picture"></span>
    </div>
    <div
      *ngIf="player.canFullscreen"
      class="fullscreen-btn control-element btn btn-quaternary"
      (click)="player.toggleFullscreen()"
    >
      <span class="mb-icon mb-icon-media-full-screen-{{ isFullscreen ? 'xs' : 'view-xs' }} "></span>
    </div>
  </div>
  <div
    *ngIf="!isEmbedPlayer && player.playerData.screenshotUrl && player.playerData.showScreenshotButton"
    class="screenshot-btn btn btn-round"
    (click)="emitCapture()"
    (touchstart)="$event.stopPropagation()"
    (mousemove)="$event.stopPropagation()"
  >
    <span class="mb-icon mb-icon-image-gallery"></span>
  </div>
</div>
