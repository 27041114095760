import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UserService } from '@modules/shared/services/user.service';

@Component({
  selector: 'mb-first-favorite-modal',
  templateUrl: './first-favorite-modal.component.html',
  styleUrls: ['./first-favorite-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstFavoriteModalComponent {
  constructor(private userService: UserService) {}

  signUp() {
    this.userService.login();
  }
}
