<footer>
  <div class="dat-label">
    <wb-text size="m" static strong>{{ 'DAT_LABEL' | translate }}</wb-text>
    <wb-button href="/presscontacts" (click)="wbLinkClick($event, ['presscontacts'])" variant="secondary" theme="dark">
      {{ 'PRESS_CONTACTS' | translate }}
    </wb-button>
  </div>

  <div class="social-link-row">
    <ul>
      <li>
        <a href="https://www.instagram.com/mercedesbenz" aria-label="Instagram" target="_blank">
          <wb-icon slot="icon" name="bds/instagram/24"></wb-icon>
        </a>
      </li>
      <li>
        <a href="https://facebook.com/MercedesBenz" aria-label="Facebook" target="_blank">
          <wb-icon slot="icon" name="bds/facebook/24"></wb-icon>
        </a>
      </li>
      <li>
        <a href="https://x.com/mercedesbenz" aria-label="X" target="_blank">
          <wb-icon slot="icon" name="bds/x/24"></wb-icon>
        </a>
      </li>
      <li>
        <a href="https://linkedin.com/company/mercedes-benz_ag" aria-label="LinkedIn" target="_blank">
          <wb-icon slot="icon" name="bds/linkedin/24"></wb-icon>
        </a>
      </li>
      <li>
        <a href="https://youtube.com/user/MercedesBenzTV" aria-label="YouTube" target="_blank">
          <wb-icon slot="icon" name="bds/youtube/24"></wb-icon>
        </a>
      </li>
      <li>
        <a href="https://tiktok.com/@mercedesbenz" aria-label="TikTok" target="_blank">
          <wb-icon src="assets/icons/mb-tiktok.svg"></wb-icon>
        </a>
      </li>
    </ul>
  </div>

  <div class="more-information">
    <wb-heading size="l">{{ 'MORE_INFORMATION' | translate }}</wb-heading>

    <nav>
      <section>
        <wb-text size="l" static strong>{{ 'FOOTER.COMPANY.HEADLINE' | translate }}</wb-text>
        <wb-link href="{{ 'FOOTER.COMPANY.OVERVIEW.LINK' | translate }}" variant="small" theme="dark" target="_blank">
          {{ 'FOOTER.COMPANY.OVERVIEW.LABEL' | translate }}
        </wb-link>
        <wb-link href="{{ 'FOOTER.COMPANY.CAREERS.LINK' | translate }}" variant="small" theme="dark" target="_blank">
          {{ 'FOOTER.COMPANY.CAREERS.LABEL' | translate }}
        </wb-link>
        <wb-link href="{{ 'FOOTER.COMPANY.INVESTORS.LINK' | translate }}" variant="small" theme="dark" target="_blank">
          {{ 'FOOTER.COMPANY.INVESTORS.LABEL' | translate }}
        </wb-link>
        <wb-link href="{{ 'FOOTER.COMPANY.LOCATIONS.LINK' | translate }}" variant="small" theme="dark" target="_blank">
          {{ 'FOOTER.COMPANY.LOCATIONS.LABEL' | translate }}
        </wb-link>
      </section>

      <section>
        <wb-text size="l" static strong>{{ 'FOOTER.KNOWLEDGE.HEADLINE' | translate }}</wb-text>
        <wb-link
          href="{{ 'FOOTER.KNOWLEDGE.CORPORATE_GOVERNANCE.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.KNOWLEDGE.CORPORATE_GOVERNANCE.LABEL' | translate }}
        </wb-link>
        <wb-link
          href="{{ 'FOOTER.KNOWLEDGE.COMPLIANCE.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.KNOWLEDGE.COMPLIANCE.LABEL' | translate }}
        </wb-link>
        <wb-link
          href="{{ 'FOOTER.KNOWLEDGE.KEY_FIGURES.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.KNOWLEDGE.KEY_FIGURES.LABEL' | translate }}
        </wb-link>
        <wb-link
          href="{{ 'FOOTER.KNOWLEDGE.SUSTAINABILITY.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.KNOWLEDGE.SUSTAINABILITY.LABEL' | translate }}
        </wb-link>
        <wb-link
          href="{{ 'FOOTER.KNOWLEDGE.CORPORATE_STRATEGY.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.KNOWLEDGE.CORPORATE_STRATEGY.LABEL' | translate }}
        </wb-link>
        <wb-link
          href="{{ 'FOOTER.KNOWLEDGE.TRADITION.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.KNOWLEDGE.TRADITION.LABEL' | translate }}
        </wb-link>
      </section>

      <section>
        <wb-text size="l" static strong>{{ 'FOOTER.SERVICES.HEADLINE' | translate }}</wb-text>
        <wb-link
          href="/presscontacts"
          (click)="wbLinkClick($event, ['presscontacts'], { state: { scrollRestoration: 'top' } })"
          variant="small"
          theme="dark"
        >
          {{ 'PRESS_CONTACTS' | translate }}
        </wb-link>
        <wb-link
          href="/newsletter/subscribe"
          (click)="wbLinkClick($event, ['newsletter', 'subscribe'], { state: { scrollRestoration: 'top' } })"
          variant="small"
          theme="dark"
        >
          {{ 'NEWSLETTER' | translate }}
        </wb-link>
        <wb-link
          href="{{ 'FOOTER.SERVICES.CORPORATE_ARCHIVE.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.SERVICES.CORPORATE_ARCHIVE.LABEL' | translate }}
        </wb-link>
        <wb-link
          href="/national-media-sites"
          (click)="wbLinkClick($event, ['national-media-sites'], { state: { scrollRestoration: 'top' } })"
          variant="small"
          theme="dark"
        >
          {{ 'NATIONAL_MEDIA_SITES' | translate }}
        </wb-link>
        <wb-link
          href="{{ 'FOOTER.SERVICES.MB_MAGAZINE.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.SERVICES.MB_MAGAZINE.LABEL' | translate }}
        </wb-link>
        <wb-link
          href="{{ 'FOOTER.SERVICES.MB_CLASSIC_MAGAZINE.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.SERVICES.MB_CLASSIC_MAGAZINE.LABEL' | translate }}
        </wb-link>
      </section>
      <section>
        <wb-text size="l" static strong>{{ 'FOOTER.PURCHASE.HEADLINE' | translate }}</wb-text>
        <wb-link href="{{ 'FOOTER.PURCHASE.VEHICLES.LINK' | translate }}" variant="small" theme="dark" target="_blank">
          {{ 'FOOTER.PURCHASE.VEHICLES.LABEL' | translate }}
        </wb-link>
        <wb-link
          href="{{ 'FOOTER.PURCHASE.ACCESSORIES.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.PURCHASE.ACCESSORIES.LABEL' | translate }}
        </wb-link>
        <wb-link
          href="{{ 'FOOTER.PURCHASE.DIGITAL_EXTRAS.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.PURCHASE.DIGITAL_EXTRAS.LABEL' | translate }}
        </wb-link>
        <wb-link
          href="{{ 'FOOTER.PURCHASE.CLASSIC_CARS.LINK' | translate }}"
          variant="small"
          theme="dark"
          target="_blank"
        >
          {{ 'FOOTER.PURCHASE.CLASSIC_CARS.LABEL' | translate }}
        </wb-link>
      </section>
    </nav>
  </div>

  <div class="bottom-navigation">
    <wb-header-language-menu aria-label="{{ 'LANGUAGE_MENU' | translate }}">
      <wb-header-language-menu-item
        *ngFor="let lang of languages"
        (click)="changeLanguage(lang.isoLang)"
        [active]="currentLanguage === lang.isoLang"
      >
        {{ lang.label }}
      </wb-header-language-menu-item>
    </wb-header-language-menu>

    <ul>
      <li>
        <wb-link
          variant="small"
          theme="dark"
          href="/legal/provider"
          (click)="
            wbLinkClick($event, ['/legal/provider'], {
              state: { scrollRestoration: 'top' },
            })
          "
        >
          {{ 'LEGAL_PRIVACY_PROVIDER_SHORT' | translate }}
        </wb-link>
      </li>
      <li>
        <wb-link
          variant="small"
          theme="dark"
          href="/legal/legal-notice"
          (click)="
            wbLinkClick($event, ['/legal/legal-notice'], {
              state: { scrollRestoration: 'top' },
            })
          "
        >
          {{ 'LEGAL_LEGAL_NOTICE' | translate }}
        </wb-link>
      </li>
      <li>
        <wb-link
          variant="small"
          theme="dark"
          href="/legal/settings"
          (click)="
            wbLinkClick($event, ['/legal/settings'], {
              state: { scrollRestoration: 'top' },
            })
          "
        >
          {{ 'LEGAL_CONSENT_SETTINGS' | translate }}
        </wb-link>
      </li>
      <li>
        <wb-link
          variant="small"
          theme="dark"
          href="/legal/privacy-statement"
          (click)="
            wbLinkClick($event, ['/legal/privacy-statement'], {
              state: { scrollRestoration: 'top' },
            })
          "
        >
          {{ 'LEGAL_PRIVACY' | translate }}
        </wb-link>
      </li>
      <li *ngIf="showCCPALink">
        <wb-link
          variant="small"
          theme="dark"
          href="/legal/privacy-statement#ccpa"
          (click)="
            wbLinkClick($event, ['/legal/privacy-statement'], {
              state: { scrollRestoration: 'top' },
              fragment: 'CCPA',
            })
          "
        >
          {{ 'Do not sell or share my personal information' }}
        </wb-link>
      </li>
      <li>
        <wb-link
          variant="small"
          theme="dark"
          href="/legal/terms-of-use"
          (click)="
            wbLinkClick($event, ['/legal/terms-of-use'], {
              state: { scrollRestoration: 'top' },
            })
          "
          >{{ 'LEGAL_TERMS_OF_USE' | translate }}
        </wb-link>
      </li>
      <li>
        <wb-link variant="small" theme="dark" class="copy-s" [href]="supportUrl" target="_blank" rel="help">
          {{ 'FOOTER.SUPPORT.BUTTON_LABEL' | translate }}
        </wb-link>
      </li>
      <li>
        <wb-link
          href="/license-notice"
          (click)="wbLinkClick($event, ['license-notice'], { state: { scrollRestoration: 'top' } })"
          variant="small"
          theme="dark"
        >
          {{ 'THIRD_PARTY_LICENSES' | translate }}
        </wb-link>
      </li>
    </ul>
  </div>
</footer>
