import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  ViewEncapsulation,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { WindowService } from '@modules/shared/services/window.service';
import { Subject } from 'rxjs';

import { CaptureModel } from '../models/capture.model';
import { MediaVideoModel } from '../models/media-video.model';

export enum PlayerType {
  INLINE = 'inline',
  SIMPLE = 'simple',
}
@Component({
  selector: 'mb-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video-defaults.scss', './video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class VideoComponent implements OnInit {
  public playerTypes = PlayerType;
  public capture!: CaptureModel;
  public showCapture$ = new Subject<boolean>();

  @HostBinding('class.video-landscape') landscape = false;
  @HostBinding('class.video-portrait') portrait = false;

  @Input() autoplay = false;
  @Input() showBackButton = false;
  @Input() muted = false;
  @Input() playsInline = false;

  private _media!: MediaVideoModel;
  @Input()
  set media(v: MediaVideoModel) {
    this._media = v;
    if (v.original.ratio < 1) {
      this.portrait = true;
      this.landscape = false;
    } else if (v.original.ratio > 1) {
      this.portrait = false;
      this.landscape = true;
    } else {
      this.portrait = false;
      this.landscape = false;
    }
  }
  get media() {
    return this._media;
  }

  @Input() playerType: string = PlayerType.INLINE;
  @Input() controls = true;
  @Input() hoverVideo = false;
  @Input() hideTitle = false;
  @Input() hideBottomTitle = false;
  @Input() allowPictureInPicture = true;
  @Input() loop = false;
  @Output() backButton = new EventEmitter();

  constructor(private windowService: WindowService) {}

  public ngOnInit(): void {
    if (this._media) {
      // select small resultions for mobile devices
      const responsiveFormat = this._media.closestToWidth(window.innerWidth);
      const previewFormat = this._media.formats.find((format) => format.key === 'preview');
      const isMobile = this.windowService.isMobileBrowser && window.innerWidth < 768;
      const preferredFormat = (isMobile ? responsiveFormat : previewFormat) ?? this._media.formats[0];
      this._media.sources = preferredFormat
        ? [
            {
              type: preferredFormat.mimeType,
              src: preferredFormat.url,
            },
          ]
        : this._media.sources;
    }
  }

  public setCapture(capture: CaptureModel) {
    this.capture = capture;
    this.showCapture$.next(true);
  }

  public hideCapure() {
    this.showCapture$.next(false);
  }
}
