import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Input, Signal, computed } from '@angular/core';
import { TrackingItem } from '@modules/shared/gtm/data-layer.types';
import { DataLayerService } from '@modules/shared/services/data-layer.service';
import { ModalService } from '@modules/shared/services/modal.service';
import { SharingData } from '@modules/shared/services/share.service';

import { ModalComponent } from '../modal/modal.component';

type ShareTargetType = 'email' | 'facebook' | 'x' | 'linkedin' | 'whatsapp';

type ShareTarget = {
  name: ShareTargetType;
  icon: string;
  url: string;
  legacyIcon?: boolean;
};

@Component({
  selector: 'mb-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareModalComponent extends ModalComponent {
  @Input({ required: true }) sharingData!: SharingData;
  @Input() public trackingData?: TrackingItem;

  public copied = false;
  public shareTargets: Signal<ShareTarget[]>;

  constructor(
    private modalService: ModalService,
    private dataLayerService: DataLayerService,
    private clipboard: Clipboard
  ) {
    super();
    this.shareTargets = computed((): ShareTarget[] => [
      {
        name: 'email',
        icon: 'bds/mail/24',
        url: this.emailShareUrl(this.sharingData),
      },
      {
        name: 'facebook',
        icon: 'bds/facebook/24',
        url: this.facebookShareUrl(this.sharingData),
      },
      {
        name: 'x',
        icon: 'bds/x/24',
        url: this.xShareUrl(this.sharingData),
      },
      {
        name: 'linkedin',
        icon: 'bds/linkedin/24',
        url: this.linkedinShareUrl(this.sharingData),
      },
      {
        name: 'whatsapp',
        icon: 'mb-icon-whatsapp',
        legacyIcon: true,
        url: this.whatsappShareUrl(this.sharingData),
      },
    ]);
  }

  public onCloseClick(): void {
    this.modalService.close();
  }

  public copyToClipboard() {
    this.clipboard.copy(this.sharingData.url);
    this.copied = true;
  }

  public trackShareEvent(shareTarget: ShareTarget) {
    if (this.trackingData) {
      this.dataLayerService.pushEvent({
        event: 'socialShare',
        item: this.trackingData,
        method: shareTarget.name,
        sharedUrl: this.sharingData.url,
      });
    }
  }

  private emailShareUrl(sharingData: SharingData) {
    const body = [sharingData.fullContent || sharingData.content, sharingData.url].filter((v) => v).join(': ');
    const subject = sharingData.title;
    return new URL(`mailto:?body=${body}&subject=${subject}`).toString();
  }

  private facebookShareUrl(sharingData: SharingData) {
    const url = sharingData.url;
    return new URL(`https://www.facebook.com/sharer/sharer.php?u=${url}`).toString();
  }

  private xShareUrl(sharingData: SharingData) {
    const text = [sharingData.title, sharingData.content].filter((v) => v).join(': ');
    const url = sharingData.url;
    return new URL(`https://x.com/share?text=${text}&url=${url}`).toString();
  }

  private linkedinShareUrl(sharingData: SharingData) {
    // https://developer.linkedin.com/docs/share-on-linkedin
    const title = sharingData.title;
    const url = sharingData.url;
    const summary = sharingData.content;
    return new URL(
      `https://www.linkedin.com/shareArticle?title=${title}&url=${url}&summary=${summary}&mini=true`
    ).toString();
  }

  private whatsappShareUrl(sharingData: SharingData) {
    const text = [sharingData.title, sharingData.content, sharingData.url].filter((v) => v).join(': ');
    return new URL(`https://api.whatsapp.com/send?text=${text}`).toString();
  }
}
