import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
export interface MetaItemInterface {
  label: string;
  count?: number;
  minutes?: string;
  seconds?: string;
  data?: any;
}
@Component({
  selector: 'mb-meta-items',
  templateUrl: './meta-items.component.html',
  styleUrls: ['./meta-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MetaItemsComponent {
  @Input() inheritFont = false;
  @Input() showEmpty = false;
  @Input() itemClass = '';
  @Input() metaItems: MetaItemInterface[] = [];

  @Output() itemClicked = new EventEmitter<MetaItemInterface>();
}
