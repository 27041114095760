import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { skip } from 'rxjs';

import { DataLayerService } from './data-layer.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalGTMEventsService {
  constructor(
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private translateService: TranslateService
  ) {}

  public handleGlobalGTMEvents() {
    // Login event
    this.userService.login$.subscribe(() => {
      this.dataLayerService.pushEvent({ event: 'userLogout' });
    });

    // Logout event
    this.userService.logout$.subscribe(() => {
      this.dataLayerService.pushEvent({ event: 'userLogout' });
    });

    // Language change event (skip first event, which is setting the default language)
    this.translateService.onLangChange.pipe(skip(1)).subscribe((event: LangChangeEvent) => {
      this.dataLayerService.pushEvent({ event: 'languageChanged', language: event.lang.split('-')[0] });
    });
  }
}
