import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PreMedia, PreMediaAdapter } from '@app/features/pre-media/pre-media.adapter';
import { ContentAPIService } from '@modules/content-api/content-api.service';
import { UserService } from '@modules/shared/services/user.service';
import { BehaviorSubject, EMPTY, of, switchMap } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { z } from 'zod';

const PRE_MEDIA_OVERVIEW_PAGE_PARAMS = new HttpParams().appendAll({
  include: [
    'reference',
    'reference.pre_media_article.header_image.image',
    'reference.pre_media_article.header_image.adjusted_image',
    'reference.pre_media_article.header_image.alternate_images',
    'reference.pre_media_presskit.main_image.image',
    'reference.pre_media_presskit.main_image.adjusted_image',
    'reference.pre_media_presskit.main_image.alternate_images.adjusted_image',
    'reference.pre_media_media_collection',
    'reference.pre_media_image.image',
    'reference.pre_media_image.adjusted_image',
    'reference.pre_media_video.video',
    'reference.pre_media_video.poster_image',
    'reference.pre_media_document.file',
    'reference.pre_media_audio.audio',
  ].join(),
  'fields[structured_image]': 'image',
  'fields[media--alternate_images]': 'image',
  'filter[archived]': 0,
});

const PRE_MEDIA_DETAIL_PARAMS = new HttpParams().appendAll({
  include: [
    'reference.pre_media_article.header_image.image',
    'reference.pre_media_article.header_image.adjusted_image',
    'reference.pre_media_article.header_image.alternate_images',
    'reference.pre_media_article.press',
    'reference.pre_media_article.images.image',
    'reference.pre_media_article.images.adjusted_image',
    'reference.pre_media_article.documents.file',
    'reference.pre_media_article.videos.video',
    'reference.pre_media_article.videos.poster_image',
    'reference.pre_media_article.audios.audio',
    'reference.pre_media_presskit.main_image.image',
    'reference.pre_media_presskit.main_image.adjusted_image',
    'reference.pre_media_presskit.main_image.alternate_images.adjusted_image',
    'reference.pre_media_presskit.articles.header_image.image',
    'reference.pre_media_presskit.articles.header_image.adjusted_image',
    'reference.pre_media_presskit.articles.header_image.alternate_images.image',
    'reference.pre_media_presskit.articles.header_image.alternate_images.adjusted_image',
    'reference.pre_media_presskit.articles.audios.audio',
    'reference.pre_media_presskit.articles.videos.video',
    'reference.pre_media_presskit.articles.videos.poster_image',
    'reference.pre_media_presskit.articles.images.image',
    'reference.pre_media_presskit.articles.images.adjusted_image',
    'reference.pre_media_presskit.articles.documents.file',
    'reference.pre_media_presskit.articles.press',
    'reference.pre_media_presskit.contact_persons',
    'reference.pre_media_presskit.audios.audio',
    'reference.pre_media_presskit.documents.file',
    'reference.pre_media_presskit.images.image',
    'reference.pre_media_presskit.images.adjusted_image',
    'reference.pre_media_presskit.videos.video',
    'reference.pre_media_presskit.videos.poster_image',
    'reference.pre_media_media_collection.images.image',
    'reference.pre_media_media_collection.images.adjusted_image',
    'reference.pre_media_media_collection.audios.audio',
    'reference.pre_media_media_collection.documents.file',
    'reference.pre_media_media_collection.videos.video',
    'reference.pre_media_media_collection.videos.poster_image',
    'reference.pre_media_image.image',
    'reference.pre_media_image.adjusted_image',
    'reference.pre_media_video.video',
    'reference.pre_media_video.poster_image',
    'reference.pre_media_document.file',
    'reference.pre_media_audio.audio',
  ].join(),
  'fields[structured_image]': 'image',
  'fields[media--alternate_images]': 'image',
  'filter[archived]': 0,
});

const PreMediaUserCheck = z.object({
  pre_media: z.boolean(),
  new_count: z.number(),
  pre_media_count: z.number(),
});

@Injectable({
  providedIn: 'root',
})
export class PreMediaService {
  private _preMediaCount$ = new BehaviorSubject<number>(0);
  private _allPreMediaCount$ = new BehaviorSubject<number>(0);
  private _hasPreMediaAccess$ = new BehaviorSubject<boolean>(false);

  public preMediaCount$ = this._preMediaCount$.asObservable();
  public allPreMediaCount$ = this._allPreMediaCount$.asObservable();
  public hasPreMedia$ = this._hasPreMediaAccess$.asObservable();

  constructor(
    private apiService: ContentAPIService,
    private userService: UserService,
    private preMediaAdapter: PreMediaAdapter
  ) {
    this.userService.isLoggedIn().subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.setPreMediaCounter();
      } else {
        this._hasPreMediaAccess$.next(false);
        this._preMediaCount$.next(0);
        this._allPreMediaCount$.next(0);
      }
    });
  }

  public getAllPreMedias() {
    return this.apiService.getResources('premedias', PRE_MEDIA_OVERVIEW_PAGE_PARAMS).pipe(
      map((data) => {
        return data
          ?.map((preMedia) => {
            try {
              return this.preMediaAdapter.parse(preMedia);
            } catch (error) {
              console.error(error);
              return false;
            }
          })
          .filter(Boolean) as PreMedia[];
      }),
      catchError(() => EMPTY) // TODO: better error handling?
    );
  }

  public getPreMedia(id: string) {
    return this.apiService.getResource('premedias', id, PRE_MEDIA_DETAIL_PARAMS).pipe(
      map((data) => {
        try {
          return this.preMediaAdapter.parse(data);
        } catch (error) {
          console.error(error);
          return null;
        }
      }),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      }),
      shareReplay(1)
    );
  }

  public confirmPreMedia(id: string) {
    return this.apiService.post(`premedias/embargo_confirm/${id}`).pipe(
      switchMap((res) => {
        if (res) {
          return this.getPreMedia(id);
        }
        return of(null);
      })
    );
  }

  public confirmDownload(id: string) {
    this.apiService
      .post(`premedias/download_confirm/${id}`)
      .pipe(
        catchError(() => {
          return of(null);
        })
      )
      .subscribe();
  }

  public setPreMediaCounter() {
    this.apiService.get('premedias/user_check').subscribe((data: any) => {
      const userCheck = PreMediaUserCheck.safeParse(data);
      if (userCheck.success) {
        this._preMediaCount$.next(userCheck.data.new_count);
        this._allPreMediaCount$.next(userCheck.data.pre_media_count);
        this._hasPreMediaAccess$.next(userCheck.data.pre_media);
      }
    });
  }
}
