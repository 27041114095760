import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QAForm } from '@modules/content-api/adapter/qaform.adapter';
import { ContentAPIService } from '@modules/content-api/content-api.service';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { fullname } from 'src/helpers/strings';

import { DialogComponent } from '../dialog/dialog.component';
import { ContactFormComponent, ContactFormState } from '../modal/contact-form/contact-form.component';

import { DialogDataInterface } from './dialog.service';
import { ScrollLockService } from './scroll-lock.service';
import { UserService } from './user.service';

export interface QAFormDataInterface {
  question?: string;
  firstName?: string;
  lastName?: string;
  editorialTeam?: string;
  email?: string;
  termsOfUseCheck?: boolean;
  contactApprovalCheck?: boolean;
  contact?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(
    private dialog: MatDialog,
    private apiService: ContentAPIService,
    private userService: UserService,
    private scrollLockService: ScrollLockService
  ) {}

  public openQaForm(qaForm?: QAForm, data?: QAFormDataInterface) {
    if (!qaForm) {
      console.error('Target type is modal, but no QA Form connected.');
      return;
    }
    this.userService.user$.pipe(take(1)).subscribe((user) => {
      let userData: QAFormDataInterface = {};
      if (user) {
        userData.firstName = user.firstName;
        userData.lastName = user.lastName;
        userData.email = user.ciamEmail || user.ermEmail;
      }
      const ref = this.dialog.open(DialogComponent, {
        data: <DialogDataInterface>{
          component: ContactFormComponent,
          inputs: {
            contacts:
              qaForm.selectableContactPeople?.map((contact) => {
                return {
                  label: [fullname(contact.firstname, contact.lastname), contact.position].filter(Boolean).join(', '),
                  value: contact.id,
                };
              }) || [],
            data: Object.assign(data || {}, userData),
          },
          outputs: {
            confirm: (data) => {
              const form = ref.componentInstance.component.instance as ContactFormComponent;
              form.setState(ContactFormState.LOADING);
              this.apiService
                .post('qa_submissions', this.parseQaFormData(qaForm, data))
                .pipe(
                  catchError(() => {
                    form.setState(ContactFormState.ERROR);
                    return of(null);
                  })
                )
                .subscribe((response) => {
                  if (!response) {
                    return;
                  }
                  form.setState(ContactFormState.SUCCESS);
                });
            },
          },
        },
        panelClass: 'mb-dialog-pane',
        scrollStrategy: new NoopScrollStrategy(),
      });

      ref
        .afterOpened()
        .pipe(take(1))
        .subscribe(() => {
          this.scrollLockService.lock();
        });

      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.scrollLockService.unlock();
        });
    });
  }

  private parseQaFormData(qaForm: QAForm, data: QAFormDataInterface) {
    const relationships: any = {
      qa_form: {
        data: { id: qaForm.id, type: 'qa_form' },
      },
    };
    if (data.contact) {
      relationships.selected_contacts = {
        data: { id: data.contact, type: 'contact' },
      };
    }
    return {
      data: {
        type: 'qa_submission',
        attributes: {
          text: data.question,
          first_name: data.firstName,
          last_name: data.lastName,
          editorial_office: data.editorialTeam,
          opt_in_privacy_policy: data.termsOfUseCheck,
          opt_in_is_journalist: data.contactApprovalCheck,
          email: data.email,
        },
        relationships,
      },
    };
  }
}
