import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';

import { WindowService } from '../services/window.service';

export interface SpyItem {
  label: string;
  clientRect: ClientRect;
  active: boolean;
  spySection: string;
  order: number;
}

@Injectable()
export class ScrollSpyService implements OnDestroy {
  public spyItems$: BehaviorSubject<SpyItem[]> = new BehaviorSubject([] as SpyItem[]);
  public dynamicSpyItems$: BehaviorSubject<SpyItem[]> = new BehaviorSubject([] as SpyItem[]);
  public activeItem$: ReplaySubject<{ previous: SpyItem; next: SpyItem }> = new ReplaySubject();

  private spySectionList: SpyItem[] = [];
  private spySectionDynamicList: SpyItem[] = [];
  private windowScrollSubscription: Subscription | null = null;

  constructor(private _window: WindowService) {}

  /**
   * add an new SpyItem, this will automaticly added by srollSpy-directive
   * @param spyItem spyItem to add
   */
  public addSpyItem(spyItem: SpyItem) {
    this.spySectionList.push(spyItem);
    if (!this.windowScrollSubscription) {
      this.registerScrollListener();
    }
    this.onitemChange();
  }
  /**
   * add an new DynamicSpyItem, this will automaticly added by srollSpy-directive
   * @param spyItem spyItem to add
   */
  public addSpyDynamicItem(spyItem: SpyItem) {
    this.spySectionDynamicList.push(spyItem);
    if (!this.windowScrollSubscription) {
      this.registerScrollListener();
    }
    this.onitemChange();
  }

  /**
   * remove the SpyItem, this will automaticly removed by scrollSpy-directive
   * @param spyItem spyItem to remove
   */
  public removeSpyItem(spyItem: SpyItem) {
    this.spySectionList = this.spySectionList.filter((item) => item !== spyItem);
    this.unregisterScrollLostener();
    this.onitemChange();
  }

  /**
   * remove the DynamicSpyItem, this will automaticly removed by scrollSpy-directive
   * @param spyItem spyItem to remove
   */
  public removeSpyDynamicItem(spyItem: SpyItem) {
    this.spySectionDynamicList = this.spySectionDynamicList.filter((item) => item !== spyItem);
    this.unregisterScrollLostener();
    this.onitemChange();
  }

  private unregisterScrollLostener() {
    if (this.windowScrollSubscription && !this.spySectionList.length && !this.spySectionDynamicList.length) {
      this.windowScrollSubscription.unsubscribe();
      this.windowScrollSubscription = null;
    }
  }
  private registerScrollListener() {
    let lastActiveItem = this.spySectionList[0];
    if (lastActiveItem) {
      lastActiveItem.active = true;
    }
    this.windowScrollSubscription = this._window.windowScroll$.subscribe(() => {
      let nextActiveItem: SpyItem | null = null;
      const windowCenter = this._window.window.innerHeight / 2;
      for (const item of this.spyItems$.value) {
        if (item.clientRect.top < windowCenter) {
          nextActiveItem = this.getNextItem(item, nextActiveItem);
        }
      }
      for (const item of this.dynamicSpyItems$.value) {
        if (item.clientRect.top < windowCenter) {
          nextActiveItem = this.getNextItem(item, nextActiveItem);
        }
      }
      if (nextActiveItem && nextActiveItem !== lastActiveItem) {
        if (lastActiveItem) {
          lastActiveItem.active = false;
        }
        const previous = lastActiveItem;
        lastActiveItem = nextActiveItem;
        nextActiveItem.active = true;
        this.activeItem$.next({ previous, next: nextActiveItem });
        this.onitemChange();
      }
    });
  }

  private getNextItem(item: SpyItem, nextItem: SpyItem | null) {
    if (!nextItem) {
      return item;
    }
    if (item.clientRect.top > nextItem.clientRect.top) {
      return item;
    }
    return nextItem;
  }

  private onitemChange() {
    this.spyItems$.next([...this.spySectionList.sort((first, second) => first.order - second.order)]);
    this.dynamicSpyItems$.next([...this.spySectionDynamicList.sort((first, second) => first.order - second.order)]);
  }

  ngOnDestroy() {
    if (this.windowScrollSubscription) {
      this.windowScrollSubscription.unsubscribe();
    }
  }
}
