import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParsedEntryInterface } from '@modules/content-api/api.interface';
import { ContentAPIService } from '@modules/content-api/content-api.service';
import { BaseConfirmationComponent } from '@modules/shared/modal/base-confirmation/base-confirmation.component';
import { FirstFavoriteModalComponent } from '@modules/shared/modal/first-favorite-modal/first-favorite-modal.component';
import { User } from '@modules/shared/models/user.model';
import { DataLayerService, TrackableContent } from '@modules/shared/services/data-layer.service';
import { DialogService } from '@modules/shared/services/dialog.service';
import { ScrollLockService } from '@modules/shared/services/scroll-lock.service';
import { UserService } from '@modules/shared/services/user.service';
import { ToastService } from '@modules/toast/toast.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { FavoriteFromApiAdapter } from '../adapter/favorite-from-api.adapter';
import { FavoriteToApiAdapter } from '../adapter/favorite-to-api.adapter';
import { FavorableContent } from '../interfaces/favorable-content.interface';
import { FavoriteDataInterface } from '../interfaces/favorite-data.interface';
import { FavoriteResponseInterface } from '../interfaces/favorite-response.interface';
import { LocalFavoriteDataInterface } from '../interfaces/local-favorite-data.interface';

/**
 * this service is a splitup of the favorites content service to avoid circular dependancies
 * import this service only if you want to add / remove favorites.
 * if you want to get a list of Favorite classes, import FavoritesContentService instead.
 */
@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  private _favoritesOpen$ = new BehaviorSubject<boolean>(false);
  private _favoritesByKey: { [key: string]: LocalFavoriteDataInterface } = {};
  private _favoritesInit$ = new ReplaySubject<LocalFavoriteDataInterface[]>();
  private _favoriteAdded$ = new Subject<LocalFavoriteDataInterface>();
  private _favoriteUpdated$ = new Subject<LocalFavoriteDataInterface>();
  private _favoriteRemoved$ = new Subject<string>();
  private _favoritesCleared$ = new Subject<boolean>();

  public favoritesOpen$: Observable<boolean> = this._favoritesOpen$.asObservable();
  public favoritesInit$: Observable<LocalFavoriteDataInterface[]> = this._favoritesInit$.asObservable();
  public favoriteAdded$ = this._favoriteAdded$.asObservable();
  public favoriteUpdated$ = this._favoriteUpdated$.asObservable();
  public favoriteRemoved$ = this._favoriteRemoved$.asObservable();
  public favoritesCleared$ = this._favoritesCleared$.asObservable();

  constructor(
    private userService: UserService,
    private apiService: ContentAPIService,
    private dialogService: DialogService,
    private toastService: ToastService,
    private dataLayerService: DataLayerService,
    private scrollLockService: ScrollLockService
  ) {
    // load local favorites on page load
    const localFavorites = this.getLocalFavorites();
    for (let id in localFavorites) {
      this._favoritesByKey[id] = localFavorites[id];
    }
    this._favoritesInit$.next(Object.values(this._favoritesByKey));
    /**
     * load the favorites from backend and set them locally
     * if there are local favorites which are not present in the backend, sync them
     */
    this.userService.user$.pipe(take(1)).subscribe((user: User | null) => {
      if (user) {
        this.apiService
          .getResources(
            'favourites',
            new HttpParams().appendAll({
              'page[limit]': 1000, // FIXME: arbitrary high page limit to improve performance, refactor by fetching data differently
            })
          )
          .pipe(
            take(1),
            map((data: ParsedEntryInterface[]) => {
              return data.map((item) => FavoriteFromApiAdapter.parse(item as FavoriteResponseInterface));
            })
          )
          .subscribe((data: LocalFavoriteDataInterface[]) => {
            data.forEach((favorite) => {
              this._favoritesByKey[favorite.id] = favorite;
            });

            // save in api if not already saved
            const unsavedFavorites = Object.values(this._favoritesByKey).filter((favorite) => !favorite.favoriteId);
            if (unsavedFavorites.length) {
              this.saveFavoritesBatchInApi(unsavedFavorites);
            }

            this.setLocalFavorites(this._favoritesByKey);
            this._favoritesInit$.next(Object.values(this._favoritesByKey));
          });
      }
    });

    // delete local stored favorites if user is logging out
    this.userService.logout$.subscribe(() => {
      delete localStorage.MBMediaFavorites;
      this._favoritesByKey = {};
      this._favoritesCleared$.next(true);
      this._favoritesInit$.next([]);
    });
  }

  private getLocalFavorites(): { [key: string]: LocalFavoriteDataInterface } {
    return JSON.parse(localStorage.MBMediaFavorites || '{}');
  }

  private setLocalFavorites(favorites: { [key: string]: LocalFavoriteDataInterface }) {
    return (localStorage.MBMediaFavorites = JSON.stringify(favorites));
  }

  public toggle(data: FavorableContent & TrackableContent) {
    if (data.favoriteData) {
      if (this.isFavorite(data.favoriteData.id)) {
        this.remove(data.favoriteData.id);
      } else {
        this.add(data);
      }
    }
  }

  public add(resource: FavorableContent & TrackableContent, showNotification = true) {
    if (!resource.favoriteData) {
      return;
    }

    const localFavorites = this.getLocalFavorites();
    const date = new Date().toISOString();
    const contentToSave: LocalFavoriteDataInterface = { addedAt: date, updatedAt: date, ...resource.favoriteData };
    localFavorites[resource.favoriteData.id] = contentToSave;
    this.setLocalFavorites(localFavorites);
    this._favoritesByKey[resource.favoriteData.id] = localFavorites[resource.favoriteData.id];
    this._favoriteAdded$.next(localFavorites[resource.favoriteData.id]);
    this.userService.user$.pipe(take(1)).subscribe((user: User | null) => {
      if (user) {
        this.saveFavoriteInApi(contentToSave);
        if (showNotification) {
          this.showFavoriteAddedToast();
        }
      } else {
        if (!localStorage.firstFavoriteChecked && Object.keys(localFavorites).length <= 1) {
          localStorage.firstFavoriteChecked = true;
          this.dialogService.open({ component: FirstFavoriteModalComponent });
        } else {
          if (showNotification) {
            this.showFavoriteAddedToast();
          }
        }
      }
    });

    if (resource.trackingData) {
      this.dataLayerService.pushEvent({
        event: 'bookmark',
        item: resource.trackingData,
      });
    }
  }

  public remove(id: string, showNotification = true, preventDeleteCheck = false) {
    if (preventDeleteCheck || (localStorage && localStorage.rememberDeleteFavorites)) {
      this.deleteFavorite(id, showNotification);
    } else {
      const ref = this.dialogService.open({
        component: BaseConfirmationComponent,
        inputs: {
          headline: 'REMOVE_CONFIRM_CONTENT',
          confirmLabel: 'DELETE',
        },
        outputs: {
          confirm: (e: { remember: boolean }) => {
            if (e.remember) {
              localStorage.rememberDeleteFavorites = 'true';
            }
            this.deleteFavorite(id, showNotification);
            ref.close();
          },
          cancel: () => {
            ref.close();
          },
        },
      });
    }
  }

  public update(content: FavoriteDataInterface) {
    const localFavorites = this.getLocalFavorites();
    localFavorites[content.id] = { ...localFavorites[content.id], updatedAt: new Date().toISOString(), ...content };
    this.userService.user$.subscribe((user: User | null) => {
      if (user) {
        this.updateFavoriteInApi(localFavorites[content.id]);
      } else {
        this.setLocalFavorites(localFavorites);
      }
      this._favoritesByKey[content.id] = localFavorites[content.id];
      this._favoriteUpdated$.next(localFavorites[content.id]);
    });
  }

  public isFavorite(id: string): boolean {
    return !!this._favoritesByKey[id];
  }

  public openFavorites() {
    this.scrollLockService.lock();
    this._favoritesOpen$.next(true);
    this.toastService.updatePositions();
  }

  public closeFavorites() {
    this.scrollLockService.unlock();
    this._favoritesOpen$.next(false);
    this.toastService.updatePositions();
  }

  private deleteFavorite(id: string, showNotification = true) {
    const localFavorites = this.getLocalFavorites();
    if (localFavorites[id]) {
      const favoriteId: string = localFavorites[id].favoriteId as string;
      delete localFavorites[id];
      this.setLocalFavorites(localFavorites);
      // delete from api if user is logged in
      this.userService.user$.subscribe((user: User | null) => {
        if (user) {
          this.removeFavoriteFromApi(favoriteId);
        }
      });
    }
    if (showNotification) {
      this.showFavoriteRemovedToast();
    }
    delete this._favoritesByKey[id];
    this._favoriteRemoved$.next(id);
  }

  private saveFavoriteInApi(content: LocalFavoriteDataInterface) {
    const data = FavoriteToApiAdapter.parse(content);
    this.apiService
      .post('favourites', { data })
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res && res.data) {
          const localFavorites = this.getLocalFavorites();
          (localFavorites[content.id] || content).favoriteId = res.data.id;
          this.setLocalFavorites(localFavorites);
        }
      });
  }

  /**
   * Save multiple favorites in the backend
   *
   * Note: This method must be used, when saving more than one favorite at once, else the Drupal could return a 500 error
   * @param content
   */
  private saveFavoritesBatchInApi(content: LocalFavoriteDataInterface[]) {
    const data = content.map((favorite) => ({
      data: FavoriteToApiAdapter.parse(favorite),
    }));
    this.apiService
      .post('favourites/multi', data)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res && res.data) {
          const localFavorites = this.getLocalFavorites();
          res.data.forEach((favorite: { type: string; id: string; attributes: { download_url?: string } }) => {
            const localFavoriteById = localFavorites[favorite.id] || content.find((item) => item.id === favorite.id);
            if (localFavoriteById) {
              localFavoriteById.favoriteId = favorite.id;
            } else {
              // case for caputures
              const localFavourite =
                Object.values(localFavorites).find(
                  (item) => item.data?.downloadUrl === favorite.attributes.download_url
                ) || content.find((item) => item.data?.downloadUrl === favorite.attributes.download_url);
              if (localFavourite) {
                localFavourite.favoriteId = favorite.id;
              }
            }
          });
          this.setLocalFavorites(localFavorites);
        }
      });
  }

  private updateFavoriteInApi(content: LocalFavoriteDataInterface) {
    const data: any = FavoriteToApiAdapter.parse(content);
    data.id = content.id;
    this.apiService.patch(`favourites/${data.id}`, { data }).pipe(take(1)).subscribe();
  }

  private removeFavoriteFromApi(id: string) {
    if (!id) {
      return;
    }
    this.apiService
      .delete('favourites', id)
      .pipe(take(1))
      .subscribe(() => {});
  }

  private showFavoriteRemovedToast(): void {
    this.toastService.open({
      text: 'FAVORITE_REMOVED',
    });
  }

  private showFavoriteAddedToast(): void {
    this.toastService.open({
      text: 'FAVORITE_ADD',
      trigger: () => this.openFavorites(),
    });
  }
}
