import { Screen } from '@modules/shared/services/window.service';

import { MediaSetting } from '../media-setting.interface';

export const ArticleHeaderImageStyle: MediaSetting[] = [
  { screen: Screen.XXXS, key: '3_2_568', width: 568, height: 378, ratio: 1.5 },
  { screen: Screen.XS, key: '3_2_768', width: 768, height: 512, ratio: 1.5 },
  { screen: Screen.SM, key: '3_2_1024', width: 1024, height: 385, ratio: 1.5 },
  { screen: Screen.MD, key: '8_3_1366', width: 1366, height: 514, ratio: 2.66 },
  { screen: Screen.LG, key: '8_3_1600', width: 1600, height: 602, ratio: 2.66 },
  { screen: Screen.XL, key: '8_3_1920', width: 1800, height: 675, ratio: 2.66 },
  { screen: Screen.XXL, key: '8_3_1800', width: 1800, height: 675, ratio: 2.66 },
];
