import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum AudioPlayState {
  PLAY = 'play',
  PAUSE = 'pause',
  ENDED = 'ended',
}

@Injectable()
export class AudioService {
  private audioPlayers: any[] = []; // native audio elements
  public audioPlayState$ = new Subject<AudioPlayState>();
  constructor() {}

  public addPlayer(player: any) {
    this.audioPlayers.push(player);
  }
  public removeAudioPlayers() {
    this.audioPlayers = [];
  }
  public stopAllPlayer() {
    this.audioPlayers.forEach((player: any) => {
      // make condition here
      if (!player.paused) {
        player.pause();
      }
    });
  }
}
