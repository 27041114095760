import { Injectable } from '@angular/core';

import { ShareModalComponent } from '../modal/share-modal/share-modal.component';

import { TrackableContent } from './data-layer.service';
import { ModalService } from './modal.service';

export interface SharingData {
  url: string;
  title: string;
  content: string; // abbreviated content (cut down to 140 chars)
  fullContent?: string; // full content (not abbreviated, used in emails)
  externalLink?: string;
}

export interface SharableContent {
  shareData?: SharingData;
}

@Injectable()
export class ShareModalService {
  constructor(private modalService: ModalService) {}

  public openModal(data: SharableContent & TrackableContent) {
    if (!data.shareData) {
      // IMPROVE: show a nice error message instead
      return;
    }

    this.modalService.open(ShareModalComponent, {
      sharingData: data.shareData,
      trackingData: data.trackingData,
    });
  }
}
