<mb-base-modal [headerTpl]="headerTpl">
  <div class="content" dialog-content [class.no-text]="!text">
    <div class="buffering-container">
      <mb-buffering class="dark"></mb-buffering>
    </div>
    <p class="buffering-text" *ngIf="text">{{ text | translate }}</p>
    <span class="abort-btn btn btn-text" mat-dialog-close (click)="cancel.emit()">
      {{ 'CANCEL' | translate }}
    </span>
  </div>
</mb-base-modal>

<!-- empty header template  -->
<ng-template #headerTpl></ng-template>
