import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { DataLayerService } from '@modules/shared/services/data-layer.service';
import { ANALYTIC_EVENTS, TrackingService, TRACKING_IDENTIFIER } from '@modules/shared/services/tracking.service';
import { Subject } from 'rxjs';

import { FullscreenService } from '../fullscreen.service';
import { MediaFlyoutComponent } from '../media-flyout/media-flyout.component';
import { MediaVideoModel } from '../models/media-video.model';
import { PlayerModel, PlayerModelOptionsInterface } from '../models/player.model';

@Injectable()
export class PlayerService {
  private componentRef!: ComponentRef<MediaFlyoutComponent>;
  private _playerClosed$ = new Subject<boolean>();

  public activePlayer!: PlayerModel;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private fullscreenService: FullscreenService,
    private trackingService: TrackingService,
    private appRef: ApplicationRef,
    private injector: Injector,
    private dataLayerService: DataLayerService
  ) {}

  public createPlayer(playerData: MediaVideoModel, options: PlayerModelOptionsInterface = {}): PlayerModel {
    this.trackingService.push(location.href, {
      events: ANALYTIC_EVENTS.VIDEO_PLAYED,
      [TRACKING_IDENTIFIER.VIDEO_STATUS]: playerData.status,
      [TRACKING_IDENTIFIER.RESOURCE_ID]: playerData.id,
    });
    return new PlayerModel(playerData, this.fullscreenService, this.dataLayerService, options);
  }

  public createFlyout(player: PlayerModel) {
    this.activePlayer = player;
    // Create a component reference from the component
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(MediaFlyoutComponent)
      .create(this.injector);
    this.componentRef.instance.player = player;
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.componentRef.hostView);

    // Get DOM element from component
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    player.flyoutRef = this.componentRef;
    document.body.appendChild(domElem);
  }

  public destroyFlyout(continuePlaying = false) {
    if (!continuePlaying && this.activePlayer) {
      this.activePlayer.pause();
      this._playerClosed$.next(true);
    }
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
    }
  }

  get playerClosed$() {
    return this._playerClosed$.asObservable();
  }
}
