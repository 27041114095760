import { FavorableContent } from '@modules/favorites/interfaces/favorable-content.interface';
import { FavoriteDataInterface } from '@modules/favorites/interfaces/favorite-data.interface';
import { LightboxMetadataInterface } from '@modules/shared/interfaces/lightbox.interface';
import { DownloadableContent } from '@modules/shared/services/download.service';
import { SharableContent } from '@modules/shared/services/share.service';

import { MediaDefaultModel } from './media-default.model';
import { MediaType } from './media.types';

export interface MediaAudioInterface extends SharableContent, DownloadableContent, FavorableContent {
  id: string;
  marsShelfNumber: string | null;
  marsOriginId: string | null;
  title: string;
  mediaType: string;
  caption: string;
  description: string;
  displayDate?: Date;
  formats: MediaAudioFormatInterface[];
  favoriteData?: FavoriteDataInterface;
  fuelLabel?: string;
}

export interface MediaAudioFormatInterface {
  id: string;
  url: string;
  name: string;
  mimeType: string;
  extension: string;
  filesize: number;
  duration: number;
}

export class MediaAudioModel extends MediaDefaultModel {
  public type = MediaType.AUDIO;
  public mediaType = MediaType.AUDIO;
  public original: MediaAudioFormatModel;
  public formats: MediaAudioFormatModel[] = [];
  public sources: { type: string; src: string }[];
  public duration: number;

  constructor(data: MediaAudioInterface) {
    super(data);
    this.formats = data.formats.map((f: any) => new MediaAudioFormatModel(f));
    this.original = (this.formats.find((item) => item.id === 'original') as MediaAudioFormatModel) || this.formats[0];
    this.sources = this.formats.map((format) => {
      return {
        type: format.mimeType,
        src: format.url,
      };
    });
    this.duration = this.original?.duration;
    this.filesize = this.original?.filesize || 0;
  }

  public getLightboxMetadata(): LightboxMetadataInterface {
    return {
      ...super.getLightboxMetadata(),
      duration: this.duration || undefined,
    };
  }
}

export class MediaAudioFormatModel {
  public id: string;
  public type = MediaType.AUDIO;
  public url: string;
  public name: string;
  public mimeType: string;
  public extension: string;
  public filesize: number;
  public duration: number;

  public minutes: number;
  public seconds: number;

  constructor(data: MediaAudioFormatInterface) {
    this.id = data.id;
    this.mimeType = data.mimeType;
    this.url = data.url;
    this.name = data.name;
    this.extension = data.extension;
    this.filesize = data.filesize;

    this.duration = data.duration;
    this.minutes = Math.floor(this.duration / 60);
    this.seconds = this.duration % 60;
  }
}
