<div class="mb-audioplayer" (click)="playerAreaClicked()">
  <div class="mb-audioplayer-top">
    <audio #audioInstance preload="metadata" [muted]="isMuted">
      <source *ngFor="let item of media.formats" src="{{ item.url }}" type="{{ item.mimeType }}" />
      {{ 'AUDIO_ELEMENT_NOT_SUPPORTED' | translate }}
    </audio>
    <button
      (click)="togglePlayPause($event)"
      attr.aria-label="{{ (isPlaying ? 'PAUSE' : 'PLAY') | translate }}"
      class="btn btn-round audio-player-button"
    >
      <span *ngIf="isPlaying" class="mb-icon mb-icon-player-pause"></span>
      <span *ngIf="!isPlaying" class="mb-icon mb-icon-player-play"></span>
    </button>

    <div class="audioplayer-controls">
      <div class="audioplayer-time">
        {{ position * 1000 | date: timeFormat }}/{{ duration * 1000 | date: timeFormat }}
      </div>

      <div class="player-scrubber" (click)="stopProp($event)">
        <mb-scrubber
          [value]="(progress$ | async) || 0"
          [max]="duration"
          (progressChanged)="onScrubberChange($event)"
        ></mb-scrubber>
      </div>

      <div class="audioplayer-volume" (click)="stopProp($event)">
        <button
          class="btn btn-plain"
          attr.aria-label="{{ (isMuted ? 'UNMUTE' : 'MUTE') | translate }}"
          (click)="toggleMute()"
        >
          <span class="mb-icon {{ isMuted ? 'mb-icon-sound-mute' : 'mb-icon-sound-high' }}"></span>
        </button>
      </div>
    </div>
  </div>

  <div class="audioplayer-bottom">
    <h2>{{ media.title }}</h2>
    <div class="audioplayer-meta">
      <div class="mb-horizontal-divided-list" *ngIf="media.original.extension || media.filesize">
        <ul class="meta-items">
          <li *ngIf="media.original.extension" class="meta-item">{{ media.original.extension | uppercase }}</li>
          <li *ngIf="media.filesize" class="meta-item">{{ media.filesize | fileSize }}</li>
        </ul>
      </div>

      <mb-action-container
        *ngIf="media && actions"
        [resource]="media"
        [buttonItems]="actions"
        (actionClick)="actionClick.emit($event)"
      ></mb-action-container>
    </div>
  </div>
</div>
