import { Injectable } from '@angular/core';
import { FavoriteDataContextInterface } from '@modules/favorites/interfaces/favorite-data-context.interface';
import { MediaImageInterface } from '@modules/media/models/media-image.model';
import { MediaStreamInterface } from '@modules/media/models/media-stream.model';
import { VideoStatus, VideoType } from '@modules/media/models/media-video.model';
import { environment } from 'src/environments/environment';

import { MediaSetting } from '../domain/media-setting.interface';

import { Adapter } from './adapter';
import { ContactPersonResponse } from './contact.adapter';
import {
  ImageMetaResponse,
  MediaAdapter,
  MediaImageFormatResponse,
  MediaImageResponse,
  MediaVideoResponse,
} from './media.adapter';
import { QAFormSchema } from './qaform.adapter';
import { TranscriptAdapter, TranscriptResponseInterface } from './transcript.adapter';

export interface PlayerConfigResponse {
  id: string;
  type: string;
  title: string;
  cameras: string[];
  error_text: string;
  external_player?: string;
  embed_url?: { uri: string };
  mp4_url?: { uri: string };
  playlist_url?: { uri: string };
  social_video_url?: { uri: string };
  fuel_label_text: { processed?: string };
  media?: MediaVideoResponse; // TBD;
  screenshot_img_backup_url?: { uri: string };
  screenshot_img_url?: { uri: string };
  screenshot_offset?: number;
  show_error_screen: boolean;
  show_qa_button: boolean;
  show_screenshot_button: boolean;
  show_scrubber_bar: boolean;
  video_width: number;
  video_height: number;
  video_status: VideoStatus;
  video_type: VideoType;
  transcript: TranscriptResponseInterface;
  qa_form?: {
    id: string;
    author: string;
    event_date: string;
    submission_mode: string;
    title: string;
    selectable_contact_people: ContactPersonResponse[];
  };
}
export interface StreamCardResponse {
  id: string;
  type: string;
  title: string;
  title_hash: string;
  background?: MediaImageFormatResponse;
  subtitle_vod: string;
  subtitle_stopped: string;
  subtitle_pre_live: string;
  subtitle_live: string;
  start_date: string;
  end_date: string;
  show_loading_animation: boolean;
  show_embed_code: boolean;
  show_calendar_download: boolean;
  player_config: PlayerConfigResponse;
  play_button_text_live: string;
  play_button_text_vod: string;
  live_status_indicator_text: string;
  ics_link: string;
  fuel_label_text: { processed?: string };
  embed_card: any; // TBD
  embed_button_text: any; // TBD
  countdown_label: string;
  countdown_date: string | null;
  relationships: {
    background?: {
      id: string;
      type: string;
      meta: {
        alt: string;
        title: string;
        widht: number;
        height: number;
      };
    };
  };
}
@Injectable({
  providedIn: 'root',
})
export class StreamAdapter implements Adapter<MediaStreamInterface> {
  constructor(
    private mediaAdapter: MediaAdapter,
    private transcriptAdapter: TranscriptAdapter
  ) {}

  public parse(
    data: StreamCardResponse,
    mediaSetting: MediaSetting[] = [],
    context?: FavoriteDataContextInterface
  ): MediaStreamInterface {
    return this.parseStreamCard(data, mediaSetting, context);
  }

  /**
   * TODO: Implement page context reference
   * should transcript entries also link to the page context?
   */
  private parseStreamCard(
    data: StreamCardResponse,
    mediaSetting: MediaSetting[] = [],
    _context?: FavoriteDataContextInterface
  ): MediaStreamInterface {
    const playerConfig = data.player_config;
    let background: MediaImageResponse | null = null;
    if (data.background) {
      const _background = data.background;
      const title = (data.relationships.background?.meta?.title as string) || '';
      background = {
        id: _background.id,
        title: title,
        caption: title,
        description: title,
        alternate_images: [],
        image: data.background,
        name: title,
        type: 'files',
        relationships: {
          image: {
            id: _background.id,
            meta: data.relationships.background?.meta as unknown as ImageMetaResponse,
          },
        },
      };
    }
    return {
      id: data.id,
      type: data.type,
      title: data.title,
      titleHash: data.title_hash,
      background: background ? (this.mediaAdapter.parse(background, mediaSetting) as MediaImageInterface) : null,
      countdownDate: data.countdown_date,
      countdownLabel: data.countdown_label,
      startDate: data.start_date,
      endDate: data.end_date,
      fuelLabel: data.fuel_label_text?.processed || '',
      icsLink: `${environment.apiBaseUrl}${data.ics_link}`,
      liveStatusIndicatorText: data.live_status_indicator_text,
      playButtonTextLive: data.play_button_text_live,
      playButtonTextVod: data.play_button_text_vod,
      playerConfig: playerConfig ? this.parsePlayerConfig(playerConfig) : undefined,
      showEmbedCode: data.show_embed_code,
      embedText: data.embed_button_text,
      showIcsDownload: data.show_calendar_download,
      showLoadingAnimation: data.show_loading_animation,
      subtitleLive: data.subtitle_live,
      subtitlePreLive: data.subtitle_pre_live,
      subtitleStopped: data.subtitle_stopped,
      subtitleVod: data.subtitle_vod,
    };
  }

  public parsePlayerConfig(playerConfig: PlayerConfigResponse) {
    if (!playerConfig) {
      return;
    }
    const parsedQaForm = QAFormSchema.safeParse(playerConfig.qa_form);
    return {
      id: playerConfig.id,
      type: playerConfig.video_type,
      title: playerConfig.title,
      fuelLabel: playerConfig.fuel_label_text?.processed || '',
      errorText: playerConfig.error_text,
      playlistUrl: playerConfig.playlist_url?.uri || '',
      socialVideoUrl: playerConfig.social_video_url?.uri || '',
      mp4Url: playerConfig.mp4_url?.uri || '',
      embedUrl: playerConfig.embed_url?.uri || '',
      screenshotUrl: playerConfig.screenshot_img_url?.uri || playerConfig.screenshot_img_backup_url?.uri || '',
      externalPlayer: playerConfig.external_player,
      showErrorScreen: playerConfig.show_error_screen,
      showQaButton: playerConfig.show_qa_button,
      qaForm: parsedQaForm.success ? parsedQaForm.data : undefined,
      showScreenshotButton: playerConfig.show_screenshot_button,
      showScrubberBar: playerConfig.show_scrubber_bar,
      screenshotOffset: playerConfig.screenshot_offset,
      width: playerConfig.video_width,
      height: playerConfig.video_height,
      transcript: playerConfig.transcript ? this.transcriptAdapter.parse(playerConfig.transcript) : null,
      cameras: (playerConfig.cameras || []).map((camera: string) => {
        return { id: camera, title: camera };
      }),
      status: playerConfig.video_status,
    };
  }
}
