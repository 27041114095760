import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MyMBMediaModalComponent } from '@modules/shared/modal/my-mb-media-modal/my-mb-media-modal..component';
import { UserProfileModalComponent } from '@modules/shared/modal/user-profile-modal/user-profile-modal.component';
import { User } from '@modules/shared/models/user.model';
import { HeaderService } from '@modules/shared/services/header.service';
import { ModalService } from '@modules/shared/services/modal.service';
import { UserService } from '@modules/shared/services/user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { languageConfig } from 'src/environments/language.config';

@Component({
  selector: 'mb-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.3s cubic-bezier(0.25, 0.1, 0.25, 1)', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class HeaderBarComponent {
  public user$: Observable<User | null>;
  public isSearchInputFieldClosed = true;
  public currentLanguage: string;

  public languages = languageConfig.languages.map((isoLang) => ({
    isoLang,
    label: isoLang.split('-')[0].toUpperCase(),
  }));

  @Input() notificationCount: number = 0;

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private headerService: HeaderService,
    private modalService: ModalService,
    private router: Router
  ) {
    this.user$ = this.userService.user$;
    this.currentLanguage = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
    });
  }

  public login(): void {
    this.headerService.closeMenu();
    this.userService.login();
  }

  public logout(): void {
    this.userService.logout();
  }

  public changeLanguage(language: string): void {
    this.translateService.use(language);
  }

  public closeMenu() {
    this.headerService.closeMenu();
  }

  public openProfileModal(): void {
    this.headerService.closeMenu();
    this.modalService.open(UserProfileModalComponent);
  }

  public toggleSearchInputField(value: boolean): void {
    if (!value) {
      this.headerService.closeMenu();
    }
    this.isSearchInputFieldClosed = value;
  }

  public openMyContent(): void {
    this.headerService.closeMenu();
    this.modalService.open(MyMBMediaModalComponent);
  }

  public toggleNavigationMenu(): void {
    if (this.headerService.isMenuOpen) {
      this.headerService.closeMenu();
    } else {
      this.headerService.openMenu({ level: 1 });
    }
  }

  public openImprint(event: Event) {
    this.headerService.closeMenu();
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['/legal/provider'], {
      state: { scrollRestoration: 'top' },
    });
  }

  public openPressContacts(event: Event) {
    this.headerService.closeMenu();
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['presscontacts']);
  }
}
