<mb-header [notificationCount]="(notificationCount$ | async) || 0"></mb-header>

<div class="route-wrapper">
  <router-outlet></router-outlet>
</div>

<div *ngIf="(isLoading$ | async) || false" class="loader-overlay">
  <mb-buffering></mb-buffering>
</div>

<mb-footer></mb-footer>

<mb-favorites-modal *ngIf="favoritesOpen" (closed)="closeFavorites()"></mb-favorites-modal>
