<div class="favorite-card-wrapper">
  <div class="card-header">
    <span *ngIf="icon" class="fav-icon mb-icon {{ icon }}"></span>
    <mb-meta-items
      *ngIf="headerMeta?.length"
      [metaItems]="headerMeta"
      [showEmpty]="data.type === 'video' || data.type === 'audio'"
    ></mb-meta-items>
  </div>

  <div *ngIf="image || imageUrl" class="card-image-wrapper">
    <mb-media *ngIf="data.type === 'video'" class="absolute-positioned" [media]="data" [hoverplay]="true"></mb-media>
    <mb-image *ngIf="image" class="absolute-positioned" [media]="image"></mb-image>
    <img
      *ngIf="imageUrl && data.type !== 'video'"
      class="static-image"
      [src]="imageUrl"
      [alt]="image?.caption || image?.title || 'Bookmarked image'"
    />
    <span class="press-kit-flag copy-s" *ngIf="data.type === pressKitType">
      {{ 'PRESS_KIT' | translate }}
    </span>
  </div>

  <div class="card-content" [class.text-card-content]="!image && !imageUrl">
    <h2 class="card-title truncated-text h4" [innerHTML]="headline"></h2>
  </div>

  <div class="card-footer">
    <ul *ngIf="footerMeta && (footerMeta.type || footerMeta.size || footerMeta.time)" class="meta-items">
      <li *ngIf="footerMeta.type" class="meta-item">{{ footerMeta.type }}</li>
      <li *ngIf="footerMeta.size" class="meta-item">{{ footerMeta.size | fileSize }}</li>
      <li *ngIf="footerMeta.time" class="meta-item">{{ footerMeta.time }}</li>
    </ul>
    <mb-action-container [buttonItems]="actions" (actionClick)="actionClick($event)"></mb-action-container>
  </div>

  <div class="card-line-wrapper">
    <div class="card-line"></div>
  </div>
</div>
