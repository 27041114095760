import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

declare const document: any;
function getFullScreenAPIComponent(document: any, apiComponentArray: any) {
  let finalAPIComponent;
  apiComponentArray.forEach((apiComponent: any) => {
    if (document[apiComponent]) {
      finalAPIComponent = apiComponent;
    }
  });
  return finalAPIComponent;
}

const fullScreenVendorAPI = {
  request: ['requestFullScreen', 'mozRequestFullScreen', 'webkitRequestFullscreen', 'msRequestFullscreen'],
  cancel: ['cancelFullScreen', 'exitFullscreen', 'mozCancelFullScreen', 'webkitExitFullscreen', 'msExitFullscreen'],
};

const polyfill: any = {
  request: getFullScreenAPIComponent(document.documentElement, fullScreenVendorAPI.request),
  cancel: getFullScreenAPIComponent(document, fullScreenVendorAPI.cancel),
};
@Injectable()
export class FullscreenService {
  /**
   * Event emitter which emits an array with the original Event and the
   * HTMLElement which is currently shown fullscreen. If fullscreen is off
   * @return {Observable}
   */
  public change$: Observable<{
    event: Event;
    element: HTMLElement;
    isFullscreen: boolean;
  }>;

  constructor() {
    // setup event listener for fullscreen change
    let eventName: string = '';
    if ('onwebkitfullscreenchange' in document) {
      eventName = 'webkitfullscreenchange';
    } else if ('onfullscreenchange' in document) {
      eventName = 'fullscreenchange';
    } else if ('onmozfullscreenchange' in document) {
      eventName = 'mozfullscreenchange';
    } else if ('onmsfullscreenchange' in document) {
      eventName = 'msfullscreenchange';
    }
    this.change$ = fromEvent(document, eventName).pipe(
      map((event: any) => {
        const element =
          document['fullscreenElement'] ||
          document['mozFullScreenElement'] ||
          document['webkitFullscreenElement'] ||
          document['msFullscreenElementElement'];

        const isFullscreen = !!element;
        return { event, element, isFullscreen };
      })
    );
  }

  /**
   * Request fullscreen mode for the given DOM element
   *
   * @param {[type]} nativeElement [description]
   */
  public request(nativeElement: any) {
    nativeElement[polyfill.request]();
  }

  /**
   * Exits fullscreen mode
   */
  public cancel() {
    document[polyfill.cancel]();
  }

  /**
   * Use to check if fullscreen api is supported
   */
  public isSupported() {
    return polyfill.request;
  }
}
