<ng-container *ngIf="currentTime || currentTime === 0">
  <ng-container *ngTemplateOutlet="timeTpl; context: { hrs: ctf.hrs, min: ctf.min, sec: ctf.sec }"></ng-container>
</ng-container>
<ng-container *ngIf="(currentTime || currentTime === 0) && duration"> / </ng-container>
<ng-container *ngIf="duration">
  <ng-container *ngTemplateOutlet="timeTpl; context: { hrs: dtf.hrs, min: dtf.min, sec: dtf.sec }"></ng-container>
</ng-container>

<ng-template #timeTpl let-hrs="hrs" let-min="min" let-sec="sec">
  <ng-container *ngIf="hrs !== '0'">{{ hrs }} : </ng-container>
  {{ min }} : {{ sec }}
</ng-template>
