import { Component, ChangeDetectionStrategy, Input, HostBinding, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mb-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
  @HostBinding('class.open')
  @Input()
  isOpen = false;

  @Input() hideIcon = false;
  @Input() disable = false;

  @Output() toggled = new EventEmitter<boolean>();

  public toggle() {
    if (this.disable) {
      return;
    }
    this.isOpen = !this.isOpen;
    this.toggled.emit(this.isOpen);
  }
}
