import { ComponentType } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Renderer2,
  RendererFactory2,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';

import { ModalComponent } from '../modal/modal/modal.component';

import { ScrollLockService } from './scroll-lock.service';

export type ModalState = 'open' | 'closed';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _modalState$: WritableSignal<ModalState> = signal('closed');
  private renderer: Renderer2;

  public modalState: Signal<ModalState> = this._modalState$.asReadonly();
  public componentRef!: ComponentRef<ModalComponent>;

  constructor(
    private appRef: ApplicationRef,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private scrollLockService: ScrollLockService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  // IMPROVE: handle multiple modals
  public open(modal: ComponentType<ModalComponent>, componentData?: { [k: string]: any }) {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    this.createAndRenderComponent(modal, componentData);
    this._modalState$.set('open');

    // we are using our own lock service here
    // because the workbench locks top on the html element
    // which triggers the header scroll animation
    this.scrollLockService.lock();
  }

  public close() {
    this.componentRef?.destroy();
    this._modalState$.set('closed');
    this.scrollLockService.unlock();
  }

  private createAndRenderComponent(component: ComponentType<any>, componentData?: { [k: string]: any }) {
    this.componentRef = createComponent(component, {
      environmentInjector: this.appRef.injector,
    });
    if (componentData) {
      Object.entries(componentData).forEach(([key, value]) => {
        this.componentRef.setInput(key, value);
      });
    }
    this.appRef.attachView(this.componentRef.hostView);
    this.renderer.appendChild(
      this.document.body,
      (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement
    );
  }
}
