import { Screen } from '@modules/shared/services/window.service';

import { MediaSetting } from '../media-setting.interface';

// These are used in the image gallery and actually grow up 1200px in width,
// since the gallery builds the masonary layout based on the actually images dynamically.
// Quick fix for sharp images is to request them large enough which will be too large in most cases.
// FIXME: improve image gallery component such that it requests images closer to the sizes, masonary calcucates
export const ArticleImageGalleryImageStyle: MediaSetting[] = [
  { screen: Screen.XXXS, key: '320', width: 320, height: 160, ratio: 2 },
  { screen: Screen.XXS, key: '400', width: 400, height: 200, ratio: 2 },
  { screen: Screen.XS, key: '500', width: 500, height: 250, ratio: 2 },
  { screen: Screen.SM, key: '600', width: 600, height: 300, ratio: 2 },
  { screen: Screen.MD, key: '960', width: 960, height: 480, ratio: 2 },
  { screen: Screen.LG, key: '1024', width: 1024, height: 512, ratio: 2 },
];
