import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@modules/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

import { AccordionComponent } from './accordion/accordion.component';
import { ActionContainerComponent } from './action-container/action-container.component';
import { BufferingComponent } from './buffering/buffering.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DynamicContainerComponent } from './dynamic-container/dynamic-container.component';
import { InfoComponent } from './info/info.component';
import { LiveIndicatorComponent } from './live-indicator/live-indicator.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MetaItemsComponent } from './meta-items/meta-items.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ResizableContainerComponent } from './resize-container/resizable-container.component';
import { ScrubberComponent } from './scrubber/scrubber.component';
import { SelectComponent } from './select/select.component';
import { SmallSpinnerComponent } from './small-spinner/small-spinner.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, PipesModule],
  declarations: [
    ActionContainerComponent,
    LiveIndicatorComponent,
    SmallSpinnerComponent,
    InfoComponent,
    DropdownComponent,
    SelectComponent,
    ScrubberComponent,
    DynamicContainerComponent,
    ResizableContainerComponent,
    MetaItemsComponent,
    AccordionComponent,
    LoadingSpinnerComponent,
    PaginationComponent,
    CheckboxComponent,
    BufferingComponent,
  ],
  exports: [
    ActionContainerComponent,
    LiveIndicatorComponent,
    SmallSpinnerComponent,
    InfoComponent,
    DropdownComponent,
    SelectComponent,
    ScrubberComponent,
    DynamicContainerComponent,
    ResizableContainerComponent,
    MetaItemsComponent,
    AccordionComponent,
    LoadingSpinnerComponent,
    PaginationComponent,
    CheckboxComponent,
    BufferingComponent,
  ],
})
export class UiModule {}
