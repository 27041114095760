import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe which takes a data and returns the number of days left until the
 * given date is reached. When the given date is in the past a negative value
 * is returned.
 */
@Pipe({
  name: 'daysLeft',
})
export class DaysLeftPipe implements PipeTransform {
  transform(then: Date, now: Date = new Date()): number {
    if (!then || !then.getTime) {
      return 0;
    }
    const msDifference = then.getTime() - now.getTime();
    return Math.floor(msDifference / 1000 / 24 / 3600);
  }
}
