import { NgModule } from '@angular/core';

import { DaysLeftPipe } from './days-left.pipe';
import { DurationFormatPipe } from './duration-format.pipe';
import { FileSizePipe } from './file-size.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { TimePeriodPipe } from './time-period.pipe';

const PIPES = [LocalizedDatePipe, TimePeriodPipe, DaysLeftPipe, DurationFormatPipe, FileSizePipe];
@NgModule({
  declarations: PIPES,
  exports: PIPES,
  providers: PIPES,
})
export class PipesModule {}
