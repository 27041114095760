import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { DataLayerService } from '@modules/shared/services/data-layer.service';
import { ANALYTIC_EVENTS, TrackingService, TRACKING_IDENTIFIER } from '@modules/shared/services/tracking.service';
import { ButtonItem } from '@modules/ui/action-container/action-container.component';
import { BehaviorSubject } from 'rxjs';

import { MediaAudioModel } from '../models/media-audio.model';
import { AudioPlayState, AudioService } from '../service/audio.service';

@Component({
  selector: 'mb-audio',
  templateUrl: 'audio.component.html',
  styleUrls: ['./audio.component.scss'],
})
export class AudioComponent implements OnInit, OnDestroy {
  public get isPlaying(): boolean {
    return this.audioObject ? !this.audioObject.paused : false;
  }

  private _progress = 0;
  public progress$ = new BehaviorSubject(0);

  @Input() media!: MediaAudioModel;
  @Input() actions: ButtonItem[] = [];

  // disable audio play when true
  @Input() disablePlay = false;

  // should output if the player is disabled and user want to play the audio
  @Output() disablePlayClick = new EventEmitter<void>();

  @Output() inactiveAreaClick = new EventEmitter<any>();
  @Output() actionClick = new EventEmitter<any>();
  // format which is used by the "date" pipe in the template to format
  // duration and position, see https://angular.io/api/common/DatePipe
  public timeFormat = 'mm:ss';

  // displayed current playback position in seconds
  public position = 0;
  // displayed total duration of the audio file in seconds
  public duration = 0;

  public isMuted = false;
  @ViewChild('audioInstance', { static: true }) audioInstance: any;
  // @ViewChild(ScrubberBarComponent, { static: false })
  // scrubber: ScrubberBarComponent;

  public audioObject: any;

  private addedToService = false;

  private initialPlay = true;

  constructor(
    private audioService: AudioService,
    private trackingService: TrackingService,
    private dataLayerService: DataLayerService
  ) {}

  ngOnInit() {
    // set default media type if not set
    // this.data.type = this.data.type || 'audio/mpeg';
    // add event listeners to native element
    this.audioObject = this.audioInstance.nativeElement;
    this.audioObject.addEventListener(
      'loadedmetadata',
      () => {
        this.duration = this.audioObject.duration;
      },
      true
    );
    this.audioObject.addEventListener(
      'progress',
      () => {
        this.onLoadProgress();
      },
      true
    );
    this.audioObject.addEventListener(
      'durationchange',
      () => {
        this.duration = this.audioObject.duration;
      },
      true
    );
    this.audioObject.addEventListener(
      'timeupdate',
      () => {
        this.updatePosition();
      },
      true
    );
    this.audioObject.addEventListener(
      'play',
      () => {
        this.audioService.audioPlayState$.next(AudioPlayState.PLAY);
      },
      true
    );
    this.audioObject.addEventListener(
      'pause',
      () => {
        this.audioService.audioPlayState$.next(AudioPlayState.PAUSE);
      },
      true
    );
    this.audioObject.addEventListener(
      'ended',
      () => {
        this.audioService.audioPlayState$.next(AudioPlayState.ENDED);
        this.trackingService.push(location.href, {
          events: ANALYTIC_EVENTS.AUDIO_FINISHED,
          [TRACKING_IDENTIFIER.RESOURCE_ID]: this.media.id,
          [TRACKING_IDENTIFIER.ASSET_IDENTIFIER]: this.media.marsShelfNumber ?? this.media.title,
        });
      },
      true
    );
  }

  stopProp(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  private updatePosition() {
    this.position = this.audioObject.currentTime;
    this._progress = Math.round(this.position);
    this.progress$.next(this._progress);
  }

  public togglePlayPause(e: any) {
    this.stopProp(e);
    if (this.disablePlay) {
      this.disablePlayClick.emit();
      return;
    }
    if (this.isPlaying) {
      this.pause();
    } else {
      this.play();
    }
  }

  play() {
    if (this.initialPlay) {
      this.initialPlay = false;
      this.trackingService.push(location.href, {
        events: ANALYTIC_EVENTS.AUDIO_PLAYED,
        [TRACKING_IDENTIFIER.RESOURCE_ID]: this.media.id,
        [TRACKING_IDENTIFIER.ASSET_IDENTIFIER]: this.media.marsShelfNumber ?? this.media.title,
      });
      this.trackEvent('audioPlay');
    }
    this.audioService.stopAllPlayer();
    this.audioObject.play();
    if (!this.addedToService) {
      this.addedToService = true;
      this.audioService.addPlayer(this.audioObject);
    }
  }

  pause() {
    this.audioObject.pause();
    this.trackEvent('audioPause');
  }

  public toggleMute() {
    this.isMuted = !this.isMuted;
  }

  private onLoadProgress() {
    this.position = this.audioObject.currentTime;
    this._progress = Math.round(this.position);
    this.progress$.next(this._progress);
  }

  public onScrubberChange(time: number) {
    this._progress = Math.round(time);
    this.audioObject.currentTime = this._progress;
    this.position = this.audioObject.currentTime;
    this.progress$.next(this._progress);
  }

  public playerAreaClicked() {
    this.inactiveAreaClick.emit(this.media);
  }

  public ngOnDestroy(): void {
    this.trackEvent('audioStop');
  }

  private trackEvent(event: 'audioPlay' | 'audioPause' | 'audioStop') {
    this.dataLayerService.pushEvent({
      event,
      audio: {
        id: this.media.id,
        title: this.media.title,
        marsOriginId: this.media.marsOriginId ?? undefined,
        shelfNumber: this.media.marsShelfNumber ?? undefined,
      },
      currentTime: this.audioObject.currentTime,
    });
  }
}
