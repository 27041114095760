import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QAFormDataInterface } from '@modules/shared/services/contact.service';
import { Option } from '@modules/ui/interfaces/option.interface';

import { ContactForm } from './contact-form.interface';

export enum ContactFormState {
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
  DEFAULT = 'default',
}
@Component({
  selector: 'mb-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent implements OnInit {
  public state = ContactFormState.DEFAULT;
  public form = new FormGroup<ContactForm>({
    question: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    editorialTeam: new FormControl(''),
    contact: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    contactApprovalCheck: new FormControl(false),
    termsOfUseCheck: new FormControl(false, [Validators.requiredTrue]),
  });

  public readonly states = ContactFormState;

  @Input() contacts: Option[] = [];
  @Input() data?: QAFormDataInterface;

  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter<QAFormDataInterface>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.data) {
      this.form.patchValue(this.data);
    }
  }

  public setContact(contact: any) {
    this.form.get('contact')?.setValue(contact.value);
  }

  // will be called from outside
  public setState(state: ContactFormState) {
    this.state = state;
    this.cdr.detectChanges();
  }
}
