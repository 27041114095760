<mb-base-modal>
  <div class="content" dialog-content>
    <h5 *ngIf="headline">{{ headline | translate }}</h5>
    <p *ngIf="text">{{ text | translate }}</p>

    <mb-checkbox class="copy-xl" [checked]="remember" (checkChange)="remember = $event">
      <span>{{ 'REMEMBER' | translate }}</span>
    </mb-checkbox>
    <div class="actions">
      <button class="btn btn-tertiary" (click)="cancel.emit()">
        {{ cancelLabel || 'CANCEL' | translate }}
      </button>
      <button class="btn btn-tertiary" (click)="confirm.emit({ remember: remember })">
        {{ confirmLabel || 'CONFIRM' | translate }}
      </button>
    </div>
  </div>
</mb-base-modal>
