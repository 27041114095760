<mb-base-modal>
  <div class="content" dialog-content>
    <ng-container *ngIf="state === states.DEFAULT; else responseTpl">
      <div class="contact-form copy-m" [formGroup]="form">
        <h2 class="contact-headline">
          {{ 'CONTACT_FORM_HEADLINE' | translate }}
        </h2>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'CONTACT_FORM_HEADLINE' | translate }}</mat-label>
          <textarea matInput formControlName="question"></textarea>
          <mat-error *ngIf="form.controls.question.errors?.required">
            {{ 'CONTACT_FORM_FIELD_REQUIRED_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

        <div *ngIf="contacts?.length" class="contact-form-contact">
          <h3 class="contact-form-contacts-headline">{{ 'CONTACT_PERSON' | translate }}</h3>
          <p>{{ 'CONTACT_FORM_CONTACT_TEXT' | translate }}</p>

          <mb-dropdown
            [options]="contacts"
            (selectionChanged)="setContact($event)"
            [value]="'CHOOSE_CONTACT_PERSON' | translate"
            class="light"
          ></mb-dropdown>
        </div>

        <div class="contact-form-personal-info">
          <h3 class="contact-form-personal-info-headline">
            {{ 'YOUR_PERSONAL_INFO' | translate }}
          </h3>
          <p>{{ 'CONTACT_FORM_PERSONAL_INFO_TEXT' | translate }}</p>

          <div class="contact-form-personal-info-fields">
            <mat-form-field>
              <mat-label>{{ 'FIRST_NAME' | translate }}</mat-label>
              <input matInput formControlName="firstName" />
              <mat-error *ngIf="form.controls.firstName.errors?.required">
                {{ 'CONTACT_FORM_FIRST_NAME_REQUIRED_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'LAST_NAME' | translate }}</mat-label>
              <input matInput formControlName="lastName" />
              <mat-error *ngIf="form.controls.lastName.errors?.required">
                {{ 'CONTACT_FORM_LAST_NAME_REQUIRED_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'EDITORIAL_TEAM' | translate }}</mat-label>
              <input matInput formControlName="editorialTeam" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'EMAIL' | translate }}</mat-label>
              <input matInput formControlName="email" />
              <mat-error *ngIf="form.controls.email.errors?.required">
                {{ 'CONTACT_FORM_EMAIL_REQUIRED_ERROR' | translate }}
              </mat-error>
              <mat-error *ngIf="form.controls.email.errors?.email">
                {{ 'CONTACT_FORM_EMAIL_INVALID_ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="contact-form-approvals">
          <mb-checkbox [control]="form.get('contactApprovalCheck')">
            {{ 'CONTACT_FORM_JOURNALIST_OPT_IN' | translate }}
          </mb-checkbox>
          <mb-checkbox [control]="form.get('termsOfUseCheck')">
            <span>
              {{ 'CONTACT_FORM_PRIVACY_OPT_IN.0' | translate }}
              <a [routerLink]="'legal/privacy-statement'" target="_blank" class="btn btn-text inline">{{
                'CONTACT_FORM_PRIVACY_OPT_IN.1' | translate
              }}</a>
              {{ 'CONTACT_FORM_PRIVACY_OPT_IN.2' | translate }}
            </span>
          </mb-checkbox>
        </div>
        <div class="actions">
          <button class="btn btn-primary" (click)="confirm.emit(form.value)" [disabled]="!form.valid">
            {{ 'SEND' | translate }}
          </button>
          <button class="btn btn-text" (click)="cancel.emit()">
            {{ 'CANCEL' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</mb-base-modal>

<ng-template #responseTpl>
  <div *ngIf="state === states.LOADING" class="buffering-view">
    <mb-buffering></mb-buffering>
  </div>
  <div *ngIf="state === states.ERROR" class="error-view">
    <span class="mb-icon mb-icon-attention"></span>
    <h2 class="response-headline">{{ 'AN_ERROR_OCCURED' | translate }}</h2>
    <div class="response-subline copy-l">{{ 'RESTART_PROGRESS' | translate }}</div>

    <button class="btn" (click)="setState(states.DEFAULT)">
      {{ 'BACK' | translate }}
    </button>
  </div>
  <div *ngIf="state === states.SUCCESS" class="success-view">
    <span class="mb-icon mb-icon-marked-ok-thin"></span>
    <h2 class="response-headline">{{ 'YOUR_QUESTION_WAS_SEND' | translate }}</h2>
    <div class="response-subline copy-l">{{ 'WE_ANSWER_SOON_EMAIL' | translate }}</div>

    <button class="btn" mat-dialog-close>
      {{ 'CONTINUE' | translate }}
    </button>
  </div>
</ng-template>
