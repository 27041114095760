import { Screen } from '@modules/shared/services/window.service';

import { MediaSetting } from '../media-setting.interface';

export const PressKitSectionImageStyle: MediaSetting[] = [
  { screen: Screen.XXXS, key: '2_1_320', width: 320, height: 160, ratio: 2 },
  { screen: Screen.XXS, key: '2_1_568', width: 568, height: 284, ratio: 2 },
  { screen: Screen.XS, key: '2_1_768', width: 768, height: 384, ratio: 2 },
  { screen: Screen.SM, key: '2_1_1024', width: 1024, height: 512, ratio: 2 },
  { screen: Screen.MD, key: '2_1_1366', width: 1366, height: 683, ratio: 2 },
  { screen: Screen.LG, key: '3_2_1024', width: 1024, height: 683, ratio: 1.5 },
];
