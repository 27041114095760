import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '@modules/shared/services/user.service';
import { map, take } from 'rxjs/operators';

@Injectable()
export class PreMediaGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate() {
    return this.userService.user$.pipe(
      take(1),
      map((user) => {
        if (user) {
          if (user.hasPreMediaAccess) {
            // redirect to homepage after logout
            this.userService.redirectUrl = window.location.origin;
            return true;
          } else {
            this.router.navigate(['403'], { skipLocationChange: true });
            return false;
          }
        } else {
          this.router.navigate(['401'], { skipLocationChange: true });
          return false;
        }
      })
    );
  }
}
