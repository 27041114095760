import {
  Component,
  OnInit,
  Inject,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogDataInterface } from './../services/dialog.service';

@Component({
  selector: 'mb-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, OnDestroy {
  @ViewChild('dialogChild', { read: ViewContainerRef, static: true }) private dialogChild!: ViewContainerRef;

  title!: string;
  active!: number;
  component!: ComponentRef<any>;

  public disableCloseBtn = false;
  public style = {};
  public classList: string | string[] | Set<string> | { [klass: string]: any } = '';
  public backgroundClass: string | string[] | Set<string> | { [klass: string]: any } = '';
  public destroy$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogDataInterface,
    private mdDialogRef: MatDialogRef<DialogComponent>,
    private resolver: ComponentFactoryResolver
  ) {
    this.mdDialogRef.disableClose = true;
  }

  public close() {
    this.mdDialogRef.close(true);
  }

  ngOnInit() {
    this.title = this.data.title || '';
    this.style = this.data.style || {};
    this.classList = this.data.classList || '';
    this.backgroundClass = this.data.backgroundClass || '';
    this.disableCloseBtn = !!this.data.disableCloseBtn;
    const component = this.resolver.resolveComponentFactory(this.data.component);
    this.component = this.dialogChild.createComponent(component);
    for (const i in this.data.inputs) {
      if (this.data.inputs.hasOwnProperty(i)) {
        this.component.instance[i] = this.data.inputs[i];
      }
    }
    for (const i in this.data.outputs) {
      if (this.data.outputs.hasOwnProperty(i)) {
        this.component.instance[i].pipe(takeUntil(this.destroy$)).subscribe((event: any) => {
          if (this.data.outputs && this.data.outputs[i]) {
            this.data.outputs[i](event);
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.component.destroy();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
