import { ContactPersonModel } from '@models/contact-person.model';
import { FavoriteDataContextInterface } from '@modules/favorites/interfaces/favorite-data-context.interface';
import { Option } from '@modules/ui/interfaces/option.interface';
import { ReplaySubject, Observable } from 'rxjs';
import { LANGUAGE_MAP } from 'src/helpers/language-map';

import { TranscriptEntryModel, TRANSCRIPT_ENTRY_TYPE } from './transcript-entry.model';

export enum TranscriptStatus {
  NOT_STARTED = '0',
  STARTED = '1',
  FINISHED = '2',
}

export enum TranscriptNavigationMode {
  CHAPTER = 'CHAPTER',
  SPEAKER = 'SPEAKER',
}
export interface Speaker {
  id: string;
  type: string;
  firstname: string;
  lastname: string;
  position: string;
  image?: any;
}

export interface TranscriptInterface {
  id: string;
  type: 'transcripts';
  title: string;
  languages: string[];
  masterLang: string;
  documents: any[];
  navigationMode: TranscriptNavigationMode;
  offset: number;
  status: TranscriptStatus;
  entryTypes: TRANSCRIPT_ENTRY_TYPE[];
  disableActionAssets: boolean;
  disabledActionAssetsToastText?: string;
  speakers: Speaker[];
  transcriptEntries?: any[];
}

export class TranscriptModel {
  private FILTERS: string[] = [];
  private AVAILABLE_ENTRIES: TranscriptEntryModel[] = [];
  private ENTRIES: TranscriptEntryModel[] = [];
  private _time = 1;

  private ENTRIES$ = new ReplaySubject<TranscriptEntryModel[]>(1);

  public languageOptions: Option[] = [];
  public lastTimeChecked = 0;
  public lang = 'de';
  // video time
  set time(v: number) {
    this._time = v;
    if (this.status === TranscriptStatus.STARTED) {
      // these updates are only needed for live mode
      this.setEntries();
      this.setAvailableFilters();
    }
  }
  get time() {
    return this._time;
  }
  public initializedEntries = false;
  public currentEntry!: TranscriptEntryModel;
  public id: string;
  public type = 'transcripts';
  public title: string;
  public languages: string[];
  public offset: number;
  public status: TranscriptStatus = TranscriptStatus.NOT_STARTED;

  public navMode: TranscriptNavigationMode = TranscriptNavigationMode.SPEAKER;
  public speakers: ContactPersonModel[] = [];
  public transcriptSpeakers: ContactPersonModel[] = [];
  public chapters: string[] = [];
  public sections$ = new ReplaySubject<string[]>(1);
  public tags: string[] = [];
  public tagOptions: Option[] = [];
  public canBookmark = false;
  public disableActionAssets: boolean;
  public disabledActionAssetsToastText: string;

  public trackingData = undefined; // no event tracking for this resource

  constructor(d: TranscriptInterface, public context: FavoriteDataContextInterface) {
    this.id = d.id;
    this.title = d.title;
    this.languages = d.languages || [];
    this.offset = d.offset;
    this.status = d.status;
    this.tags = d.entryTypes || [];
    this.setTagOptions();
    this.navMode = d.navigationMode || TranscriptNavigationMode.SPEAKER;
    if (d.transcriptEntries && d.transcriptEntries.length) {
      this.addEntries(d.transcriptEntries.map((entry) => new TranscriptEntryModel(entry)));
    } else {
      this.ENTRIES = [];
      this.ENTRIES$.next(this.ENTRIES);
    }
    this.languageOptions = [];
    this.disableActionAssets = d.disableActionAssets;
    this.disabledActionAssetsToastText = d.disabledActionAssetsToastText || '';
    this.languages.forEach((locale) => {
      this.languageOptions.push({
        label: (LANGUAGE_MAP as { [key: string]: string })[locale] as string,
        value: locale,
      });
    });
    this.lang = d.masterLang || '';
    this.canBookmark = !!d.documents?.length;
    this.transcriptSpeakers = (d.speakers || []).map((speaker: Speaker) => new ContactPersonModel(speaker));
  }

  private setAvailableFilters() {
    const speakers_fullname: string[] = [];
    const speakers: ContactPersonModel[] = [];
    const chapters: string[] = [];
    this.AVAILABLE_ENTRIES.forEach((e) => {
      if (e.speaker && !speakers_fullname.includes(e.speaker?.fullname)) {
        speakers_fullname.push(e.speaker?.fullname);
        speakers.push(e.speaker);
      }
      if (e.chapter && !chapters.includes(e.chapter)) {
        chapters.push(e.chapter);
      }
    });
    this.speakers = speakers;
    this.chapters = chapters;
    this.sections$.next(this.navMode === TranscriptNavigationMode.CHAPTER ? this.chapters : speakers_fullname);
  }
  private setEntries() {
    this.AVAILABLE_ENTRIES = this.FILTERS.length
      ? this.ENTRIES.filter((entry) => this.FILTERS.includes(entry.entryType))
      : this.ENTRIES;
    this.AVAILABLE_ENTRIES.forEach((entry, index) => {
      if (!this.AVAILABLE_ENTRIES[index + 1]) {
        entry.isLast = true;
        return;
      }
      entry.isLast = this.AVAILABLE_ENTRIES[index + 1].speaker?.fullname !== entry.speaker?.fullname;
    });
    if (this.status === TranscriptStatus.STARTED) {
      this.AVAILABLE_ENTRIES = this.AVAILABLE_ENTRIES.filter((e) => e.time + this.offset <= this.time);
    }
    this.ENTRIES.forEach((entry) => {
      if (!this.tags.includes(entry.entryType)) {
        this.tags.push(entry.entryType);
      }
    });
    this.setTagOptions();
    this.ENTRIES$.next(this.AVAILABLE_ENTRIES);
  }

  get filters(): string[] {
    return this.FILTERS;
  }

  get currentLanguage(): string {
    return this.lang;
  }

  get entries(): Observable<TranscriptEntryModel[]> {
    return this.ENTRIES$.asObservable();
  }

  get currentSpeaker() {
    return this.currentEntry?.speaker;
  }

  public clearEntries() {
    this.ENTRIES = [];
    this.setEntries();
  }

  public addEntries(entries: TranscriptEntryModel[]) {
    // concat with existing entries and sort by time
    entries = entries.filter((item) => this.ENTRIES.findIndex((_item) => _item.id === item.id) === -1);
    this.ENTRIES = this.ENTRIES.concat(entries).sort((a, b) => (a.time < b.time ? -1 : 1));
    this.initializedEntries = true;
    this.setEntries();
    this.setAvailableFilters();
  }

  public getCurrentEntryBySection(section: string, t = 0) {
    t = t || this.time;
    let firstKnown: TranscriptEntryModel | null = null;
    const getValueToCheck = (entry: TranscriptEntryModel) => {
      return this.navMode === TranscriptNavigationMode.CHAPTER ? entry.chapter : entry.speaker?.fullname;
    };
    const getConditionToCheck = (entry: TranscriptEntryModel, time: number) => {
      return this.status === TranscriptStatus.STARTED ? entry.time + this.offset <= time : true;
    };
    this.AVAILABLE_ENTRIES.forEach((entry) => {
      if (firstKnown) {
        return;
      }
      if (getConditionToCheck(entry, t)) {
        if (getValueToCheck(entry) === section) {
          firstKnown = entry;
        }
      }
    });
    return firstKnown ? (firstKnown as TranscriptEntryModel) : this.AVAILABLE_ENTRIES[0];
  }

  public setFilters(v: string[]) {
    this.FILTERS = v;
    this.setEntries();
    this.setAvailableFilters();
  }

  private setTagOptions() {
    this.tags.forEach((tag) => {
      if (this.tagOptions.findIndex((option) => option.value === tag) === -1) {
        this.tagOptions.push({
          label: tag,
          value: tag,
        });
      }
    });
  }
}
