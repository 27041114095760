<div class="container">
  <div class="back-button-container">
    <span class="btn btn-plain" (click)="closed.emit()">
      <span class="btn-label hidden-sm">{{ 'CLOSE' | translate }}</span>
      <span class="mb-icon mb-icon-close"></span>
    </span>
  </div>
  <div class="headline-container">
    <h2 class="top-bar h1">
      {{ 'MY_CONTENT' | translate }} <span class="mb-badge">{{ favoritesCount$ | async }}</span>
    </h2>

    <mb-dropdown
      *ngIf="favoritesCount$ | async"
      [options]="filters"
      [hideLabel]="true"
      [class.active]="activeFilter$.value !== 'all'"
      [icon]="activeFilter$.value !== 'all' ? 'mb-icon-filter-flat' : 'mb-icon-filter'"
      (selectionChanged)="setFilter($event.value)"
    ></mb-dropdown>
  </div>
  <div *ngIf="favoritesCount$ | async" class="favorites-navigation-wrapper">
    <ul class="favorites-navigation copy-l">
      <li
        *ngFor="let filter of filters"
        class="favorites-navigation-item"
        [class.active]="activeFilter$.value === filter.value"
        (click)="setFilter(filter.value)"
      >
        {{ filter.label | translate }}
      </li>
    </ul>
  </div>
</div>

<div class="favorites-empty-container" *ngIf="(favoritesCount$ | async) === 0">
  <span class="mb-icon mb-icon-information-credits"></span>
  <div class="favorites-empty-container-content">
    <span class="copy-l">
      {{ 'FAVORITES_EMPTY_HINT_1' | translate }}
      <span class="mb-icon mb-icon-bookmark"></span>
      {{ 'FAVORITES_EMPTY_HINT_2' | translate }}
    </span>
  </div>
  <button *ngIf="(user$ | async) === null" class="btn" (click)="login()" [title]="'LOGIN' | translate">
    <span class="mb-icon mb-icon-login"></span>
    <span>{{ 'FAVORITE_ADD_FIRST_SIGN_UP' | translate }}</span>
  </button>
</div>

<ng-container *ngIf="favorites$ | async as favoritesSections">
  <div *ngFor="let section of favoritesSections; let index = index">
    <mb-accordion
      #accordion
      [hideIcon]="true"
      [isOpen]="openAccordions[section.date]"
      (toggled)="openAccordions[section.date] = $event"
    >
      <div accordion-header class="container">
        <div class="accordion-header-content">
          <div class="accrdion-date">
            <h4>{{ section.date | date }}</h4>
          </div>
          <div class="accordion-icon-container">
            <span class="mb-badge-small">{{ section.items.length }}</span>
            <span class="accordion-icon mb-icon mb-icon-down-thin"></span>
          </div>
        </div>
      </div>
      <div accordion-content class="container accordion-content-container">
        <div class="accordion-content-header">
          <span class="content-counter hidden-xs">
            {{ (section.items.length === 1 ? 'CONTENT_N' : 'CONTENTS_N') | translate: { count: section.items.length } }}
          </span>

          <mb-action-container
            [buttonItems]="rowActions"
            (actionClick)="rowAction($event, section)"
          ></mb-action-container>
        </div>
        <div *ngIf="accordion.isOpen" class="row">
          <div *ngFor="let item of section.items" class="card-item col-12 col-sm-6 col-lg-4 col-xl-3">
            <div *ngIf="(item.data$ | async) === null" class="card-placeholder">
              <mb-buffering></mb-buffering>
            </div>
            <ng-container *ngIf="item.data$ | async as favorite">
              <mb-favorite-card
                [class.has-media]="favorite.image"
                [icon]="favorite.icon"
                [headline]="favorite.headline"
                [actions]="favorite.actions"
                [data]="favorite.data"
                [image]="favorite.image"
                [imageUrl]="favorite.imageUrl"
                [headerMeta]="favorite.headerMeta"
                [footerMeta]="favorite.footerMeta"
                (click)="cardTrigger(favorite.data)"
              ></mb-favorite-card>
            </ng-container>
          </div>
        </div>
      </div>
    </mb-accordion>
  </div>
</ng-container>
