import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FavoritesContentService } from '@modules/favorites/services/favorites-content.service';
import { FavoritesService } from '@modules/favorites/services/favorites.service';
import { ActionResourceInterface } from '@modules/shared/action-button-group/action.types';
import { DownloadService } from '@modules/shared/services/download.service';
import { ShareModalService } from '@modules/shared/services/share.service';
import { TrackingService, TRACKING_TYPES } from '@modules/shared/services/tracking.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum ActionIcons {
  SHARE = 'sharing',
  DOWNLOAD = 'download',
  BOOKMARK = 'bookmark',
  DELETE = 'delete',
}

export interface ButtonItem {
  icon: ActionIcons;
  class?: string;
  label?: string;
  labelClass?: string;
  callback?: () => void;
}

@Component({
  selector: 'mb-action-container',
  templateUrl: './action-container.component.html',
  styleUrls: ['./action-container.component.scss'],
})
export class ActionContainerComponent implements OnDestroy {
  private destroy$ = new Subject<boolean>();
  public actions = ActionIcons;
  @Output()
  actionClick = new EventEmitter<ActionIcons>();

  @Input()
  buttonItems: ButtonItem[] = [];

  @Input() resource?: ActionResourceInterface;

  constructor(
    private cdr: ChangeDetectorRef,
    private favoritesContentService: FavoritesContentService,
    private favoriteService: FavoritesService,
    private shareService: ShareModalService,
    private downloadService: DownloadService,
    private trackingService: TrackingService,
    public elRef: ElementRef
  ) {
    // FIXME: code smell
    this.favoritesContentService.favorites$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.resource) {
        this.cdr.detectChanges();
      }
    });
  }

  onActionClick(event: MouseEvent, actionItem: ButtonItem) {
    if (this.resource) {
      if (actionItem.icon === ActionIcons.BOOKMARK || actionItem.icon === ActionIcons.DELETE) {
        if (this.resource.favoriteData) {
          if (!this.favoriteService.isFavorite(this.resource.favoriteData.id)) {
            this.trackingService.trackEvent(this.resource, TRACKING_TYPES.BOOKMARK);
          }
          this.favoriteService.toggle(this.resource);
        }
      } else if (actionItem.icon === ActionIcons.SHARE) {
        if (this.resource.shareData) {
          this.shareService.openModal(this.resource);
          this.trackingService.trackEvent(this.resource, TRACKING_TYPES.SHARE);
        }
      } else if (actionItem.icon === ActionIcons.DOWNLOAD) {
        if (this.resource) {
          const downloadCallback = () => {
            // track download event after the user actually clicked download
            this.trackingService.trackEvent(this.resource, TRACKING_TYPES.DOWNLOAD);
            actionItem.callback?.();
          };
          this.downloadService.download({
            data: this.resource,
            isShareDownload: false,
            pushToDataLayer: true,
            downloadCb: downloadCallback,
          });
        }
      }
    }
    this.actionClick.emit(actionItem.icon);
    event.stopPropagation();
    return false;
  }

  get isBookmarked() {
    if (!this.resource?.favoriteData) {
      return false;
    }
    return this.favoriteService.isFavorite(this.resource.favoriteData.id);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
