import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { co2ToUnicode } from 'src/helpers/strings';

import { JSONAPIObjectRelationshipDataList } from './api.interface';
import { ContentAPIParser } from './parser/content-api.parser';

@Injectable({ providedIn: 'root' })
export class ContentAPIService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private translation: TranslateService
  ) {
    this.baseUrl = environment.apiBaseUrl;
  }

  /**
   * Helper function to preparer common request options
   *
   * @param params
   * @param setContentType
   * @returns
   */
  private prepareOptions(
    params: HttpParams | { [param: string]: string | number | boolean } = new HttpParams(),
    setContentType: boolean = false
  ): {
    withCredentials: boolean;
    headers: HttpHeaders;
    params: HttpParams | { [param: string]: string | number | boolean };
  } {
    let headers = new HttpHeaders({
      Accept: 'application/vnd.api+json',
    });

    if (setContentType) {
      headers = headers.append('Content-Type', 'application/vnd.api+json');
    }

    return {
      withCredentials: true,
      headers,
      params,
    };
  }

  private getBaseUrl(lang?: string) {
    return `${this.baseUrl}/${lang || this.lang}/jsonapi`;
  }

  /**
   * Maps currentLang to API lang
   */
  private get lang() {
    switch (this.translation.currentLang) {
      case 'en-US':
        return 'en';
      case 'de-DE':
        return 'de';
      default:
        return 'en';
    }
  }

  public getResource(type: string, id: string, params?: HttpParams, lang?: string) {
    const options = this.prepareOptions(params);
    const url = `${this.getBaseUrl(lang)}/${type}/${id}`;

    return this.http.get(url, options).pipe(
      take(1),
      map((val) => co2ToUnicode(val)),
      map((response: any) => ContentAPIParser.parseEntry(response.data, response.included))
    );
  }

  public getResources(
    type: string,
    params?: HttpParams | { [param: string]: string | number | boolean },
    lang?: string
  ) {
    const options = this.prepareOptions(params);
    const url = `${this.getBaseUrl(lang)}/${type}`;

    return (this.http.get(url, options) as Observable<JSONAPIObjectRelationshipDataList>).pipe(
      map((val) => co2ToUnicode(val)),
      map((response: JSONAPIObjectRelationshipDataList) =>
        response.data.map((data) => ContentAPIParser.parseEntry(data, response.included || []))
      ),
      catchError((err) => {
        // we do need to forward 403 error, so the magazine service can handle that edge case
        if (err.status === 403) {
          throw err;
        }
        // TODO: propper error handling
        // TODO: retry logic
        // TODO: sentry / log unrecoverable errors (5XX)
        return of([]);
      })
    );
  }

  public getUnparsedResources(type: string, _params: any = {}, lang?: string) {
    const options = this.prepareOptions(_params);
    const url = `${this.getBaseUrl(lang)}/${type}`;
    return this.http.get(url, options).pipe(
      take(1),
      map((val) => co2ToUnicode(val))
    ) as unknown as Observable<JSONAPIObjectRelationshipDataList>;
  }

  public getStaticFile(filePath: string, _params: any = {}) {
    const url = `${this.baseUrl}/sites/default/files/${filePath}`;
    const options = this.prepareOptions(_params);
    return this.http.get(url, options);
  }

  public get(type: string, _params: any = {}) {
    const { lang, ...params } = _params;
    const options = this.prepareOptions(params, true);
    const url = `${this.getBaseUrl(lang)}/${type}`;
    return this.http.get(url, options);
  }

  public post(type: string, content: any = {}, _params: any = {}) {
    const { lang, ...params } = _params;
    const options = this.prepareOptions(params, true);
    const url = `${this.getBaseUrl(lang)}/${type}`;
    return this.http.post(url, content, options).pipe(take(1));
  }

  public patch(type: string, content: any = {}, _params: any = {}) {
    const { lang, ...params } = _params;
    const options = this.prepareOptions(params, true);
    const url = `${this.getBaseUrl(lang)}/${type}`;
    return this.http.patch(url, content, options).pipe(take(1));
  }

  public delete(type: string, id: string, _params: any = {}) {
    const { lang, ...params } = _params;
    const options = this.prepareOptions(params, true);
    const url = `${this.getBaseUrl(lang)}/${type}/${id}`;
    return this.http.delete(url, options).pipe(take(1));
  }
}
