import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { DialogComponent } from './../dialog/dialog.component';
import { ScrollLockService } from './scroll-lock.service';

export interface DialogDataInterface {
  title?: string;
  style?: { [key: string]: string };
  classList?: string | string[] | Set<string> | { [klass: string]: any };
  backgroundClass?: string | string[] | Set<string> | { [klass: string]: any };
  fullContent?: boolean;
  disableCloseBtn?: boolean;
  component: any;
  inputs?: { [key: string]: any };
  outputs?: { [key: string]: (e?: any) => any };
}

export enum DialogState {
  OPEN = 'open',
  ClOSE = 'closed',
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogRef: MatDialogRef<DialogComponent> | null = null;

  private _dialogState = new ReplaySubject<DialogState>();
  private destroy$ = new Subject<boolean>();
  constructor(private dialog: MatDialog, private scrollLockService: ScrollLockService) {}

  public open(data: DialogDataInterface) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      data,
      panelClass: 'mb-dialog-pane',
      scrollStrategy: new NoopScrollStrategy(),
    });

    this.dialogRef.componentInstance.destroy$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.close();
      this.destroy$.next(true);
    });

    this.dialogRef
      .afterOpened()
      .pipe(take(1))
      .subscribe(() => {
        this.scrollLockService.lock();
        this._dialogState.next(DialogState.OPEN);
      });

    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this._dialogState.next(DialogState.ClOSE);
        this.scrollLockService.unlock();
      });
    return this.dialogRef;
  }

  public openNext(data: DialogDataInterface) {
    if (this.dialogRef) {
      this.dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.open(data);
        });
      this.close();
    } else {
      this.open(data);
    }
  }

  public close() {
    this._dialogState.next(DialogState.ClOSE);
    this.dialogRef?.close(true);
    this.dialogRef = null;
  }

  get dialogState$() {
    return this._dialogState;
  }
}
