<div *ngIf="resolutions?.length" class="resolution-controller" [class.open]="open">
  <div class="resolution-options-wrapper" [class.open-bottom]="openBottom">
    <p class="resolution-title copy-m font-family-text">
      {{ 'QUALITY' | translate }}
    </p>
    <ul class="resoltion-list">
      <li
        *ngFor="let item of resolutions; let index = index"
        class="resolutions-option font-family-text copy-m"
        [class.active]="item.bandwidth === activeResolution"
        (click)="changeResolution(item.bandwidth)"
      >
        {{ isComposed ? item.height / 2 : item.height }}p
      </li>
      <li
        class="resolutions-option font-family-text copy-m"
        [class.active]="activeResolution === infinity"
        (click)="changeResolution(infinity)"
      >
        {{ 'AUTOMATIC' | translate }}
      </li>
    </ul>
  </div>
  <div class="resolution-btn btn btn-quaternary" (click)="toggleResolutions($event)">
    <span class="mb-icon mb-icon-settings-flat"></span>
  </div>
</div>
