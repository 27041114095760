import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  ElementRef,
  Renderer2,
  EventEmitter,
  Output,
  ViewEncapsulation,
  HostBinding,
  ChangeDetectorRef,
} from '@angular/core';
import { CaptureModel } from '@modules/media/models/capture.model';
import { PlayerService } from '@modules/media/service/player.service';
import { DialogService } from '@modules/shared/services/dialog.service';
import { LightboxService } from '@modules/shared/services/lightbox.service';
import { ModalService } from '@modules/shared/services/modal.service';
import { ShareModalService } from '@modules/shared/services/share.service';

import { VideoDefaultsComponent } from '../video-defaults';

@Component({
  selector: 'mb-inline-player',
  templateUrl: './inline-player.component.html',
  styleUrls: ['./inline-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlinePlayerComponent extends VideoDefaultsComponent {
  @HostBinding('class.hover-video')
  @Input()
  hoverVideo = false;
  @Input() muted = false;

  @ViewChild('videoParent', { static: false }) videoParent!: ElementRef;
  @Input() controls = true;
  @Input() allowPictureInPicture = true;
  @Output() capture = new EventEmitter<CaptureModel>();
  constructor(
    public elRef: ElementRef,
    public playerService: PlayerService,
    public renderer: Renderer2,
    shareService: ShareModalService,
    lightboxService: LightboxService,
    dialogService: DialogService,
    modalService: ModalService,
    cdr: ChangeDetectorRef
  ) {
    super(shareService, lightboxService, dialogService, modalService, cdr, playerService, elRef, renderer);
  }

  hoverPlayer() {
    if (this.hoverVideo && this.player) {
      this.player.play();
    }
  }

  stopHoverplay() {
    if (this.hoverVideo && this.player) {
      this.player.stop();
    }
  }

  togglePlay() {
    this.player.togglePlay();
  }
}
