<div class="document-mobile-view">
  <div class="document-icon-wrapper">
    <span class="document-icon mb-icon mb-icon-documents-detail"></span>
    <span class="document-icon-label">{{ filetypeName }}</span>
  </div>
  <div *ngIf="title" class="document-title-wrapper">
    <span class="document-title" [innerHTML]="title"></span>
  </div>

  <a *ngIf="showOpenButton" [href]="url" class="btn btn-secondary-dark">
    <span class="mb-icon mb-icon-link-out"></span>
    <span class="btn-label">{{ 'DOCUMENT_OPEN' | translate }}</span>
  </a>
</div>
