<div class="toast-content copy-m" [class.full-content]="fullContent" (click)="checkTrigger()">
  <div *ngIf="title" class="toast-content-item">{{ title | translate }}</div>
  <div *ngIf="text" class="toast-content-item">{{ text | translate }}</div>
  <ng-template [mbToastContentHost]></ng-template>
</div>

<div class="close-btn-wrapper" [class.white-btn]="whiteCloseBtn">
  <div class="btn btn-plain" (click)="close()">
    <span class="mb-icon mb-icon-close"></span>
  </div>
</div>
