<ng-container *ngIf="mediaList.length > 1">
  <div class="prev-btn-wrapper">
    <div class="btn btn-round" (click)="prev()">
      <span class="mb-icon mb-icon-back-thin"></span>
    </div>
  </div>

  <div class="next-btn-wrapper">
    <div class="btn btn-round" (click)="next()">
      <span class="mb-icon mb-icon-forward-thin"></span>
    </div>
  </div>
</ng-container>

<div
  class="lightbox-content-wrapper"
  (swipeleft)="swipe('left')"
  (swiperight)="swipe('right')"
  [class.no-padding]="mediaList[activeIndex].mediaType === mediaType.DOCUMENT"
>
  <ng-container *ngFor="let media of mediaList; let index = index">
    <ng-container *ngIf="activeIndex === index">
      <ng-container [ngSwitch]="media.mediaType">
        <ng-container *ngSwitchCase="mediaType.IMAGE">
          <ng-container *ngTemplateOutlet="imageTpl; context: { media: media }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="mediaType.AUDIO">
          <ng-container *ngTemplateOutlet="audioTpl; context: { media: media }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="mediaType.DOCUMENT">
          <ng-container *ngTemplateOutlet="documentTpl; context: { media: media }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="mediaType.VIDEO">
          <ng-container *ngTemplateOutlet="videoTpl; context: { media: media }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #imageTpl let-media="media">
  <mb-image class="contain" [isLightboxMedia]="true" [media]="media"></mb-image>
</ng-template>

<ng-template #documentTpl let-media="media">
  <mb-pdf-preview
    *ngIf="media.filetypeName === 'PDF'"
    [url]="media.original.url"
    [title]="media.title"
  ></mb-pdf-preview>
  <mb-document-preview
    *ngIf="media.filetypeName !== 'PDF'"
    [title]="media.title"
    [filetypeName]="media.filetypeName"
    [url]="media.original.url"
    [showOpenButton]="!isPreMedia"
  ></mb-document-preview>
</ng-template>

<ng-template #audioTpl let-media="media">
  <mb-audio [media]="media"></mb-audio>
</ng-template>

<ng-template #videoTpl let-media="media">
  <mb-video [controls]="true" [allowPictureInPicture]="false" [media]="media"></mb-video>
</ng-template>
