import { Injectable } from '@angular/core';
import { TranscriptEntryInterface } from '@modules/media/models/transcript-entry.model';
import {
  TranscriptInterface,
  TranscriptNavigationMode,
  TranscriptStatus,
} from '@modules/media/models/transcript.model';

import { Adapter } from './adapter';
import { MediaAdapter } from './media.adapter';
import { TranscriptEntryResponseInterface } from './transcript-entry.adapter';

export interface TranscriptResponseInterface {
  id: string;
  type: string;
  title: string;
  languages: string[];
  master_language: string;
  navigation_mode: TranscriptNavigationMode;
  offset: number;
  optional_display_languages: [];
  start_time: number;
  transcript_entries: TranscriptEntryResponseInterface[];
  transcript_status: TranscriptStatus;
  video_url: any; // ?
}

@Injectable({
  providedIn: 'root',
})
export class TranscriptAdapter implements Adapter<TranscriptInterface> {
  constructor(private mediaAdapter: MediaAdapter) {}
  public parse(d: TranscriptResponseInterface): TranscriptInterface {
    return {
      id: d.id,
      type: 'transcripts',
      title: d.title,
      masterLang: d.master_language,
      languages: d.languages,
      navigationMode: d.navigation_mode,
      offset: d.offset,
      status: d.transcript_status,
      transcriptEntries: d.transcript_entries,

      documents: [], // needs to be implemented. used to decide if transcript can bookmark
      disableActionAssets: false, // needs to be implemented
      disabledActionAssetsToastText: '', // needs to be implemented
      entryTypes: [], // need entry types to add filter
      speakers: [], // needed to display speakers
    };
  }

  public parseEntry(d: TranscriptEntryResponseInterface): TranscriptEntryInterface {
    return {
      id: d.id,
      type: d.type,
      text: d.text?.value,
      chapter: d.chapter?.title || '',
      entryType: d.entry_type,
      linkText: d.link?.title || '',
      linkUrl: d.link?.uri || '',
      media: d.livestream_media.map((item) => this.mediaAdapter.parse(item, [])),
      quote: d.quote,
      speaker: d.speaker
        ? {
            id: d.speaker.id,
            title: d.speaker.title,
            firstname: d.speaker.first_name,
            lastname: d.speaker.last_name,
          }
        : null,
      status: TranscriptStatus.STARTED,
      test: false, // might be needed
      time: d.time,
    };
  }
}
