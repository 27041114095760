import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mb-modal',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  // TODO: handle open / close and other generic modal things here?
  // TODO: provide <wb-modal> wrapper and use <ng-content>?
}
