import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'mb-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationComponent {
  // currentTimeFormat
  public dtf = {
    hrs: '0',
    min: '00',
    sec: '00',
  };
  // durationTimeFormat
  public ctf = {
    hrs: '0',
    min: '00',
    sec: '00',
  };

  private _currentTime = 0;
  @Input()
  set currentTime(v: number) {
    this._currentTime = v;
    this.ctf = this.parseTime(v);
  }
  get currentTime() {
    return this._currentTime;
  }

  private _duration = 0;
  @Input()
  set duration(v: number) {
    if (v === Infinity) {
      v = 0;
    }
    this._duration = v;
    this.parseTime(v);
    this.dtf = this.parseTime(v || 0);
  }
  get duration() {
    return this._duration;
  }

  private parseTime(time: number) {
    return {
      hrs: `${Math.floor(time / 3600)}`,
      min: `${Math.floor((time % 3600) / 60)}`.padStart(2, '0'),
      sec: `${Math.floor(time % 60)}`.padStart(2, '0'),
    };
  }
}
