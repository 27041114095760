<div class="flyout-wrapper-outer" [class.paused]="(player.playing$ | async) === false">
  <div class="flyout-wrapper">
    <div class="flyout-ratio-container">
      <div class="flyout-container" [class.composed-player]="player.playerData.isComposed" #flyoutContainer></div>
    </div>
  </div>
  <div class="flyout-controls">
    <span class="return-pip-btn icon-btn btn btn-plain" (click)="closeFlyout(true)">
      <span class="mb-icon mb-icon-picture-in-picture-exit"></span>
    </span>

    <span class="close-pip-btn icon-btn btn btn-plain" (click)="closeFlyout()">
      <span class="mb-icon mb-icon-close"></span>
    </span>

    <span class="play-pause-btn icon-btn" (click)="player.togglePlay()">
      <span class="btn btn-round">
        <span class="mb-icon mb-icon-player-{{ (player.playing$ | async) ? 'pause' : 'play' }}"></span>
      </span>
    </span>
  </div>
  <span
    *ngIf="player.playerData.screenshotUrl && player.playerData.showScreenshotButton"
    class="play-capture-btn icon-btn"
    (click)="capture()"
  >
    <span class="btn btn-round">
      <span class="mb-icon mb-icon-image-gallery"></span>
    </span>
  </span>
</div>
