import { QAForm } from '@modules/content-api/adapter/qaform.adapter';
import { FavorableContent } from '@modules/favorites/interfaces/favorable-content.interface';
import { FavoriteDataInterface } from '@modules/favorites/interfaces/favorite-data.interface';
import { LightboxMetadataInterface } from '@modules/shared/interfaces/lightbox.interface';
import { DownloadableContent } from '@modules/shared/services/download.service';
import { SharableContent } from '@modules/shared/services/share.service';

import { MediaDefaultModel } from './media-default.model';
import { MediaType } from './media.types';
import { TranscriptModel } from './transcript.model';

export enum VideoType {
  COMPOSED = 'composed',
  ALTERNATERENDITIONS = 'alternaterenditions',
  SINGLE = 'single',
  SOCIAL = 'social',
}

export enum VideoStatus {
  STOPPED = 'stopped',
  VOD = 'vod',
  PRE_LIVE = 'pre_live',
  LIVE = 'live',
}

export interface Camera {
  id: string;
  sort?: number;
  title: string;
}
export interface MediaVideoInterface extends SharableContent, DownloadableContent, FavorableContent {
  id: string;
  marsShelfNumber: string | null;
  marsOriginId: string | null;
  title: string;
  isStream?: boolean;
  type?: VideoType;
  mediaType: string;
  formats: MediaVideoFormatInterface[];
  /**
   * TODO: Check if multiple formats are needed here
   * if yes, we need the height and width of the poster image
   */
  poster: string;
  caption?: string;
  description?: string;
  displayDate?: Date;
  status?: VideoStatus;
  qaForm?: QAForm;
  screenshotOffset?: number;
  screenshotUrl?: string;
  showScreenshotButton?: boolean;
  showQaButton?: boolean;
  showScrubberBar?: boolean;
  cameras?: Camera[];
  fuelLabel?: string;
  transcript?: any;
  favoriteData?: FavoriteDataInterface;
}

export interface MediaVideoFormatInterface {
  id: string;
  key?: string;
  url: string;
  name: string;
  mimeType: string;
  height: number;
  width: number;
  extension: string;
  duration: number;
  filesize: number;
}

export class MediaVideoModel extends MediaDefaultModel {
  public type = MediaType.VIDEO;
  public isStream = false;
  public videoType: VideoType;
  public mediaType = MediaType.VIDEO;
  public original: MediaVideoFormatModel;
  public screenshotUrl: string;
  public screenshotOffset: number;
  public isComposed: boolean;
  public poster: string;
  public formats: MediaVideoFormatModel[] = [];
  public sources: { type: string; src: string }[];
  public qaForm?: QAForm;
  public cameras: Camera[];
  public fuelLabel: string;
  public transcript: TranscriptModel | null;
  public ratio: number;
  public inverseRatio: number;
  public status: VideoStatus;
  public showScreenshotButton: boolean;
  public showQaButton: boolean;
  public showScrubberBar: boolean;
  public filesize: number = 0;
  public extension: string = '';
  public duration: number | null;

  constructor(data: MediaVideoInterface) {
    super(data);
    this.isStream = !!data.isStream;
    this.videoType = data.type || VideoType.SINGLE;
    this.isComposed = data.type === VideoType.COMPOSED;
    this.poster = data.poster;
    this.screenshotUrl = data.screenshotUrl || '';
    this.screenshotOffset = data.screenshotOffset || 0;
    this.fuelLabel = data.fuelLabel || '';
    this.formats = data.formats.map((f: any) => new MediaVideoFormatModel(f));
    this.original = this.findOriginal();
    this.cameras = data.cameras || [];
    this.ratio = this.original?.ratio || 0;
    this.inverseRatio = this.original?.inverseRatio || 0;
    this.status = data.status || VideoStatus.VOD;
    this.showScreenshotButton = !!data.showScreenshotButton;
    this.showQaButton = !!data.showQaButton;
    this.qaForm = data.qaForm;
    this.showScrubberBar = data.showScrubberBar === undefined || data.showScrubberBar;
    this.filesize = this.original?.filesize || 0;
    this.extension = this.original?.extension || '';
    this.duration = this.original?.duration || null;

    this.transcript = data.transcript;

    // remove original format, if other formats are available (they are usally way to large -> use preview instead)
    const _formats = this.formats.filter((format) => format.key !== 'original');
    if (_formats.length != 0) {
      this.formats = _formats;
    }

    this.sources = this.formats.map((format) => {
      return {
        type: format.mimeType,
        src: format.url,
      };
    });
  }

  public getLightboxMetadata(): LightboxMetadataInterface {
    return {
      ...super.getLightboxMetadata(),
      duration: this.duration || undefined,
    };
  }

  private findFormatClosestTo(value: number, prop: 'width' | 'height' | 'ratio') {
    const format = this.formats.reduce((acc: MediaVideoFormatModel, cur: MediaVideoFormatModel) => {
      if (!acc) {
        acc = cur;
      }
      return Math.abs(cur[prop] - value) < Math.abs(acc[prop] - value) ? cur : acc;
    });
    return format || this.original;
  }

  public closestToWidth(width: number): MediaVideoFormatModel {
    return this.findFormatClosestTo(width, 'width');
  }

  private findOriginal(): MediaVideoFormatModel {
    return (
      (this.formats.find((item) => item.key === 'original') as MediaVideoFormatModel) ||
      this.formats.reduce(
        (prev, current) => (prev.filesize > current.filesize ? prev : current),
        {} as MediaVideoFormatModel
      )
    );
  }
}

export class MediaVideoFormatModel {
  public id: string;
  public key: string;
  public type = MediaType.VIDEO;
  public url: string;
  public name: string;
  public mimeType: string;
  public ratio: number;
  public inverseRatio: number;
  public height: number;
  public width: number;
  public filesize: number;
  public extension: string;
  public duration: number;
  public minutes: number;
  public seconds: number;

  constructor(data: MediaVideoFormatInterface) {
    this.id = data.id;
    this.key = data.key ?? '';
    this.mimeType = data.mimeType;
    this.ratio = data.width / data.height;
    this.inverseRatio = data.height / data.width;
    this.height = data.height;
    this.width = data.width;
    this.url = data.url;
    this.name = data.name;
    this.filesize = data.filesize;
    this.extension = data.extension;
    this.duration = data.duration;

    this.minutes = Math.floor(this.duration / 60);
    this.seconds = this.duration % 60;
  }
}
