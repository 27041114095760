import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { PreMediaService } from '@app/features/pre-media/pre-media.service';
import { FavoritesContentService } from '@modules/favorites/services/favorites-content.service';
import { FavoritesService } from '@modules/favorites/services/favorites.service';
import { EventsService } from '@modules/shared/services/events.service';
import { ModalService } from '@modules/shared/services/modal.service';
import { UserService } from '@modules/shared/services/user.service';
import { Observable } from 'rxjs';

import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'mb-my-mb-media-modal',
  templateUrl: './my-mb-media-modal.component.html',
  styleUrls: ['./my-mb-media-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyMBMediaModalComponent extends ModalComponent {
  public isLoggedIn$: Observable<boolean | null>;
  public favoritesCount$: Observable<number>;
  public eventsCount$: Observable<number>;
  public newEventsCount$: Observable<number>;
  public preMediaCount$: Observable<number>;
  public newPreMediaCount$: Observable<number>;
  public hasPreMediaAccess$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private favoritesService: FavoritesService,
    private favoritesContentService: FavoritesContentService,
    private preMediaService: PreMediaService,
    private eventsService: EventsService,
    private modalService: ModalService,
    private router: Router
  ) {
    super();
    this.isLoggedIn$ = this.userService.isLoggedIn();
    this.favoritesCount$ = this.favoritesContentService.favoritesCount$;
    this.eventsCount$ = this.eventsService.myEventsCount$;
    this.newEventsCount$ = this.eventsService.pendingRegistrationsCount$;
    this.preMediaCount$ = this.preMediaService.allPreMediaCount$;
    this.newPreMediaCount$ = this.preMediaService.preMediaCount$;
    this.hasPreMediaAccess$ = this.preMediaService.hasPreMedia$;
  }

  // TODO: implement in parent component
  public onCloseClick(): void {
    this.modalService.close();
  }

  public openMyFavorites(): void {
    this.modalService.close(); // super.close()
    this.favoritesService.openFavorites();
  }

  public openMyEvents(): void {
    this.modalService.close(); // super.close()
    this.router.navigate(['my-events']);
  }

  public openMyPreMedia(): void {
    this.modalService.close(); // super.close()
    this.router.navigate(['pre-media']);
  }

  public login(): void {
    this.userService.login();
  }
}
