import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TrackingService } from '@modules/shared/services/tracking.service';
import { BehaviorSubject, filter, Observable } from 'rxjs';

@Injectable()
export class AppService {
  public isLightPage$: Observable<boolean>;
  public isLoading$: Observable<boolean>;

  private _isLightPage$ = new BehaviorSubject<boolean>(false);
  private _isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private router: Router, private title: Title, private trackingService: TrackingService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this._isLoading$.next(true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this._isLoading$.next(false);
      }
    });

    // Track page views
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.trackingService.push((event as NavigationEnd).urlAfterRedirects);
    });

    this.isLightPage$ = this._isLightPage$.asObservable();
    this.isLoading$ = this._isLoading$.asObservable();
  }

  public setTitle(title: string): void {
    this.title.setTitle(title);
  }

  public getTitle(): string {
    return this.title.getTitle();
  }

  public lightBackgroundPage() {
    this._isLightPage$.next(true);
  }

  public darkBackgroundPage() {
    this._isLightPage$.next(false);
  }
}
