import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mb-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  @Input() headline!: string;
  @Input() message!: string;

  @Output() cancel = new EventEmitter();

  closeError() {
    this.cancel.emit();
  }
}
