import { FavorableContent } from '@modules/favorites/interfaces/favorable-content.interface';
import { FavoriteDataInterface } from '@modules/favorites/interfaces/favorite-data.interface';
import { TrackingItem } from '@modules/shared/gtm/data-layer.types';
import { LightboxContextLink, LightboxMetadataInterface } from '@modules/shared/interfaces/lightbox.interface';
import { TrackableContent } from '@modules/shared/services/data-layer.service';
import { DownloadableContent, DownloadData } from '@modules/shared/services/download.service';
import { SharableContent, SharingData } from '@modules/shared/services/share.service';
import { filetype } from 'src/helpers/strings';

export abstract class MediaDefaultModel
  implements SharableContent, DownloadableContent, FavorableContent, TrackableContent
{
  public id: string;
  public marsShelfNumber: string | null;
  public marsOriginId: string | null;
  public name: string;
  public title: string;
  public caption: string;
  public description: string;
  public filesize: number;
  public filename: string;
  public filetypeName: string;
  public displayDate?: Date;
  public formats: any[] = [];
  public original: any;
  public connectedData?: LightboxContextLink;
  public fuelLabel?: string;

  public favoriteData?: FavoriteDataInterface;
  public shareData?: SharingData;
  public downloadData?: DownloadData;
  public trackingData: TrackingItem;

  constructor(data: any) {
    this.id = data.id;
    this.marsShelfNumber = data.marsShelfNumber ?? null;
    this.marsOriginId = data.marsOriginId ?? null;
    this.name = data.name;
    this.title = data.title || data.name; // we dont get title from the API
    this.caption = data.caption;
    this.description = data.description;
    this.displayDate = data.displayDate;
    this.filesize = data.filesize;
    this.filename = data.filename;
    this.filetypeName = filetype(
      data.filename || (data.formats.find((item: any) => item.id === 'original') || data.formats[0])?.name
    );
    this.fuelLabel = data.fuelLabel;
    this.favoriteData = data.favoriteData;
    this.shareData = data.shareData;
    this.downloadData = data.downloadData;

    this.trackingData = {
      id: this.id,
      type: data.mediaType,
      title: this.title,
      marsOriginId: this.marsOriginId ?? undefined,
      shelfNumber: this.marsShelfNumber ?? undefined,
    };
  }

  get mimeType(): string {
    if (this.formats?.length) {
      return (this.formats.find((item) => item.id === 'original') || this.formats[0]).mimeType;
    }
    return '';
  }

  public getLightboxMetadata(): LightboxMetadataInterface {
    return {
      caption: this.caption,
      description: this.description,
      fuelLabel: this.fuelLabel || undefined,
      fileSize: this.filesize,
      fileFormat: this.filetypeName,
      marsShelfNumber: this.marsShelfNumber || undefined,
      displayDate: this.displayDate,
    };
  }
}
