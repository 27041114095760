import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mb-download-prepare-modal',
  templateUrl: './download-prepare-modal.component.html',
  styleUrls: ['./download-prepare-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadPrepareModalComponent {
  @Input() text?: string;
  @Output() cancel = new EventEmitter();
}
