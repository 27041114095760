import { Platform } from '@angular/cdk/platform';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ChangeDetectorRef,
  TemplateRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { WindowService } from '@modules/shared/services/window.service';
import { Subject, takeUntil } from 'rxjs';

import { Option } from '../interfaces/option.interface';

@Component({
  selector: 'mb-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();
  public useNativeDropdown = false;

  @Input() labelTpl?: TemplateRef<any>;
  @Input() closeOnScroll = false;

  @HostBinding('class.open-top')
  @Input()
  openTop = false;

  @Input() hideLabel = false;

  @Input()
  @HostBinding('class.is-open')
  public isOpen = false;

  public valueLabel: string = '';
  @Input() icon: string = '';

  @Input() options: Option[] = [];

  private _value: any;
  @Input()
  set value(v: any) {
    this.setValue(v);
  }
  get value() {
    return this._value;
  }

  @Output() selectionChanged = new EventEmitter<Option>();
  @Output() dropdownOpened = new EventEmitter<void>();

  constructor(private cdr: ChangeDetectorRef, private platform: Platform, private windowService: WindowService) {
    this.useNativeDropdown = this.platform.IOS || this.platform.ANDROID;
  }

  public open(e: MouseEvent) {
    this.dropdownOpened.emit();
    const docClickFn = () => {
      this.isOpen = false;
      this.cdr.detectChanges();
      document.removeEventListener('click', docClickFn);
    };

    if (this.isOpen) {
      docClickFn();
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    this.isOpen = true;
    this.cdr.detectChanges();
    document.addEventListener('click', docClickFn);
  }

  public nativeOptionSelect(value: string) {
    const selectedOption = this.options.find((option) => option.value === value) as Option;
    this.optionSelected(selectedOption);
  }

  public optionSelected(e: Option) {
    if (e.value !== this.value) {
      this.value = e.value;
      this.selectionChanged.emit(e);
    }
  }

  private setValue(v: any) {
    this._value = v;
    const foundValue = this.options.find((option) => option.value === v);
    if (foundValue) {
      this.valueLabel = foundValue.label;
    } else {
      this.valueLabel = v;
    }
  }

  ngOnInit(): void {
    if (this.closeOnScroll) {
      this.windowService.windowScroll$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        const wasOpen = this.isOpen;
        this.isOpen = false;
        if (wasOpen) {
          this.cdr.detectChanges();
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
