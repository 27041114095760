<div class="accordion-header" [class.open]="isOpen" (click)="toggle()">
  <ng-content select="[accordion-header]"></ng-content>
  <div class="accordion-header-icon-wrapper">
    <ng-content select="[accordion-header-icon-content]"></ng-content>
    <span *ngIf="!hideIcon" class="accordion-header-icon mb-icon mb-icon-down-thin"></span>
  </div>
</div>

<div *ngIf="isOpen" class="accordion-content">
  <ng-content select="[accordion-content]"></ng-content>
</div>
