import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'mb-scrubber',
  templateUrl: './scrubber.component.html',
  styleUrls: ['./scrubber.component.scss'],
})
export class ScrubberComponent {
  public isScrubbing = false;
  public internalValue = 0;

  public elWidth = 0;
  public elPos = 0;
  public _hoverValue = 0;

  @ViewChild('scrubberContent', { static: false }) scrubberContent!: ElementRef<any>;

  @HostBinding('class.vertical')
  @Input()
  vertical = false;

  @Input() hoverTpl!: TemplateRef<any>;

  @Input() sports = [
    {
      point: 4,
      label: 'foo',
    },
  ];
  @Input() value = 0;
  @Input() max = 100;
  @Input() showScrubTarget = false;

  @Output() progressChanged = new EventEmitter<number>();
  @Output() progressChanging = new EventEmitter<number>();
  @Output() hoverValue = new EventEmitter<number>();

  constructor(public elRef: ElementRef) {}

  touch(e: any) {
    const touch = e.touches[0] || e.changedTouches[0];
    const rect = this.elRef.nativeElement.getBoundingClientRect();
    this.updateValue(touch.clientX - rect.left, touch.clientY - rect.top);
  }

  onHoverValue(e: any) {
    this.updateValue(e.offsetX, e.offsetY);
  }

  updateValue(x: number, y: number) {
    const targetEl = this.elRef.nativeElement;
    if (this.vertical) {
      this._hoverValue = ((targetEl.clientHeight - y) / targetEl.clientHeight) * this.max;
    } else {
      this._hoverValue = (x / targetEl.clientWidth) * this.max;
    }
    this._hoverValue = this._hoverValue < 0 ? 0 : this._hoverValue > this.max ? this.max : this._hoverValue;
    let left = x;
    if (this.scrubberContent) {
      const calcedExtraWidth = this.scrubberContent.nativeElement.clientWidth / 2;
      const hostWidth = this.elRef.nativeElement.clientWidth;
      if (left < calcedExtraWidth) {
        left = calcedExtraWidth;
      } else if (left + calcedExtraWidth > hostWidth) {
        left = hostWidth - calcedExtraWidth;
      }
    }
    this.elRef.nativeElement.style.setProperty('--scrubber-hover-pos', `${left}px`);
    this.hoverValue.emit(this._hoverValue);
  }

  scrubEnd() {
    this.progressChanged.emit(Number(this._hoverValue));
  }
  scrubbing() {
    this.progressChanging.emit(Number(this._hoverValue));
  }

  keydown(e: any) {
    e.preventDefault();
  }

  round(number: number) {
    return Math.round(number);
  }

  get progress() {
    return (this.value / this.max) * 100 || 0;
  }
}
