import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Observable, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

declare const window: Window | any;

// https://compuccino.atlassian.net/wiki/spaces/OID/pages/110300342/04+Breakpoints+und+Raster

export enum Screen {
  XXXS = 0,
  XXS = 375,
  XS = 568,
  SM = 768,
  MD = 1024,
  LG = 1366,
  XL = 1600,
  XXL = 1920,
}

export const enum ScreenMatchMedia {
  XXXS = '(max-width: 374.98px)',
  XXS = '(min-width: 375px) and (max-width: 567.98px)',
  XS = '(min-width: 568px) and (max-width: 767.98px)',
  SM = '(min-width: 768px) and (max-width: 1023.98px)',
  MD = '(min-width: 1024px) and (max-width: 1365.98px)',
  LG = '(min-width: 1366px) and (max-width: 1599.98px)',
  XL = '(min-width: 1600px) and (max-width: 1919.98px)',
  XXL = '(min-width: 1920px)',
}

function calculateScreen(): Screen {
  const windowWidth = window.innerWidth;
  let screen = Screen.XXXS;
  if (windowWidth >= Screen.XXL) {
    screen = Screen.XXL;
  } else if (windowWidth >= Screen.XL) {
    screen = Screen.XL;
  } else if (windowWidth >= Screen.LG) {
    screen = Screen.LG;
  } else if (windowWidth >= Screen.MD) {
    screen = Screen.MD;
  } else if (windowWidth >= Screen.SM) {
    screen = Screen.SM;
  } else if (windowWidth >= Screen.XS) {
    screen = Screen.XS;
  } else if (windowWidth >= Screen.XXS) {
    screen = Screen.XXS;
  } else {
    screen = Screen.XXXS;
  }

  return screen;
}
function mobileCheck(): boolean {
  let isMobile = false;
  // tslint:disable:max-line-length
  // device detection: https://stackoverflow.com/questions/3514784/what-is-the-best-way-to-detect-a-mobile-device-in-jquery#answer-3540295
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    isMobile = true;
  }
  // tslint:enable:max-line-length
  return isMobile;
}

const MAIN_NAVIGATION_MINIMIZE_OFFSET = 120;
@Injectable()
export class WindowService implements OnDestroy {
  public window;
  public screen: Screen;
  public isMobileBrowser = false;

  private _isHeaderMinimized = false;
  private _isHeaderMinimized$ = new BehaviorSubject<boolean>(false);
  private _scroll$: Observable<number>;
  private _screen$: BehaviorSubject<Screen>;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public screen$: Observable<Screen>;

  constructor(private ngZone: NgZone) {
    this.window = window;
    this.screen = calculateScreen();
    this.isMobileBrowser = mobileCheck();

    this._scroll$ = fromEvent(window, 'scroll').pipe(map(() => this.window.pageYOffset));

    this._screen$ = new BehaviorSubject(this.screen);
    this.screen$ = this._screen$.asObservable();

    window.matchMedia(ScreenMatchMedia.XXS).addListener(this.onScreenChange.bind(this));
    window.matchMedia(ScreenMatchMedia.XS).addListener(this.onScreenChange.bind(this));
    window.matchMedia(ScreenMatchMedia.SM).addListener(this.onScreenChange.bind(this));
    window.matchMedia(ScreenMatchMedia.MD).addListener(this.onScreenChange.bind(this));
    window.matchMedia(ScreenMatchMedia.LG).addListener(this.onScreenChange.bind(this));
    window.matchMedia(ScreenMatchMedia.XL).addListener(this.onScreenChange.bind(this));

    // fix for ios because it will not trigger matchMedia on rotation change
    fromEvent(window, 'orientationchange').subscribe(() => this.onScreenChange(<MediaQueryList>{ matches: true }));

    this.windowScroll$.subscribe((scrollTop) => {
      const isMinimized = scrollTop >= MAIN_NAVIGATION_MINIMIZE_OFFSET;
      if (this._isHeaderMinimized !== isMinimized) {
        this._isHeaderMinimized = isMinimized;
        this._isHeaderMinimized$.next(this._isHeaderMinimized);
      }
    });
  }

  public setScreen(screen: Screen) {
    this.screen = screen;
    this._screen$.next(screen);
  }

  get windowScroll$() {
    return this._scroll$;
  }

  get isHeaderMinimized$(): Observable<boolean> {
    return this._isHeaderMinimized$.asObservable();
  }

  private onScreenChange(e: MediaQueryList) {
    if (!e.matches) {
      return;
    }
    const screen = calculateScreen();
    if (screen !== this.screen) {
      this.screen = screen;
      this.ngZone.run(() => {
        this._screen$.next(this.screen);
      });
    }
  }

  public scrollToId(id: string) {
    const element = <HTMLElement>document.getElementById(id);
    if (!element) {
      return;
    }
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    const currentPos = document.documentElement.scrollTop;
    const elPos = element.getBoundingClientRect().top;
    return this.scrollTo(currentPos + elPos - 90);
  }

  public scrollTo(top: number = 0, left: number = 0) {
    window.scrollTo({
      top,
      left,
      behavior: 'smooth',
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
