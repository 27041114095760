import { ComponentType } from '@angular/cdk/portal';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  Directive,
  ViewContainerRef,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { PreMedia } from '@app/features/pre-media/pre-media.adapter';
import { FavoritesService } from '@modules/favorites/services/favorites.service';
import { MediaType } from '@modules/media/models/media.types';
import { ToastService } from '@modules/toast/toast.service';
import { ActionContainerComponent, ActionIcons } from '@modules/ui/action-container/action-container.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LightboxContextLink, LightboxMetadataInterface } from '../interfaces/lightbox.interface';
import { LightboxService } from '../services/lightbox.service';

@Directive({
  selector: '[mbContentHost]',
})
export class ContentHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
  selector: 'mb-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightboxComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();
  public actionIcons = ActionIcons;
  public contentScrolled = false;
  public infoScrolled = false;
  public hubInView = false;

  @ViewChild(ContentHostDirective, { static: true }) contentTpl!: ContentHostDirective;
  @ViewChild('actionContainerHub', { static: false }) actionContainerHub!: ActionContainerComponent;
  @ViewChild('innerContentWrapper', { static: true }) innerContentWrapper!: ElementRef<any>;

  @Input() component!: ComponentType<any>;
  @Input() fullContent: boolean = false;
  @Input() componentData: any;
  @Input() sidebarData?: LightboxMetadataInterface;
  @Input() contextLink?: LightboxContextLink;
  @Input() preMedia?: PreMedia;

  constructor(
    public lightboxService: LightboxService,
    private favoritesService: FavoritesService,
    private toastService: ToastService
  ) {}

  closeLightbox(closeFavorites = false) {
    if (closeFavorites) {
      this.favoritesService.closeFavorites();
    }
    this.lightboxService.close();
  }

  checkContentScroll(e: any) {
    this.contentScrolled = e.target.scrollTop > 0;
    if (this.actionContainerHub) {
      const elRect = this.actionContainerHub.elRef.nativeElement.getBoundingClientRect();
      this.hubInView = elRect.top < window.innerHeight;
    }
  }

  checkInfoScroll(e: any) {
    this.infoScrolled = e.target.scrollTop > 0;
  }

  showCounter() {
    return this.lightboxService.mediaCount > 1 && this.lightboxService.activeResource?.type !== MediaType.DOCUMENT;
  }

  ngOnInit() {
    this.toastService.closeLightbox$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.lightboxService.close();
    });

    this.lightboxService.sidebarData$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.sidebarData = data;

      // sometimes (favorites) context link is inside the sidebarData
      // TODO: refactor such that context is alway extra OR always in the data (first one is cleaner?)
      if (!this.contextLink) {
        this.contextLink = this.sidebarData?.contextLink;
      }
    });
    const viewContainerRef = this.contentTpl.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(this.component);
    if (this.componentData) {
      for (let i in this.componentData) {
        componentRef.instance[i] = this.componentData[i];
      }
    }

    // sometimes (favorites) context link is inside the sidebarData
    // TODO: refactor such that context is alway extra OR always in the data (first one is cleaner?)
    if (!this.contextLink) {
      this.contextLink = this.sidebarData?.contextLink;
    }

    // Adding class to mb-image which will set object-fit: contain
    this.innerContentWrapper.nativeElement.children[0].classList.add('contain');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  isBookmarked(id: string) {
    return this.favoritesService.isFavorite(id);
  }
}
