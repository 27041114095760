<video
  *ngIf="media"
  #player
  [loop]="loop"
  [muted]="muted"
  [autoplay]="autoplay"
  [poster]="media.poster"
  [preload]="media.poster ? 'none' : 'metadata'"
  [attr.playsInline]="playsInline ? '' : undefined"
>
  <source *ngFor="let item of media.sources" [src]="item.src" [type]="item.type" />
</video>
<ng-container *ngIf="controls">
  <span
    *ngIf="!player || !player.nativeElement || player.nativeElement.paused === true"
    class="play-icon mb-icon mb-icon-player-play"
  ></span>
</ng-container>
