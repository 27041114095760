import { Injectable } from '@angular/core';
import { MediaQueryService as WBMediaQueryService } from '@workbench/core';

@Injectable({
  providedIn: 'root',
})
export class MediaQueryService {
  private mediaQueryService = new WBMediaQueryService(window);
  constructor() {}

  public getCurrentMediaQuery() {
    return this.mediaQueryService.getCurrentMediaQuery();
  }
}
