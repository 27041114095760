<button
  *ngFor="let btn of buttonItems; let i = index"
  [class]="'action-button ' + (btn.class ? btn.class : 'btn-plain')"
  attr.aria-label="{{ !btn.label ? ('ARIA.' + btn.icon | translate) : undefined }}"
  [attr.icon-type]="btn.icon"
  (click)="onActionClick($event, btn)"
>
  <ng-container *ngIf="btn.icon === actions.BOOKMARK && isBookmarked; else defaultIcon">
    <ng-container *ngTemplateOutlet="iconTpl; context: { icon: 'bookmark-flat' }"></ng-container>
  </ng-container>
  <ng-template #defaultIcon>
    <ng-container *ngTemplateOutlet="iconTpl; context: { icon: btn.icon }"></ng-container>
  </ng-template>
  <span *ngIf="btn.label" class="action-label" [ngClass]="btn.labelClass || ''">{{ btn.label | translate }}</span>
</button>
<ng-template #iconTpl let-icon="icon">
  <span class="icon mb-icon-{{ icon }} mb-icon"></span>
</ng-template>
