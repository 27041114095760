<div class="backdrop" (click)="closeSeachOverlay()"></div>
<wb-input
  #wbInput
  type="text"
  name="search-field"
  inputmode="search"
  [value]="inputValue"
  (wbinput)="setInputValue($event)"
  (keyup.enter)="goToSearchPage()"
>
  <span slot="label">{{ 'SEARCH_FOR' | translate }}</span>
  <wb-input-action slot="actions" *ngIf="inputValue">
    <wb-round-button size="s" (click)="clearInputValue()">
      <wb-icon name="bds/close/24"></wb-icon>
    </wb-round-button>
  </wb-input-action>
  <wb-input-action slot="actions" (click)="goToSearchPage()">
    <wb-round-button size="s">
      <wb-icon name="bds/search/24"></wb-icon>
    </wb-round-button>
  </wb-input-action>
</wb-input>
