import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ToastContentHostDirective, ToastComponent } from './toast.component';
import { ToastService } from './toast.service';

@NgModule({
  declarations: [ToastComponent, ToastContentHostDirective],
  imports: [CommonModule, TranslateModule],
  bootstrap: [],
})
export class ToastModule {
  static forRoot(): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [ToastService, ToastContentHostDirective],
    };
  }
}
