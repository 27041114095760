import { Injectable } from '@angular/core';
import { MediaImageInterface } from '@modules/media/models/media-image.model';
import { EventDataInterface } from '@modules/section/models/event.model';
import { TranslateService } from '@ngx-translate/core';

import { ImageMetaResponse, MediaAdapter, MediaImageResponse } from './media.adapter';

@Injectable({
  providedIn: 'root',
})
export class EventAdapter {
  constructor(private mediaAdapter: MediaAdapter, private translateService: TranslateService) {}

  public parse(data: any): EventDataInterface {
    let image: MediaImageResponse | null = null;

    let slug = '';
    const currentLang = this.translateService.currentLang.split('-')[0];
    if (data.page?.slug) {
      slug = (data.page.slug.find(({ lang }: any) => lang === currentLang)?.value || data.page.slug[0]?.value).replace(
        'page/',
        ''
      );
    }
    if (data.event_page?.slug) {
      slug = (
        data.event_page.slug.find(({ lang }: any) => lang === currentLang)?.value || data.event_page.slug[0]?.value
      ).replace('event-page/', '');
    }

    if (data.image) {
      const _image = data.image;
      const { title } = data.relationships.image?.meta as unknown as ImageMetaResponse;
      image = {
        id: _image.id,
        title: title,
        caption: title,
        description: title,
        alternate_images: [],
        image: data.image,
        name: title,
        type: 'files',
        relationships: {
          image: {
            id: _image.id,
            meta: data.relationships.image?.meta as unknown as ImageMetaResponse,
          },
        },
      };
    }
    return {
      id: data.id,
      title: data.name,
      ermId: data.erm_id,
      carlId: data.ermv3_id,
      city: data.city,
      country: data.country,
      embargos: data.embargos,
      embargoDate: data.embargos[0],
      startDate: data.start_date,
      endDate: data.end_date,
      image: image ? (this.mediaAdapter.parse(image) as MediaImageInterface) : null,
      subtitle: data.sub_title,
      timezoneOffset: data.timezone_offset,
      type: data.event_type,
      attendances: [],
      deadline: data.embargos[0],
      hashtag: data.hashtag,
      lat: data.location?.lat,
      lng: data.location?.lng,
      titleHash: data.hashtag,
      urlSlug: slug,
    };
  }
}
