import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const noCopyright = 'NOASSERTION';

type Component = {
  name: string;
  version: string;
  licenseName: string;
  licenseID: string;
  copyright: string;
};
type Meta = {
  title: string;
  description: string;
};
type License = {
  text: string;
  name: string;
  id: string;
};
export type ComponentLicense = {
  components: Component[];
  meta: Meta;
  licenses: License[];
};

@Injectable({
  providedIn: 'root',
})
export class LicenseNoticeService {
  private componentLicenses: ComponentLicense = {
    components: [],
    meta: { title: '', description: '' },
    licenses: [],
  };

  constructor(private http: HttpClient) {}

  public load(): Observable<ComponentLicense> {
    return this.http.get<ComponentLicense>('./assets/component-licenses.json').pipe(
      map((data: ComponentLicense) => {
        const components = data.components?.filter((d) => d.copyright !== noCopyright);
        this.componentLicenses = {
          meta: data.meta,
          licenses: data.licenses,
          components: components,
        };
        return data;
      })
    );
  }

  public getData(): ComponentLicense {
    return this.componentLicenses;
  }
}
