import { LinkTypes } from '@modules/card/enums/link-types.enum';
import { TargetType } from '@modules/card/enums/target-type.enum';
import { CardLinkInterface } from '@modules/card/interfaces/card-link.interface';
import { MediaImageInterface, MediaImageModel } from '@modules/media/models/media-image.model';

import { Attendance, EventAttendanceStatus } from './attendance.model';

// list of known event types
export enum EventTypes {
  TEST_DRIVE = 'testdrive',
  WORLDPREMIERE = 'worldpremiere',
  PRESS_EVENT = 'pressevent',
  TRADE_SHOW = 'tradeshow',
  DRIVING_EVENT = 'Fahrveranstaltung',
  WORLD_PREMIERE = 'Weltpremiere',
  FAIR = 'Messe',
  TEC_DAY = 'TecDay',
  HUB = 'Hub',
  OTHER = 'Sonstige',
}

// storage for the different states an event can be in
export enum EventStates {
  UPCOMING = 'upcoming',
  RUNNING = 'running',
  PAST = 'past',
}

export interface EmbargoObject {
  datetime: Date;
  text: String;
}

export interface EventDataInterface {
  id: string | number;
  ermId?: string;
  carlId?: string;
  type: EventTypes;
  title: string;
  titleHash?: string;
  subtitle: string | null;
  urlSlug?: string;
  lat?: number;
  lng?: number;
  city: string;
  country: string;
  embargoDate: number;
  embargos: EmbargoObject[] | null;
  startDate: number;
  endDate: number;
  timezoneOffset: string; // UTC, GMT, etc..
  hashtag?: string;
  deadline?: number;
  attendances?: Attendance[];
  attendanceStatus?: EventAttendanceStatus;
  image: MediaImageInterface | null;
}

/**
 * Model used by the content-api service which represents a single event
 */
export class EventModel {
  public id: string | number;
  public ermId?: string;
  public carlId?: string;
  public type: EventTypes = EventTypes.WORLDPREMIERE;
  public title: string;
  public titleHash?: string;
  public subtitle: string | null;
  public urlSlug: string | undefined;
  public lat?: number;
  public lng?: number;
  public city: string;
  public country: string;
  public embargoDate: Date | null;
  public embargos: EmbargoObject[] | null;
  public endDate?: Date;
  public timezoneOffset: string;
  public hashtag?: string;
  public deadline?: Date;
  public attendances?: Attendance[];
  public attendanceStatus?: EventAttendanceStatus;
  public image: any;
  public startDate: Date;
  public link?: CardLinkInterface;

  constructor(data: EventDataInterface) {
    // cast types which are wrong in the API
    this.id = data.id;
    this.ermId = data.ermId;
    this.carlId = data.carlId;
    this.title = data.title;
    this.titleHash = data.titleHash;
    this.urlSlug = data.urlSlug;
    this.city = data.city;
    this.country = data.country;
    this.embargos = data.embargos;
    this.timezoneOffset = data.timezoneOffset;
    this.subtitle = data.type === 'testdrive' ? 'testdrive' : null;
    this.attendanceStatus = data.attendanceStatus;
    if (data.lat) {
      this.lat = data.lat;
    }
    if (data.lng) {
      this.lng = data.lng;
    }
    this.image = data.image ? new MediaImageModel(data.image) : null;

    this.embargoDate = data.embargoDate ? new Date(data.embargoDate) : null;
    this.startDate = new Date(data.startDate || Date.now());
    this.endDate = data.endDate ? new Date(data.endDate) : undefined;

    // TODO: move this into api-parser / api-service
    if (data.attendances?.length) {
      this.attendances = data.attendances.map((attendance: any) => new Attendance(attendance));
    }
    if (this.urlSlug) {
      this.link = {
        type: this.urlSlug.startsWith('https://') ? LinkTypes.EXTERNAL : LinkTypes.ROUTER,
        url: this.urlSlug,
        query: '',
        target: TargetType.SELF,
      };
    }
  }

  get state(): EventStates | null {
    if (!this.startDate || !this.endDate) {
      return null;
    }
    const now = new Date();
    if (this.startDate >= now) {
      return EventStates.UPCOMING;
    } else if (this.startDate <= now && this.endDate && this.endDate >= now) {
      return EventStates.RUNNING;
    } else if (this.startDate < now) {
      return EventStates.PAST;
    }
    return null;
  }

  get location(): string {
    return [this.city, this.country].filter((v) => v).join(', ');
  }

  get personalizedStartDate(): Date {
    if (this.attendances && this.attendances.length > 0 && this.attendances[0].arrival) {
      return this.attendances[0].arrival;
    } else {
      return this.startDate;
    }
  }

  get personalizedEndDate(): Date {
    if (this.attendances && this.attendances.length > 0 && this.attendances[this.attendances.length - 1].departure) {
      return this.attendances[this.attendances.length - 1].departure as Date;
    } else {
      return this.endDate as Date;
    }
  }
}
