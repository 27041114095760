import { Injectable } from '@angular/core';
import { FavoriteDataInterface } from '@modules/favorites/interfaces/favorite-data.interface';
import { FavoritesContentService } from '@modules/favorites/services/favorites-content.service';
import { FavoritesService } from '@modules/favorites/services/favorites.service';
import { ANALYTIC_EVENTS, TrackingService } from '@modules/shared/services/tracking.service';
import { map } from 'rxjs/operators';

interface SetCaptureInterface {
  activeCanvas: number;
  time: number;
  screenshotUrl: string;
  offset: number;
  context: {
    id: string;
    type: string;
  };
}
@Injectable({ providedIn: 'root' })
export class CaptureService {
  constructor(
    private favoritesService: FavoritesService,
    private favoritesContentService: FavoritesContentService,
    private trackingService: TrackingService
  ) {}

  public setCapture(data: SetCaptureInterface) {
    const id = `${Date.now()}`;
    const frame = Math.round(data.time * 10) + data.offset || 1;
    const captureData: FavoriteDataInterface = {
      id,
      type: 'capture',
      context: data.context,
      data: {
        activeCanvas: data.activeCanvas,
        time: data.time,
        originalTime: data.time,
        downloadUrl: `${data.screenshotUrl}original/video${data.activeCanvas + 1}/${frame}`,
      },
    };
    this.favoritesService.add({ favoriteData: captureData }, false);
    this.trackingService.push(location.href, {
      events: ANALYTIC_EVENTS.SCREEN_CAPTURE,
    });
    return this.favoritesContentService.favorites$.pipe(
      map((favorites) => {
        return favorites.find((item) => item.id === id);
      })
    );
  }
}
