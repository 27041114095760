import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  HostBinding,
} from '@angular/core';
import { FavoriteDataContextInterface } from '@modules/favorites/interfaces/favorite-data-context.interface';
import { FullscreenService } from '@modules/media/fullscreen.service';
import { CaptureModel } from '@modules/media/models/capture.model';
import { CaptureService } from '@modules/media/service/capture.service';
import { Option } from '@modules/ui/interfaces/option.interface';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { MediaVideoModel, VideoStatus } from '../../models/media-video.model';
import { PlayerModel } from '../../models/player.model';
import { PlayerService } from '../../service/player.service';

@Component({
  selector: 'mb-video-controls',
  templateUrl: './video-controls.component.html',
  styleUrls: ['./video-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoControlsComponent implements OnInit, OnDestroy {
  @HostBinding('class.small-controls')
  @Input()
  smallControls = false;

  @HostBinding('class.show-controls')
  @Input()
  showControls: null | boolean = false;

  @Input()
  isEmbedPlayer = false;

  private destroy$ = new Subject<boolean>();
  public resolutionsOpen = false;

  public videoStatusStates = VideoStatus;
  public progress = 0;

  private _tracks: any[] = [];

  public isIosDevice = false;

  isFullscreen = false;
  @Input() media!: MediaVideoModel;
  @Input() player!: PlayerModel;

  @Input() tracks: Option[] = [];
  @Input() currentLang = 'en-US';
  @Input() allowPictureInPicture = true;
  @Input() showScreenshotButton = false;

  @Output() capture = new EventEmitter<CaptureModel>();

  constructor(
    private playerService: PlayerService,
    private captureService: CaptureService,
    private fullscreenService: FullscreenService
  ) {
    this.isIosDevice = !!this.isIos();
  }
  private isIos() {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      return true;
    } else {
      return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
    }
  }

  changeTrack(track: any) {
    this._tracks.forEach((_track) => {
      _track.enabled = _track.id === track.value;
    });
  }

  toggleFlyout() {
    if (this.player.flyoutRef) {
      this.playerService.destroyFlyout(true);
      return;
    }
    this.playerService.createFlyout(this.player);
  }

  public emitCapture() {
    const sub = new Subject<boolean>();
    this.captureService
      .setCapture({
        activeCanvas: this.player.position,
        time: this.player.currentTime,
        screenshotUrl: this.player.playerData.screenshotUrl,
        offset: this.player.playerData.screenshotOffset,
        context: this.player.playerData.favoriteData?.context as FavoriteDataContextInterface,
      })
      .pipe(takeUntil(sub))
      .subscribe((data) => {
        if (data) {
          sub.next(true);
          sub.complete();
          data.data$.pipe(take(1)).subscribe((d) => {
            this.capture.emit(d.data);
          });
        }
      });
    // this.capture.emit(capture);
  }

  ngOnInit() {
    this.fullscreenService.change$.pipe(takeUntil(this.destroy$)).subscribe(({ isFullscreen }) => {
      this.isFullscreen = isFullscreen;
    });

    this.player.audioTracks$.pipe(takeUntil(this.destroy$)).subscribe((tracks: any[]) => {
      this._tracks = tracks;
      const activeTrack = tracks.find((track) => track.enabled);
      if (activeTrack) {
        this.currentLang = activeTrack.id;
      }
      this.tracks = tracks.map((track) => {
        return {
          label: track.label,
          value: track.id,
        };
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
