import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'mb-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPreviewComponent {
  @Input()
  set url(v: any) {
    const x = this.sanitizer.bypassSecurityTrustResourceUrl(v);
    this._url = x;
  }
  get url() {
    return this._url;
  }

  @Input() title!: string;

  private _url!: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}
}
