
export const environment = {
  production: true,
  mediaConfig: 'daimleragmemediabuild',
  apiBaseUrl:'https://api.media.mercedes-benz.com',
  mediaDeliveryUrl:'https://api.media.mercedes-benz.com/jsonapi/image/deliver',
  embedUrl:'https://stream.media.mercedes-benz.com',
  loginUrl:'https://login.media.mercedes-benz.com',
  CSRFTokenHeader:'X-MEMEDIA-CSRF-TOKEN',
  mapApiKey:'KLTSv_7zNkdeNbsBgsleldR_jbqQdskFGUN_8L3O_kg',
  sitesToTrack:["media.mercedes-benz.com", "stream.media.mercedes-benz.com"],
  connectionString:'InstrumentationKey=94c74201-d137-4390-96d6-f894ab802c09;IngestionEndpoint=https://westeurope-4.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=0b07d05a-de75-4ca2-abfa-dc3d260ee46f',
  frontendUrl:'https://media.mercedes-benz.com'
};
