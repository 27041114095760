import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Optional } from 'src/helpers/types';

import { EventDataLayer, PageDataLayer, TrackingItem } from '../gtm/data-layer.types';

import { BreadcrumbService } from './breadcrumb.service';
import { UserService } from './user.service';

export type TrackableContent = {
  trackingData?: TrackingItem | undefined; // making this optional to avoid having to refactor the code base too much
};

const GTM_ID = 'GTM-P9B6NF8';

/**
 * GTM and GA4 data layer service
 */
@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private hasPageViewEvent = false; // initialize GTM only after the first pageView event
  private dataLayer: any[] = [];

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private breadcrumbService: BreadcrumbService,
    private title: Title
  ) {
    if (window) {
      // use existing dataLayer or create a new one
      (window as any).dataLayer = (window as any).dataLayer || [];
      this.dataLayer = (window as any).dataLayer;
    }
  }

  public pushEvent(data: EventDataLayer) {
    this.dataLayer.push(data);
  }

  // IMPROVE: automatically trigger a page view after routing (requires to pass all relevant data to the route data)
  //          reduces code duplication and manual calls in "page" components
  public pushPageView(page: Optional<PageDataLayer, 'url' | 'urlSlug' | 'originalUrl' | 'categoryTree' | 'title'>) {
    if (!page.originalUrl) {
      page.originalUrl = window.location.href;
    }
    page.url = this.cleanedUrl(page.originalUrl);
    page.urlSlug = new URL(page.url).pathname;
    page.categoryTree = this.breadcrumbService.getBreadcrumbString(' > ');
    page.title = this.title.getTitle();
    page.publishDate = page.publishDate ? new Date(page.publishDate).toISOString().split('T')[0] : undefined;

    this.dataLayer.push({
      event: 'pageview',
      page,
      language: this.translateService.currentLang?.split('-')[0],
      user: {
        loginState: this.userService.isLoggedInSync() ? 'logged_in' : 'logged_out',
      },
    });

    if (!this.hasPageViewEvent) {
      // first page view event, initialize GTM
      this.initializeGTM();
      this.hasPageViewEvent = true;
    }
  }

  /**
   * Initializes GTM.js script
   * Note: We are not simply using a script tag in the <head> of the index.html
   *       in order to ensure having pageview data available for automatic / impression events
   */
  private initializeGTM() {
    this.dataLayer.push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    });

    const scriptElement = document.createElement('script');
    scriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`;
    scriptElement.type = 'text/javascript';
    scriptElement.async = true;
    scriptElement.id = 'gtm-js';
    document.getElementsByTagName('head')[0].appendChild(scriptElement);
  }

  /**
   * Removes all extra query parameters except campaign parameters from url
   * @param url
   * @returns cleaned url
   */
  private cleanedUrl(originalUrl: string) {
    const url = new URL(originalUrl);
    const params = url.searchParams;
    const campaignParams = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
      'gclid',
      'dclid',
      '_gl',
      '_ga',
    ];
    params.forEach((_value, key) => {
      if (!campaignParams.includes(key)) {
        params.delete(key);
      }
    });
    return url.toString();
  }
}
