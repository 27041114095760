/**
 * Generate a fullname
 */
export const fullname = (firstname?: string | null, lastname?: string | null) =>
  [firstname, lastname].filter(Boolean).join(' ');

export const filetype = (filename?: string | null) => filename?.split('.').pop()?.toUpperCase() ?? '';

export const filetypeFromMime = (mimeType: string) => {
  const mimeTypeMap: { [k: string]: string } = {
    'text/html': 'html',

    'image/gif': 'gif',
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/tiff': 'tiff',
    'image/svg+xml': 'svg',
    'image/webp': 'webp',

    'application/msword': 'doc',
    'application/pdf': 'pdf',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/zip': 'zip',

    'audio/midi': 'midi',
    'audio/mpeg': 'mp3',
    'audio/ogg': 'ogg',

    'video/mpeg': 'mpeg',
    'video/quicktime': 'mov',
    'video/x-flv': 'flv',
    'video/x-ms-wmv': 'wmv',
    'video/x-msvideo': 'avi',
    'video/mp4': 'mp4',
  };

  return mimeTypeMap[mimeType]?.toUpperCase() ?? '';
};

export const co2ToUnicode = (json: any): any =>
  JSON.parse(JSON.stringify(json).replaceAll(/CO2|CO<sub>2<\/sub>/gi, 'CO₂'));

export const htmlDecode = (input: string) => {
  let doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};
