import { Screen } from '@modules/shared/services/window.service';

import { MediaSetting } from '../media-setting.interface';

export const DefaultCardImageStyle: MediaSetting[] = [
  { screen: Screen.XXXS, key: '4_3_800', width: 800, height: 600, ratio: 1.3 },
  { screen: Screen.SM, key: '4_3_400', width: 400, height: 300, ratio: 1.3 },
];

export const ContactCardImageStyle: MediaSetting[] = [
  // this images are not all cut the same way, so we just assume square
  // these are rendered as 50x50 circles
  { screen: Screen.XXXS, key: '200', width: 200, height: 200, ratio: 1 },
];

export const SquareCardImageStyle: MediaSetting[] = [
  { screen: Screen.XXXS, key: '1_1_800', width: 800, height: 800, ratio: 1.3 },
  { screen: Screen.SM, key: '1_1_400', width: 400, height: 400, ratio: 1.3 },
];

export const FavoriteCardImagesStyle: MediaSetting[] = [
  { screen: Screen.XXXS, key: '2_1_768', width: 768, height: 384, ratio: 2 },
  { screen: Screen.SM, key: '3_2_768', width: 768, height: 512, ratio: 1.5 },
];
