import { ErrorHandler, Injectable, Injector, isDevMode } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

@Injectable({ providedIn: 'root' })
export class AppErrorHandler implements ErrorHandler {
  private appInsightsErrorHandler: ApplicationinsightsAngularpluginErrorService | null = null;

  constructor(private injector: Injector) {
    this.appInsightsErrorHandler = this.injector.get(ApplicationinsightsAngularpluginErrorService);
  }

  /**
   * Custom error handler
   * DEV: log error to console
   * PROD: report error to backend
   * @param error
   */
  handleError(error: any) {
    if (isDevMode()) {
      console.error(error);
    } else {
      if (this.appInsightsErrorHandler) {
        this.appInsightsErrorHandler.handleError(error);
      } else {
        // Fallback logging
        console.error(error);
        // Add additional fallback error reporting logic here
      }
    }
  }
}
