import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { PlayerModel } from '@modules/media/models/player.model';
import { PlayerService } from '@modules/media/service/player.service';

@Component({
  selector: 'mb-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPreviewComponent implements OnInit, OnDestroy {
  @ViewChild('videoWrapperEl', { static: true }) videoWrapperEl!: ElementRef;

  private seekTimeout: any = null;
  public isSeeking = false;
  public thumbUrl!: string;

  @Input() player!: PlayerModel;

  private previewPlayer!: PlayerModel;

  @Input()
  set position(v: number) {
    if (this.previewPlayer) {
      this.previewPlayer.setPosition(v);
    }
  }

  @Input()
  set currentTime(v: number) {
    if (v <= 0) {
      v = 1;
    }
    if (this.previewPlayer) {
      if (this.seekTimeout) {
        clearTimeout(this.seekTimeout);
      }
      this.seekTimeout = setTimeout(() => {
        this.previewPlayer.setTime(v);
      }, 50);
    }
    this.setCapture(v);
  }

  constructor(
    private elRef: ElementRef,
    private playerService: PlayerService,
    private render: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  public setCapture(time: number) {
    if (this.player && this.player.playerData.screenshotUrl) {
      const playerData = this.player.playerData;
      const frame = Math.ceil(time * 10 + playerData.screenshotOffset);
      this.thumbUrl = `${playerData.screenshotUrl}thumb/video${this.player.position + 1}/${frame}`;
    }
  }

  ngOnInit(): void {
    this.elRef.nativeElement.style.setProperty('--mb-video-preview-ratio', this.player.playerData.ratio);
    if (!this.player.playerData.screenshotUrl) {
      // use 2nd player to fake preview images, if no screenshotUrl is set
      this.previewPlayer = this.playerService.createPlayer(this.player.playerData);
      this.render.appendChild(this.videoWrapperEl.nativeElement, this.previewPlayer.videoEl);

      this.previewPlayer.videoEl.addEventListener(
        'seeking',
        () => {
          this.isSeeking = true;
          this.cdr.detectChanges();
        },
        { passive: true }
      );
      this.previewPlayer.videoEl.addEventListener(
        'seeked',
        () => {
          this.isSeeking = false;
          this.cdr.detectChanges();
        },
        { passive: true }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.previewPlayer) {
      this.previewPlayer.player.dispose();
    }
  }
}
