export enum EventAttendanceStatus {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled',
  REPRESENTATIVE = 'representative',
  EXPIRED = 'expired',
  DECLINED = 'declined',
  HAS_SUBSTITUTE = 'has_substitute',
  OPEN = 'open',
}

export class Attendance {
  public id: string;
  public status: EventAttendanceStatus;
  public arrival?: Date | null;
  public departure?: Date | null;
  public participations: Date[] = [];
  public registrationDeadline?: Date | null;

  constructor(data: any = {}) {
    this.id = data.id;
    this.status = data.status;
    this.arrival = data.arrival ? new Date(data.arrival) : null;
    this.departure = data.departure ? new Date(data.departure) : null;
    this.registrationDeadline = data.registrationDeadline ? new Date(data.registrationDeadline) : null;
    if (data.participations) {
      this.participations = data.participations.map((participation: string) => new Date(participation));
    }
  }
}
