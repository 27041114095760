import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardModule } from '@modules/card/card.module';
import { MediaModule } from '@modules/media/media.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { UiModule } from '@modules/ui/ui.module';
import { TranslateModule } from '@ngx-translate/core';

import { FavoriteCardComponent } from './favorite-card/favorite-card.component';
import { FavoritesModalComponent } from './favorites-modal/favorites-modal.component';

@NgModule({
  declarations: [FavoritesModalComponent, FavoriteCardComponent],
  imports: [CommonModule, CardModule, PipesModule, UiModule, TranslateModule, MediaModule],
  exports: [FavoritesModalComponent],
})
export class FavoritesModule {}
