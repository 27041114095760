import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { MediaImageModel } from './models/media-image.model';
import { MediaVideoModel } from './models/media-video.model';
import { MediaType } from './models/media.types';
import { PlayerType } from './video/video.component';

@Component({
  selector: 'mb-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaComponent {
  @Input() autoplay = false;
  @Input() hoverplay = false;
  @Input() muted = false;
  @Input() controls = true;
  @Input() loop = false;
  @Input() hideTitles = false;
  @Input() playerType: PlayerType = PlayerType.SIMPLE;
  @Input() playsInline = false;

  @Input() media?: MediaImageModel | MediaVideoModel;

  @Output() imageError = new EventEmitter();

  public mediaType = MediaType;
}
