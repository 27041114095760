<mb-scrubber
  (progressChanging)="player.setTime($event)"
  (progressChanged)="player.setTime($event)"
  (hoverValue)="hoverSeek($event)"
  [hoverTpl]="hoverTpl"
  [max]="player.duration"
  [showScrubTarget]="true"
  [value]="(player.currentTime$ | async) || 0"
>
</mb-scrubber>

<ng-template #hoverTpl let-value="value">
  <div class="video-scrubber-overlay">
    <mb-video-preview [player]="player" [position]="player.position" [currentTime]="seekVal"></mb-video-preview>
    <ng-container *ngIf="player.duration$ | async as duration">
      <mb-duration [currentTime]="value"></mb-duration>
    </ng-container>
  </div>
</ng-template>
