<ng-container *ngIf="options?.length">
  <div class="dropdown-trigger" (click)="open($event)" [class.is-open]="isOpen">
    <span *ngIf="icon" class="dropdown-icon mb-icon {{ icon }}"></span>
    <span *ngIf="!hideLabel" class="dropdown-label">
      <ng-container *ngIf="labelTpl; else defaultLabelTpl">
        <ng-container *ngTemplateOutlet="labelTpl"></ng-container>
      </ng-container>
      <ng-template #defaultLabelTpl>{{ valueLabel || options[0].label | translate }}</ng-template>
    </span>
    <span
      *ngIf="!hideLabel"
      class="dropdown-indicator mb-icon mb-icon-down"
      [class.is-open-top]="openTop"
      [class.invert]="isOpen"
    ></span>
  </div>

  <ng-container *ngIf="!useNativeDropdown">
    <div class="dropdown-container" [class.open-top]="openTop" [class.is-open]="isOpen">
      <div
        *ngFor="let option of options"
        class="dropdown-option"
        (click)="optionSelected(option)"
        [class.active]="option.value === value"
      >
        {{ option.label | translate: option.translationData || {} }}
      </div>
    </div>
  </ng-container>

  <select
    *ngIf="useNativeDropdown"
    class="maximize-content dropdown-native-container"
    #selectEl
    (change)="nativeOptionSelect(selectEl.value)"
  >
    <option
      class="mb-dropdown-option"
      *ngFor="let option of options"
      [value]="option.value"
      [selected]="option.value === value"
    >
      {{ option.label | translate: option.translationData || {} }}
    </option>
  </select>
</ng-container>
