import { FavoriteDataContextInterface } from '../interfaces/favorite-data-context.interface';
import { FavoriteDataInterface } from '../interfaces/favorite-data.interface';

const ALIASES: any = {
  structured_image: 'structured_image',
};
export class FavoriteToApiAdapter {
  public static parse(content: FavoriteDataInterface) {
    if (content.type === 'article') {
      return {
        type: 'favourite',
        attributes: {
          favourite_type: 'article',
        },
        relationships: {
          article_context: {
            data: {
              id: content.id,
              type: 'article',
            },
          },
        },
      };
    } else if (content.type === 'capture') {
      return {
        type: 'favourite',
        attributes: {
          favourite_type: 'capture',
          video_time: content.data.originalTime || 1,
          current_time: content.data.time || 1,
          download_url: content.data.downloadUrl,
          camera_id: content.data.activeCanvas + 1,
        },
        relationships: this.getContext(content.context),
      };
    } else if (content.type === 'presskit') {
      return {
        type: 'favourite',
        attributes: {
          favourite_type: 'presskit',
        },
        relationships: {
          presskit_context: {
            data: {
              id: content.id,
              type: 'presskit',
            },
          },
        },
      };
    }
    return {
      type: 'favourite',
      attributes: {
        favourite_type: ALIASES[content.type] || content.type,
      },
      relationships: Object.assign(
        {
          media: {
            data: {
              id: content.id,
              type: ALIASES[content.type] || content.type,
            },
          },
        },
        this.getContext(content.context)
      ),
    };
  }

  private static getContext(context?: FavoriteDataContextInterface | null) {
    if (!context) {
      return {};
    }
    if (context.type === 'articles') {
      return {
        article_context: {
          data: {
            id: context.id,
            type: 'article',
          },
        },
      };
    } else if (context.type === 'pages') {
      return {
        page_contexts: {
          data: {
            id: context.id,
            type: 'page',
          },
        },
      };
    } else if (context.type === 'streams') {
      return {
        stream_context: {
          data: {
            id: context.id,
            type: 'stream',
          },
        },
      };
    }
    return {};
  }
}
