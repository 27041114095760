import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { FullscreenService } from '@modules/media/fullscreen.service';
import { Screen, WindowService } from '@modules/shared/services/window.service';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ToastInterface } from './interfaces/toast.interface';
import { ToastComponent } from './toast.component';

const FIRST_TOAST_DEFAUT_DESKTOP_TOP = 20;
const FIRST_TOAST_FAVORTIES_MODAL_DESKTOP_TOP = 45;

@Injectable()
export class ToastService {
  public closeLightbox$ = new Subject<boolean>();

  private _header: any;
  private toasts: { key: string; component: ComponentRef<ToastComponent> }[] = [];
  private activeFullScreenEl: HTMLElement | null = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private windowService: WindowService,
    private fullscreenService: FullscreenService
  ) {
    this.windowService.isHeaderMinimized$.subscribe(() => this.updatePositions());

    this.fullscreenService.change$.subscribe(({ element }) => {
      if (element) {
        this.activeFullScreenEl = element;
        this.moveToFullscreenEl();
      } else {
        this.activeFullScreenEl = null;
        this.moveBackToParent();
      }
    });
  }

  public moveToFullscreenEl() {
    this.toasts.forEach((toast) => {
      this.activeFullScreenEl?.appendChild(toast.component.instance.elRef.nativeElement);
    });
    this.updatePositions();
  }

  private moveBackToParent() {
    this.toasts.forEach((toast) => {
      const parent = toast.component.instance.parent || document.body;
      parent.appendChild(toast.component.instance.elRef.nativeElement);
    });
    this.updatePositions();
  }

  public open(data: ToastInterface, parent: HTMLElement = document.body) {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(ToastComponent).create(this.injector);

    componentRef.instance.fullContent = !!data.fullContent;
    componentRef.instance.whiteCloseBtn = !!data.whiteCloseBtn;
    componentRef.instance.title = data.title || '';
    componentRef.instance.text = data.text || '';
    componentRef.instance.parent = parent;
    componentRef.instance.trigger = data.trigger;

    componentRef.instance.component = data.component;
    componentRef.instance.componentData = data.componentData || {};
    componentRef.instance.componentEvents = data.componentEvents || {};
    componentRef.instance.ref = componentRef;

    const key = `${Date.now()}`;
    this.toasts.push({
      key,
      component: componentRef,
    });
    componentRef.instance.destroy$.pipe(take(1)).subscribe(() => {
      this.removeToastByKey(key);
      this.updatePositions();
    });
    this.appRef.attachView(componentRef.hostView);
    setTimeout(() => {
      componentRef.instance.elRef.nativeElement.classList.add('show');
    }, 20); // add delay in case a media is shown in the capture and the height / width needs to be calculated

    while (this.toasts.length > 4) {
      this.removeToastByKey(this.toasts[0].key);
    }
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    (this.activeFullScreenEl || parent).appendChild(domElem);
    this.updatePositions();
    return key;
  }

  public getToastByKey(key: string): ComponentRef<ToastComponent> | null {
    const index = this.toasts.findIndex((item) => item.key === key);
    if (index > -1) {
      return this.toasts[index].component;
    }
    return null;
  }

  public removeToastByKey(key: string): boolean {
    const index = this.toasts.findIndex((item) => item.key === key);
    if (index > -1) {
      this.toasts[index].component.instance.close();
      this.toasts.splice(index, 1);
      return true;
    }
    return false;
  }

  public updatePositions() {
    setTimeout(() => {
      // run in timeout to make sure the toast are build and the height can be readed
      let total = 0;
      const isMobile = this.windowService.screen <= Screen.XS; // below SM
      const list = isMobile ? [...this.toasts].reverse() : this.toasts;
      list.forEach((t, index) => {
        const el = t.component.instance.elRef.nativeElement;
        const elHeight = el.clientHeight;
        total += isMobile ? 2 : index === 0 ? this.getFirstTopPosition() : 10;
        t.component.instance[isMobile ? 'bottom' : 'top'] = total;
        t.component.instance[!isMobile ? 'bottom' : 'top'] = null;
        total += elHeight;
      });
    }, 10);
  }

  private getFirstTopPosition() {
    if (this.activeFullScreenEl) {
      return FIRST_TOAST_DEFAUT_DESKTOP_TOP;
    } else if (document.querySelector('mb-favorites-modal')) {
      return FIRST_TOAST_FAVORTIES_MODAL_DESKTOP_TOP;
    } else if (!this._header) {
      this._header = document.querySelector('mb-header');
      if (!this._header) {
        return FIRST_TOAST_DEFAUT_DESKTOP_TOP;
      }
    }
    let topToSubstract = this._header.classList.contains('shrinked') ? 45 : 0;
    if (this._header.classList.contains('has-subnav')) {
      topToSubstract -= 40;
    }
    const rect = this._header.getBoundingClientRect();
    const top = rect.height - topToSubstract;
    return top + FIRST_TOAST_DEFAUT_DESKTOP_TOP;
  }
}
