<iframe class="document-object" [src]="url" width="100%" height="100%" style="border: none"></iframe>

<div class="document-mobile-view">
  <div class="document-icon-wrapper">
    <span class="document-icon mb-icon mb-icon-documents-detail"></span>
    <span class="document-icon-label">pdf</span>
  </div>
  <div *ngIf="title" class="document-title-wrapper">
    <a [href]="url" target="_plank" class="document-title" [innerHTML]="title"> </a>
  </div>

  <a [href]="url" class="btn btn-secondary-dark">
    <span class="mb-icon mb-icon-link-out"></span>
    <span class="btn-label">{{ 'DOCUMENT_OPEN' | translate }}</span>
  </a>
</div>
