import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  effect,
} from '@angular/core';
import { DialogService, DialogState } from '@modules/shared/services/dialog.service';
import { LightboxService, LightboxState } from '@modules/shared/services/lightbox.service';
import { ModalService } from '@modules/shared/services/modal.service';
import { ShareModalService } from '@modules/shared/services/share.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MediaVideoModel } from '../models/media-video.model';
import { PlayerModel } from '../models/player.model';
import { PlayerService } from '../service/player.service';

const MOBILE_CONTROL_WIDTH = 768;
@Component({ template: '' })
export abstract class VideoDefaultsComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('videoParent', { static: false }) videoParent!: ElementRef;
  @Input() autoplay = false;
  @Input() muted = false;
  @Input() media!: MediaVideoModel;
  @Input() showBackButton = false;
  @Output() backButton = new EventEmitter();
  @Input() hideTitle = false;
  @Input() hideBottomTitle = false;
  @Input() loop = false;

  public afterDestroyHook: () => void = () => {};
  public afterInitHook: () => void = () => {};
  private observer!: MutationObserver;
  public destroy$ = new Subject<boolean>();

  public controlBrakpoints = [0, MOBILE_CONTROL_WIDTH];
  private mouseMoveTimeout: any = null;
  public showControls$ = new Subject<boolean>();
  public player!: PlayerModel;

  public smallControls = false;
  public hoverDelay = 4000;

  public onInitHook: () => void = () => {};

  private wasFullscreen = false;
  constructor(
    public shareService: ShareModalService,
    public lightboxService: LightboxService,
    public dialogService: DialogService,
    public modalService: ModalService,
    public cdr: ChangeDetectorRef,
    public playerService: PlayerService,
    public elRef: ElementRef,
    public renderer: Renderer2
  ) {
    effect(() => {
      this.checkFullscreenForOverlay(this.modalService.modalState() === 'open');
    });
    this.lightboxService.lightboxState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.checkFullscreenForOverlay(state === LightboxState.OPEN);
    });
    this.dialogService.dialogState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.checkFullscreenForOverlay(state === DialogState.OPEN);
    });
  }

  private checkFullscreenForOverlay(open: boolean) {
    if (!this.player) {
      return;
    }
    if (open) {
      if (this.player.isFullscreen) {
        this.wasFullscreen = true;
        this.player.toggleFullscreen(false);
      }
    } else {
      if (this.wasFullscreen) {
        this.wasFullscreen = false;
        this.player.toggleFullscreen(true);
      }
    }
  }

  showControls() {
    this.showControls$.next(true);
    if (this.mouseMoveTimeout) {
      clearTimeout(this.mouseMoveTimeout);
    }
    this.mouseMoveTimeout = setTimeout(() => {
      this.showControls$.next(false);
    }, this.hoverDelay);
  }

  checkControlWidth(width: any) {
    this.smallControls = width < MOBILE_CONTROL_WIDTH;
    this.cdr.detectChanges();
  }

  openShare() {
    this.shareService.openModal({
      shareData: {
        title: this.player.playerData.title,
        content: this.player.playerData.description,
        url: location.href,
      },
    });
  }

  checkAutoPlay() {
    if (this.autoplay && !this.muted) {
      /**
       * to autoplay a video, we have to set it to muted manually.
       * if the video was not intentionally muted, we unmute it again
       */
      setTimeout(() => {
        this.player.unmute();
      }, 100);
    }
  }

  ngOnInit(): void {
    this.player = this.playerService.createPlayer(this.media, {
      autoplay: this.autoplay,
      muted: this.muted,
      loop: this.loop,
    });
    this.player.setFullscreenTarget(this.elRef.nativeElement);
    this.afterInitHook();
    this.player.play();
  }

  ngAfterViewInit() {
    const videpParent = this.videoParent.nativeElement;
    /**
     * in safari on ios devices the player gets replaced with the
     * video player component of ios. this will result in misbehavior,
     * so we need to set the proper instance of the new video player element.
     */
    this.observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const videoEl = this.videoParent.nativeElement.querySelector('video');
          if (videoEl) {
            this.player.videoEl = videoEl;
            this.checkAutoPlay();
          }
        }
      }
    });
    this.observer.observe(videpParent, { childList: true });
    this.renderer.appendChild(this.videoParent.nativeElement, this.player.videoEl);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.observer.disconnect();
    this.player.stop();
    this.playerService.destroyFlyout();
    this.player.player.dispose();
    if (this.afterDestroyHook) {
      this.afterDestroyHook();
    }
  }
}
