<ng-container *ngIf="!vertical">
  <div *ngIf="showScrubTarget" class="scrub-target-bar"></div>
  <div class="controls-scrubber-bar" [style.width.%]="progress">
    <ng-container *ngTemplateOutlet="scrubberContentTpl"></ng-container>
  </div>
</ng-container>
<div *ngIf="vertical" class="controls-scrubber-bar" [style.height.%]="progress">
  <ng-container *ngTemplateOutlet="scrubberContentTpl"></ng-container>
</div>
<input
  class="controls-scrubber-range-control"
  (change)="scrubEnd()"
  (input)="scrubbing()"
  (mousemove)="onHoverValue($event)"
  (touchmove)="touch($event)"
  (touchstart)="touch($event)"
  (touchend)="scrubEnd()"
  (keydown)="keydown($event)"
  type="range"
  attr.aria-label="{{ 'ARIA.MEDIA_PROGRESS_BAR' | translate }}"
  [attr.aria-valuenow]="value"
  [attr.aria-valuetext]="round(progress) + '%'"
  aria-min="0"
  [attr.aria-max]="max"
  min="0"
  [max]="max"
  [value]="value"
/>

<ng-template #scrubberContentTpl>
  <div class="scrubber-content" #scrubberContent>
    <ng-container *ngIf="hoverTpl">
      <ng-container *ngTemplateOutlet="hoverTpl; context: { value: _hoverValue || value }"></ng-container>
    </ng-container>
  </div>
</ng-template>
