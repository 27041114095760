import { z } from 'zod';

interface NavigationMenuSchema {
  children?: NavigationMenuSchema[];
  depth: number;
  id: string;
  name: Record<string, string>;
  parents: string[];
  path: Record<string, string>;
  weight: string;
  show_topic_page?: boolean | null;
}

export const NavigationMenuSchema: z.ZodType<NavigationMenuSchema> = z.lazy(() =>
  z.object({
    children: z.array(NavigationMenuSchema).optional(),
    depth: z.number(),
    id: z.string(),
    name: z.object({ en: z.string(), de: z.string() }).transform((data) => ({ 'en-US': data.en, 'de-DE': data.de })),
    parents: z.string().array(),
    path: z.object({ en: z.string(), de: z.string() }).transform((data) => ({ 'en-US': data.en, 'de-DE': data.de })),
    weight: z.string(),
    show_topic_page: z.boolean().optional().nullable(),
  })
);

export type NavigationMenu = z.infer<typeof NavigationMenuSchema>;
