import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StreamAdapter, StreamCardResponse } from '@modules/content-api/adapter/stream.adapter';
import { ContentAPIService } from '@modules/content-api/content-api.service';
import { MediaStreamModel } from '@modules/media/models/media-stream.model';
import { map } from 'rxjs/operators';

const PLAYER_PAGE_PARAMS = new HttpParams().appendAll({
  include: ['background', 'player_config', 'player_config.transcript'].join(),
  'fields[media--structured_image]': 'image',
  'fields[media--alternate_images]': 'image',
});

@Injectable({
  providedIn: 'root',
})
export class PlayerPageService {
  constructor(private apiService: ContentAPIService, private adapter: StreamAdapter) {}

  public getStreamData(id: string) {
    return this.apiService.getResource('streams', id, PLAYER_PAGE_PARAMS).pipe(
      map((data) => {
        try {
          return new MediaStreamModel(this.adapter.parse(data as StreamCardResponse));
        } catch (error) {
          return null;
        }
      })
    );
  }
}
