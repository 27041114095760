import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollLockService {
  public isLocked: boolean = false;

  constructor() {}

  public lock(): void {
    document.body.style.overflow = 'hidden';
    this.isLocked = true;
  }

  public unlock(): void {
    if (!this.isLocked) {
      return;
    }
    document.body.style.overflow = '';
    this.isLocked = false;
  }
}
