import { DragDrop } from '@angular/cdk/drag-drop';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { FavoriteDataContextInterface } from '@modules/favorites/interfaces/favorite-data-context.interface';
import { ToastService } from '@modules/toast/toast.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { CaptureComponent } from '../capture/capture.component';
import { PlayerModel } from '../models/player.model';
import { CaptureService } from '../service/capture.service';
import { PlayerService } from '../service/player.service';

@Component({
  selector: 'mb-media-flyout',
  templateUrl: './media-flyout.component.html',
  styleUrls: ['./media-flyout.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
  encapsulation: ViewEncapsulation.None,
})
export class MediaFlyoutComponent implements OnInit, OnDestroy {
  @ViewChild('canvas') canvas!: ElementRef;

  @ViewChild('flyoutContainer', { static: true }) flyoutContainer!: ElementRef;

  @Input() player!: PlayerModel;
  private previousParent: any;

  public doc = window.document as unknown as HTMLElement;
  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private playerService: PlayerService,
    private captureService: CaptureService,
    private toastService: ToastService,
    private cdkDrag: DragDrop
  ) {
    const ref = this.cdkDrag.createDrag(this.elRef.nativeElement);
    ref.withBoundaryElement(document.body);
  }

  closeFlyout(continuePlaying = false) {
    this.playerService.destroyFlyout(continuePlaying);
  }

  capture() {
    const sub = new Subject<boolean>();
    this.captureService
      .setCapture({
        activeCanvas: this.player.position,
        time: this.player.currentTime,
        screenshotUrl: this.player.playerData.screenshotUrl,
        offset: this.player.playerData.screenshotOffset,
        context: this.player.playerData.favoriteData?.context as FavoriteDataContextInterface,
      })
      .pipe(takeUntil(sub))
      .subscribe((data) => {
        if (data) {
          sub.next(true);
          sub.complete();
          data.data$.pipe(take(1)).subscribe((d) => {
            const capture = d.data;
            const key = this.toastService.open({
              component: CaptureComponent,
              fullContent: true,
              whiteCloseBtn: true,
              componentData: {
                capture,
              },
              componentEvents: {
                captureClicked: () => this.toastService.removeToastByKey(key),
              },
            });
          });
        }
      });
  }
  ngOnInit() {
    this.previousParent = this.player.videoEl.parentElement;
    this.renderer.appendChild(this.flyoutContainer.nativeElement, this.player.videoEl);
    this.elRef.nativeElement.style.setProperty('--flyout-item-ratio', this.player.playerData.ratio);
    if (this.player.playerData.ratio < 1) {
      this.elRef.nativeElement.classList.add('portrait-flyout');
    }
  }

  ngOnDestroy() {
    this.player.flyoutRef = null;
    this.renderer.appendChild(this.previousParent, this.player.videoEl);
  }
}
