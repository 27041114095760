import { MediaAudioInterface, MediaAudioModel } from './media-audio.model';
import { MediaDocumentInterface, MediaDocumentModel } from './media-document.model';
import { MediaImageInterface, MediaImageModel } from './media-image.model';
import { MediaVideoInterface, MediaVideoModel } from './media-video.model';

export type MediaModelType = MediaImageModel | MediaVideoModel | MediaDocumentModel | MediaAudioModel;
export class MediaFactory {
  static create(
    data: undefined | MediaImageInterface | MediaVideoInterface | MediaDocumentInterface | MediaAudioInterface
  ): MediaModelType | null {
    if (!data) {
      return null;
    }
    switch (data.mediaType) {
      case 'image':
        return new MediaImageModel(data as MediaImageInterface);

      case 'video':
        return new MediaVideoModel(data as MediaVideoInterface);

      case 'document':
        return new MediaDocumentModel(data as MediaDocumentInterface);

      case 'audio':
        return new MediaAudioModel(data as MediaAudioInterface);
    }
    return null;
  }
}
