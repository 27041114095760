<mb-base-modal class="no-content-padding">
  <div class="content share-download-confirmation copy-m" dialog-content>
    <h2 class="download-confirmation-headline h3">{{ 'DOWNLOAD_LINK' | translate }}</h2>

    <mb-checkbox [checked]="agreed" (checkChange)="agreed = $event">
      <!-- this should be display as inline, thats why we need this formatting -->
      <span>
        {{ 'DOWNLOAD_AGREE_PRE_TOS' | translate
        }}<a class="btn btn-plain copy-m" [routerLink]="'/legal/terms-of-use'">
          {{ 'DOWNLOAD_AGREE_TOS' | translate }}</a
        >{{ 'DOWNLOAD_AGREE_AFTER_TOS' | translate }}
      </span>
    </mb-checkbox>

    <mat-form-field>
      <span class="mb-icon mb-icon-chain" [class.disabled]="!agreed"></span>
      <input class="input-el" matInput [value]="url" [class.disabled]="!agreed" readonly disabled />
    </mat-form-field>

    <div class="button-container">
      <button class="btn btn-tertiary" [class.disabled]="!agreed" (click)="copyUrl()">
        {{ 'COPY' | translate }}
      </button>
      <a class="btn" [class.disabled]="!agreed" href="mailto:?subject=Download Link&body={{ url }}">
        {{ 'DOWNLOAD_MAILTO' | translate }}
      </a>
    </div>

    <div *ngIf="copied" class="notification-container">
      <div class="btn btn-quaternary">
        <span class="mb-icon mb-icon-marked-ok-thin"></span>
        <span>{{ 'COPIED' | translate }}</span>
      </div>
    </div>
  </div>
</mb-base-modal>
