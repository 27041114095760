import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MediaVideoModel } from '@modules/media/models/media-video.model';
import { PlayerModel } from '@modules/media/models/player.model';

@Component({
  selector: 'mb-video-scrubber',
  templateUrl: './video-scrubber.component.html',
  styleUrls: ['./video-scrubber.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoScrubberComponent {
  public seekVal = 0;

  @Input() player!: PlayerModel;
  @Input() media!: MediaVideoModel;

  public hoverSeek(value: any) {
    this.seekVal = value;
  }
}
