<picture>
  <ng-container *ngIf="!isLightboxMedia; else lightboxImageTpl">
    <source *ngFor="let source of media.formats" [srcset]="source.url" [media]="source.query" />
    <img [src]="media.original.url" [alt]="media.title" (error)="imageError.emit($event)" draggable="false" />
  </ng-container>
  <ng-template #lightboxImageTpl>
    <source *ngFor="let source of media.lightboxFormats" [srcset]="source.url" [media]="source.query" />
    <img [src]="media.original.url" [alt]="media.title" (error)="imageError.emit($event)" draggable="false" />
  </ng-template>
</picture>
