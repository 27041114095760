<div class="dialog-wrapper">
  <div class="dialog-header">
    <ng-container *ngIf="headerTpl; else defaultHeaderTpl">
      <ng-container *ngTemplateOutlet="headerTpl"></ng-container>
    </ng-container>
    <ng-template #defaultHeaderTpl>
      <span class="btn btn-plain close-btn">
        <span mat-dialog-close class="mb-icon mb-icon-close"></span>
      </span>
    </ng-template>
  </div>
  <div class="dialog-header-content">
    <ng-content select="[dialog-header-content]"></ng-content>
  </div>
  <div class="dialog-content">
    <div class="dialog-content-inner">
      <ng-content select="[dialog-content]"></ng-content>
    </div>
  </div>
</div>
