<div
  class="lightbox-container"
  (scroll)="checkContentScroll($event)"
  #lbContainer
  [class.scrolled]="contentScrolled"
  [class.info-scrolled]="infoScrolled"
>
  <div class="content-wrapper" [class.full-content]="fullContent">
    <div class="content-wrapper-inner" #innerContentWrapper>
      <ng-template [mbContentHost]></ng-template>
    </div>
  </div>

  <div class="sidebar-wrapper">
    <div class="info-container" (scroll)="checkInfoScroll($event)">
      <mb-info *ngIf="preMedia && preMedia.formatted_datetimes">
        <div class="embargo-container">
          <div class="embargo-title">
            <wb-icon name="alert-circle"></wb-icon>
            <wb-text size="l" strong static class="embargo-subline">
              {{ 'EMBARGO_CONFIRM_SUBLINE' | translate }}
            </wb-text>
          </div>
          <wb-text size="m" static class="embargo-date" *ngFor="let embargoDate of preMedia.formatted_datetimes">
            {{ embargoDate }}
          </wb-text>
        </div>
      </mb-info>
      <ng-container *ngIf="sidebarData">
        <!-- FUEL LABEL -->
        <p *ngIf="sidebarData.fuelLabel" class="fuel-label copy-s" [innerHTML]="sidebarData.fuelLabel"></p>

        <div *ngIf="sidebarData.displayDate">
          <p class="copy-s">{{ 'PUBLISH_DATE' | translate }}:</p>
          <p class="copy-l">{{ sidebarData.displayDate | localizedDate : 'longDate' }}</p>
        </div>

        <div *ngIf="sidebarData.caption">
          <p class="copy-s">{{ 'CAPTION' | translate }}:</p>
          <p class="copy-l">{{ sidebarData.caption }}</p>
        </div>

        <div *ngIf="sidebarData.description">
          <p class="copy-s">{{ 'DESCRIPTION' | translate }}:</p>
          <p class="copy-l">{{ sidebarData.description }}</p>
        </div>

        <!-- SHELF NUMBER -->

        <div *ngIf="sidebarData.marsShelfNumber">
          <p class="copy-s">{{ 'SHELF_NUMBER' | translate }}:</p>
          <p class="copy-l">{{ sidebarData.marsShelfNumber }}</p>
        </div>

        <!-- MEDIA META -->
        <div *ngIf="sidebarData.duration">
          <p class="copy-s">{{ 'RUNTIME' | translate }}:</p>
          <p class="copy-l">
            {{ sidebarData.duration | durationFormat }}
          </p>
        </div>

        <div *ngIf="sidebarData.resolution">
          <p class="copy-s">{{ 'RESOLUTION' | translate }}:</p>
          <p class="copy-l">{{ sidebarData.resolution }}</p>
        </div>

        <div *ngIf="sidebarData.numberOfPages">
          <p class="copy-s">{{ 'PAGES' | translate }}</p>
          <p class="copy-l">{{ sidebarData.numberOfPages }}</p>
        </div>

        <div *ngIf="sidebarData.fileSize">
          <p class="copy-s">{{ 'FILE_SIZE' | translate }}:</p>
          <p class="copy-l">{{ sidebarData.fileSize | fileSize }}</p>
        </div>

        <!-- CAPTURES -->
        <div *ngIf="sidebarData.timecode">
          <p class="copy-s">{{ 'RECORDED_AT' | translate }}:</p>
          <p class="copy-l">{{ sidebarData.timecode }}</p>
        </div>

        <div *ngIf="sidebarData.numberOfCameras">
          <p class="copy-s">{{ 'AVAILABLE_PERSPECTIVES' | translate }}:</p>
          <p class="copy-l">{{ sidebarData.numberOfCameras }}</p>
        </div>

        <!-- FORMAT -->
        <div *ngIf="sidebarData.fileFormat">
          <p class="copy-s">{{ 'FILE_FORMAT' | translate }}:</p>
          <p class="copy-l">{{ sidebarData.fileFormat }}</p>
        </div>

        <!-- CONTEXT -->
        <div *ngIf="contextLink">
          <p class="copy-s">{{ contextLink.label | translate }}:</p>
          <div>
            <a
              [routerLink]="[contextLink.link.url]"
              [queryParams]="contextLink.link.queryParams || {}"
              queryParamsHandling="merge"
              class="copy-l btn btn-plain btn-text"
              (click)="closeLightbox(true)"
            >
              {{ contextLink.text }}
            </a>
          </div>
        </div>

        <!-- DAT LABEL -->
        <p class="dat-label copy-s" [innerHTML]="'DAT_LABEL' | translate"></p>
      </ng-container>
    </div>

    <mb-action-container
      *ngIf="lightboxService.activeResource"
      #actionContainerHub
      class="default-action-container large lightbox-action-container"
      [class.in-view]="hubInView"
      [buttonItems]="
        !preMedia
          ? [
              { class: 'btn', icon: actionIcons.SHARE, label: 'SHARE' },
              { class: 'btn', icon: actionIcons.DOWNLOAD, label: 'DOWNLOAD' },
              {
                class: 'btn',
                icon: actionIcons.BOOKMARK,
                label: isBookmarked(lightboxService.activeResource.id || '') ? 'SAVED' : 'SAVE'
              }
            ]
          : [{ class: 'btn', icon: actionIcons.DOWNLOAD, label: 'DOWNLOAD' }]
      "
      [resource]="lightboxService.activeResource"
    ></mb-action-container>

    <mb-action-container
      *ngIf="lightboxService.activeResource"
      class="floating-action-container"
      [buttonItems]="
        !preMedia
          ? [
              { class: 'btn btn-plain', icon: actionIcons.SHARE },
              { class: 'btn btn-plain', icon: actionIcons.DOWNLOAD },
              {
                class: 'btn btn-plain',
                icon: actionIcons.BOOKMARK
              }
            ]
          : [{ class: 'btn btn-plain', icon: actionIcons.DOWNLOAD }]
      "
      [resource]="lightboxService.activeResource"
    ></mb-action-container>
  </div>

  <div class="lightbox-header" [class.full-width]="showCounter()">
    <div *ngIf="showCounter()" class="counter-container">
      <div class="counter-indicator button-label">{{ lightboxService.activeMediaIndex + 1 }}</div>
      <div class="counter-seperator button-label">/</div>
      <div class="counter-count button-label">{{ lightboxService.mediaCount }}</div>
    </div>

    <div class="close-btn-wrapper">
      <div class="close-btn btn btn-quaternary" (click)="closeLightbox()">
        <span class="d-none d-sm-inline-block">{{ 'CLOSE' | translate }}</span>
        <span class="mb-icon mb-icon-close"></span>
      </div>
    </div>
  </div>
</div>
