import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false,
  // TODO: this runs every change detection cycle, which is not ideal
  // Refactor once we upgrade Angular v16
  // https://stackoverflow.com/questions/74141521/how-to-create-custom-pipe-reacting-on-lang-change-like-translate-pipe-some-sort
  // https://dev.to/this-is-angular/angular-signals-everything-you-need-to-know-2b7g
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(date: any, pattern: string = 'mediumDate', timezone?: string): any {
    const currentLang = this.translate.currentLang || 'de-DE';

    if (date === 'TODAY' || date === 'YESTERDAY') {
      return date;
    }
    const formats: any = {
      'de-DE': {
        longDate: 'dd. MMMM yyyy',
        shortTime: 'H:mm',
        startDay: 'dd.',
        endDay: 'dd. MMMM yyyy',
        startDayAndMonth: 'dd. MMMM',
        endDayAndMonth: 'dd. MMMM yyyy',
        yearOnly: 'yyyy',
        monthAndYear: 'MM yyyy',
        precise: 'dd. MMMM yyyy, HH:mm',
      },
      default: {
        longDate: 'MMMM dd, yyyy',
        shortTime: 'h:mm a',
        startDay: 'MMMM dd',
        startDayAndMonth: 'MMMM dd',
        endDay: 'dd, yyyy',
        endDayAndMonth: 'MMMM dd, yyyy',
        yearOnly: 'yyyy',
        monthAndYear: 'MM yyyy',
        precise: 'MMMM dd, yyyy, h:mm aaa',
      },
    };

    pattern = (formats[currentLang] || formats.default)[pattern] || pattern;
    return new DatePipe(currentLang).transform(date, pattern, timezone);
  }
}
