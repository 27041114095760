<mb-base-modal>
  <div class="first-favorite-modal" dialog-content>
    <div class="first-favorite-icon-container">
      <img src="/assets/img/my_content_first_usage@2.png" alt="Bookmark icon with badge showing the number 1" />
    </div>
    <h2 class="h5 headline">{{ 'FAVORITE_ADD' | translate }}</h2>
    <p class="text copy-l">{{ 'FAVORITE_ADD_FIRST' | translate }}</p>
    <div class="first-favorite-actions">
      <button class="btn" (click)="signUp()">{{ 'FAVORITE_ADD_FIRST_SIGN_UP' | translate }}</button>
      <span class="continue-btn btn btn-plain" mat-dialog-close> {{ 'FAVORITE_ADD_FIRST_CONTINUE' | translate }}</span>
    </div>
  </div>
</mb-base-modal>
