import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';

export type InfoVariant = 'danger' | 'info' | 'warning' | 'secondary' | 'primary' | 'success';

@Component({
  selector: 'mb-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent {
  @Input() icon?: string;
  @Input() headline?: string;

  @Input() type: InfoVariant = 'info';
}
