import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { Breadcrumb, BreadcrumbService } from '@modules/shared/services/breadcrumb.service';
import { HeaderService } from '@modules/shared/services/header.service';
import { PressKitPageService } from '@pages/press-kit-page/press-kit-page.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PressKit } from '../adapter/press-kit.adapter';

@Injectable({
  providedIn: 'root',
})
export class PressKitResolver implements Resolve<Observable<PressKit | null>> {
  constructor(
    private appService: AppService,
    private pressKitPageService: PressKitPageService,
    private router: Router
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<PressKit | null> {
    const pressKitId = route.paramMap.get('pressKitId');

    return this.pressKitPageService.getPressKit(pressKitId).pipe(
      map((result) => {
        if (!result) {
          this.router.navigate(['404'], { skipLocationChange: true });
          return null;
        }

        this.appService.darkBackgroundPage();
        this.appService.setTitle(`${result.title} | Mercedes-Benz Media`);

        return result;
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class PressKitBreadcrumbResolver implements Resolve<Observable<Breadcrumb[] | Breadcrumb | undefined>> {
  constructor(
    private pressKitPageService: PressKitPageService,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Breadcrumb[] | Breadcrumb | undefined> {
    const pressKitId = route.paramMap.get('pressKitId');
    if (pressKitId) {
      if (this.breadcrumbService.keepBreadcrumbs) {
        return of({ label: 'PRESS_KIT', link: `/press-kit/${pressKitId}` });
      } else {
        // fetch presskit to build "default" breadcrumbs for this article
        return this.pressKitPageService.getPressKit(pressKitId).pipe(
          switchMap((pressKit) => {
            if (pressKit) {
              if (pressKit.topicId) {
                return this.headerService.findNavigationMenuById(pressKit.topicId).pipe(
                  switchMap((menu) => {
                    if (menu) {
                      return this.headerService.navigationMenuPath$(menu).pipe(
                        map((topicPath) => {
                          return [
                            ...topicPath.map((item) => ({
                              label: `MENU.${item.id}.NAME`,
                              link: `MENU.${item.id}.PATH`,
                            })),
                            { label: 'PRESS_KIT', link: `/press-kit/${pressKitId}` },
                          ];
                        })
                      );
                    } else {
                      return of([{ label: 'PRESS_KIT', link: `/press-kit/${pressKitId}` }]);
                    }
                  })
                );
              } else {
                return of([{ label: 'PRESS_KIT', link: `/press-kit/${pressKitId}` }]);
              }
            } else {
              return of(undefined);
            }
          })
        );
      }
    } else {
      return of(undefined);
    }
  }
}
