<mb-dynamic-container [observedWidths]="controlBrakpoints" (widthChanged)="checkControlWidth($event)">
  <div
    class="inline-video-container"
    (mouseenter)="hoverPlayer()"
    (mouseleave)="stopHoverplay()"
    (mousemove)="showControls()"
    (click)="showControls()"
  >
    <div class="inline-video-wrapper" #videoParent (click)="togglePlay()"></div>
    <div
      *ngIf="!hideTitle || showBackButton"
      class="video-title-wrapper video-title-wrapper-top"
      [class.player-hovered]="showControls$ | async"
    >
      <span *ngIf="showBackButton" class="video-title-btn btn btn-plain" (click)="backButton.emit()">
        <span class="mb-icon mb-icon-step-back"></span>
      </span>
      <span *ngIf="!hideTitle" [innerHTML]="media.title"></span>
    </div>
    <div
      *ngIf="!hideBottomTitle"
      class="video-title-wrapper video-title-wrapper-bottom font-family-title h2"
      [class.hidden]="smallControls"
      [class.paused]="(player.playing$ | async) === false"
      [innerHTML]="media.title"
    ></div>
    <mb-video-controls
      *ngIf="player && controls && !hoverVideo"
      [player]="player"
      [media]="media"
      [allowPictureInPicture]="allowPictureInPicture"
      (capture)="capture.emit($event)"
      [smallControls]="smallControls"
      [showControls]="(showControls$ | async) || (player.playing$ | async) === false"
    ></mb-video-controls>
  </div>
  <div *ngIf="hoverVideo && (player.playing$ | async) === false" class="play-pause-btn-container">
    <div class="btn btn-plain">
      <span class="mb-icon mb-icon-player-play"></span>
    </div>
  </div>
</mb-dynamic-container>
