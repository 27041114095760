<mb-header-bar [notificationCount]="notificationCount"></mb-header-bar>

<!-- Main menu -->
<wb-header-navigation aria-label="{{ 'MAIN_NAVIGATION' | translate }}">
  <ng-container *ngIf="mainMenu$ | async as menu">
    <wb-header-navigation-item
      *ngFor="let item of menu"
      label="Open {{ 'MENU.' + item.id + '.NAME' | translate }} menu"
      (click)="toggleMenu(2, item)"
      [active]="isActive(item)"
      >{{ 'MENU.' + item.id + '.NAME' | translate }}</wb-header-navigation-item
    >
  </ng-container>

  <div class="desktop-end">
    <wb-header-navigation-item class="hover-animation" (click)="openMyContent()">
      <div class="my-mb-media">
        <wb-icon slot="icon" name="bds/bookmark/24"></wb-icon>
        {{ 'MY_CONTENT' | translate }}
        <div class="notification-icon" [ngClass]="{ 'show-notification': !!notificationCount }">
          <wb-icon class="bell" name="notification"></wb-icon>
          <wb-counter [value]="notificationCount" [max]="9" variant="secondary"></wb-counter>
        </div>
      </div>
    </wb-header-navigation-item>
  </div>
</wb-header-navigation>

<!-- Bread crumbs -->
<ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
  <div
    class="breadcrumbs"
    [class.light]="lightPage$ | async"
    *ngIf="isMinimized || breadcrumbs.length || currentMediaQuery === 'mq3' || currentMediaQuery === 'mq4'"
  >
    <wb-breadcrumbs theme="dark">
      <wb-icon
        id="to-top"
        class="chevron btn btn-plain"
        *ngIf="isMinimized"
        name="bds/chevron-up/24"
        (click)="scrollTop()"
      ></wb-icon>
      <wb-horizontal-scroll theme="dark" hide-arrow-navigation>
        <wb-breadcrumb
          *ngIf="breadcrumbs.length"
          [href]="'/'"
          (click)="onBreadcrumbClick($event, { label: 'home', link: '/' })"
        >
          Start
        </wb-breadcrumb>
        <ng-container *ngFor="let crumb of breadcrumbs">
          <wb-breadcrumb [href]="crumb.link | translate" (click)="onBreadcrumbClick($event, crumb)">{{
            crumb.label | translate
          }}</wb-breadcrumb>
        </ng-container>
      </wb-horizontal-scroll>
    </wb-breadcrumbs>

    <div class="breadcrumbs-end">
      <wb-header-navigation-item class="hover-animation" (click)="openMyContent()">
        <div class="my-mb-media">
          <wb-icon slot="icon" name="bds/bookmark/24"></wb-icon>
          {{ 'MY_CONTENT' | translate }}
          <div class="notification-icon" [ngClass]="{ 'show-notification': !!notificationCount }">
            <wb-icon class="bell" name="notification"></wb-icon>
            <wb-counter [value]="notificationCount" [max]="9" variant="secondary"></wb-counter>
          </div>
        </div>
      </wb-header-navigation-item>
    </div>
  </div>
</ng-container>

<mb-fly-in></mb-fly-in>
