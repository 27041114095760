import { FavorableContent } from '@modules/favorites/interfaces/favorable-content.interface';
import { TrackingItem } from '@modules/shared/gtm/data-layer.types';
import { LightboxContextLink, LightboxMetadataInterface } from '@modules/shared/interfaces/lightbox.interface';
import { TrackableContent } from '@modules/shared/services/data-layer.service';
import { DownloadableContent, DownloadData } from '@modules/shared/services/download.service';

import { Camera } from './media-video.model';

export interface CaptureInterface {
  id: string;
  type: string;
  screenshotUrl: string;
  screenshotOffset: number;
  fuelLabel: string;
  activeCamera: number;
  originalVideoTime: number;
  videoTime: number;
  cameraOptions: Camera[];
  additionalData: LightboxContextLink;
}
export class CaptureModel implements FavorableContent, DownloadableContent, TrackableContent {
  public id: string;
  public type: string;
  public mediaType = 'image';
  public title: string;
  private originalSeekPosition = 0;
  private originalActiveCamera = 0;

  public hasPrev = true;
  public hasNext = true;
  public seekPosition = 0;

  public thumbUrl!: string;
  public previewUrl!: string;
  public originUrl!: string;

  public minutes: number = 0;
  public seconds: number = 0;
  public time: string = '00:00';

  public possibleSeekPositions: boolean[] = [];

  private screenshotUrl: string;
  private screenshotOffset: number;
  private additionalData: LightboxContextLink;
  public fuelLabel: string;
  public activeCamera: number; // in case of composite we need a camera pos
  public originalVideoTime: number;
  public currentVideoTime: number;

  public cameraOptions: Camera[];

  public filetypeName: string;

  public trackingData?: TrackingItem | undefined;

  constructor(data: CaptureInterface) {
    this.id = data.id;
    this.type = data.type;
    this.screenshotUrl = data.screenshotUrl;
    this.screenshotOffset = data.screenshotOffset;
    this.fuelLabel = data.fuelLabel || '';
    this.activeCamera = data.activeCamera;
    this.originalVideoTime = data.originalVideoTime;
    this.currentVideoTime = data.videoTime || data.originalVideoTime;
    this.cameraOptions = data.cameraOptions;

    this.originalActiveCamera = data.activeCamera;

    this.additionalData = data.additionalData;

    this.title = this.additionalData.text ?? '';

    this.filetypeName = 'JPG';

    this.seekPosition = this.currentVideoTime - this.originalVideoTime;
    this.changeCapture(this.currentVideoTime, this.activeCamera);

    this.trackingData = {
      id: this.id,
      type: 'capture',
      title: this.title,
      marsOriginId: undefined,
      shelfNumber: undefined,
    };
  }

  get downloadData(): DownloadData {
    // sorry for this crime, but screenshots are currently no own entity in the backend and thus dont match the "DownloadData" interface
    const [_host, serverName, _image, videoName] = this.screenshotUrl.replace('https://', '').split('/');
    const frame = Math.round(this.currentVideoTime * 10) + this.screenshotOffset || 1;
    return {
      id: this.id,
      type: this.type,
      format: this.filetypeName,
      fileType: 'image',
      title: this.additionalData.text ?? '',
      serverName,
      cameraName: `video${this.activeCamera + 1}`,
      imageSize: 'original',
      videoName,
      timeCode: `${frame}`,
      streamId: this.additionalData.link?.queryParams?.video,
    };
  }

  public getFrame() {
    return Math.round(this.currentVideoTime * 10) + this.screenshotOffset || 1;
  }

  getLightboxMetadata(): LightboxMetadataInterface {
    return {
      fuelLabel: this.fuelLabel ? this.fuelLabel : undefined,
      fileFormat: this.filetypeName,
      timecode: this.time,
      numberOfCameras: this.cameraOptions.length,
      contextLink: this.additionalData,
    };
  }

  private changeCapture(videoTime: number, activeCanvas: number) {
    this.currentVideoTime = videoTime !== Infinity ? videoTime : 0;
    activeCanvas++;
    const thumbUrl = `${this.screenshotUrl}thumb/video${activeCanvas}/`;
    const previewUrl = `${this.screenshotUrl}preview/video${activeCanvas}/`;
    const originUrl = `${this.screenshotUrl}original/video${activeCanvas}/`;
    const frame = Math.round(videoTime * 10) + this.screenshotOffset || 1;

    this.minutes = Math.floor(videoTime / 60);
    this.seconds = Math.floor(videoTime % 60);
    this.time = `${this.minutes.toString().padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}`;
    this.thumbUrl = `${thumbUrl}${frame}`;
    this.previewUrl = `${previewUrl}${frame}`;
    this.originUrl = `${originUrl}${frame}.jpg`;
    this.checkPageOptions();
  }

  private checkPageOptions() {
    const maxframe = 999999;
    this.possibleSeekPositions[0] = this.originalVideoTime - 1.5 > 0;
    this.possibleSeekPositions[1] = this.originalVideoTime - 1.0 > 0;
    this.possibleSeekPositions[2] = this.originalVideoTime - 0.5 > 0;
    this.possibleSeekPositions[3] = true;
    this.possibleSeekPositions[4] = this.originalVideoTime + 0.5 < maxframe;
    this.possibleSeekPositions[5] = this.originalVideoTime + 1.0 < maxframe;
    this.possibleSeekPositions[6] = this.originalVideoTime + 1.5 < maxframe;

    const currentBullet = Math.floor(((this.seekPosition + 1.5) / 3) * 7);
    this.hasPrev = this.possibleSeekPositions[currentBullet - 1] === true;
    this.hasNext = this.possibleSeekPositions[currentBullet + 1] === true;
  }

  prev() {
    this.seekTo(this.seekPosition - 0.5);
  }

  next() {
    this.seekTo(this.seekPosition + 0.5);
  }

  seekTo(seekValue: number) {
    this.seekPosition = seekValue;
    if (this.seekPosition >= 1.5) {
      this.hasNext = false;
      this.hasPrev = true;
    } else if (this.seekPosition <= -1.5) {
      this.hasPrev = false;
      this.hasNext = true;
    } else {
      this.hasNext = true;
      this.hasPrev = true;
    }
    this.changeCapture(this.originalVideoTime + seekValue, this.activeCamera);
  }

  setActiveCamera(activeCamera: number) {
    this.activeCamera = activeCamera;
    this.changeCapture(this.originalVideoTime + this.seekPosition, activeCamera);
  }

  public get isEdited() {
    return this.seekPosition !== this.originalSeekPosition || this.activeCamera !== this.originalActiveCamera;
  }

  save() {
    this.originalSeekPosition = this.seekPosition;
    this.originalActiveCamera = this.activeCamera;
  }

  get favoriteData() {
    const frame = Math.round(this.currentVideoTime * 10) + this.screenshotOffset || 1;
    return {
      id: this.id,
      type: this.type,
      data: {
        activeCanvas: this.activeCamera,
        time: this.currentVideoTime,
        originalTime: this.originalVideoTime,
        downloadUrl: `${this.screenshotUrl}original/video${this.activeCamera + 1}/${frame}`,
      },
    };
  }
  cancel() {
    this.seekTo(this.originalSeekPosition);
    this.setActiveCamera(this.originalActiveCamera);
  }

  get isBookmarked() {
    return true;
  }
}
