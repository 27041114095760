import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { FavoritesService } from '@modules/favorites/services/favorites.service';
import { LightboxService } from '@modules/shared/services/lightbox.service';
import { Option } from '@modules/ui/interfaces/option.interface';

import { CaptureModel } from '../models/capture.model';

@Component({
  selector: 'mb-capture',
  templateUrl: './capture.component.html',
  styleUrls: ['./capture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptureComponent {
  private hideCaptureTimeout: any = null;

  public cameraOptions: Option[] = [];
  public showControls = false;

  @HostBinding('class.with-controls')
  @Input()
  controls = false;

  private _capture!: CaptureModel;
  @Input()
  set capture(v: CaptureModel) {
    this._capture = v;
    if (this.hideCaptureTimeout) {
      clearTimeout(this.hideCaptureTimeout);
    }
    this.hideCaptureTimeout = setTimeout(() => {
      this.closeCapture();
    }, 5000);
    this.cameraOptions = [];
    this._capture.cameraOptions.forEach((option, index) => {
      this.cameraOptions.push({
        label: option.title,
        value: index,
      });
    });
  }
  get capture() {
    return this._capture;
  }
  @Output() hideCapture = new EventEmitter();
  @Output() captureClicked = new EventEmitter();

  constructor(private lightboxService: LightboxService, private favoriteService: FavoritesService) {}

  closeCapture(e?: any) {
    if (e) {
      e.stopPropagation();
    }
    if (this.hideCaptureTimeout) {
      clearTimeout(this.hideCaptureTimeout);
    }
    this.hideCapture.emit();
  }

  openMedia() {
    this.captureClicked.emit();
    this.closeCapture();
    this.lightboxService.open({
      component: CaptureComponent,
      resource: this.capture,
      componentData: {
        capture: this.capture,
        controls: true,
      },
      sidebarData: this.capture.getLightboxMetadata(),
    });
  }

  toggleControls() {
    this.showControls = !this.showControls;
  }

  changePosition(selectedOption: Option) {
    this.capture.setActiveCamera(selectedOption.value);
  }

  saveEdit() {
    this.capture.save();
    this.showControls = false;
    this.favoriteService.update(this.capture.favoriteData);
  }

  cancelEdit() {
    this.capture.cancel();
    this.showControls = false;
  }
}
