import { ParsedEntryInterface } from '@modules/content-api/api.interface';
import { ContentAPIService } from '@modules/content-api/content-api.service';
import { MediaFactory } from '@modules/media/models/media.model';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { FavoritesAdapter } from '../adapter/favorites.adapter';
import { FavoriteContentDataInterface } from '../interfaces/favorite-content-data.interface';
import { LocalFavoriteDataInterface } from '../interfaces/local-favorite-data.interface';
import { FavoritesParser } from '../parser/favorites.parser';

const API_TYPES_MAP: any = {
  article: 'articles',
  presskit: 'presskits',
  image: 'images',
  structured_image: 'structured_images',
  audio: 'audios',
  video: 'videos',
  document: 'documents',
  stream: 'streams',
};

export class Favorite {
  private dataRequested = false;
  private DATA$ = new ReplaySubject<FavoriteContentDataInterface>();

  public id: string;
  public type: string;
  public date: Date;
  public data: any;
  public context?: {
    id: string;
    type: string;
  } | null;

  public content: any;
  private queryParams: any = {};

  constructor(
    content: LocalFavoriteDataInterface,
    private apiService: ContentAPIService,
    private adapter: FavoritesAdapter,
    private favoritesParser: FavoritesParser
  ) {
    this.id = content.id;
    this.type = content.type;
    this.date = new Date(content.addedAt);
    this.data = content.data;
    this.context = content.context || null;
    switch (this.type) {
      case 'article':
        this.queryParams.include = [
          'header_image',
          'header_image.image',
          'header_image.adjusted_image',
          'header_image.alternate_images.image',
        ].join();
        this.queryParams['fields[media--alternate_images]'] = 'image';
        break;
      case 'presskit':
        this.queryParams.include = [
          'main_image',
          'main_image.image',
          'main_image.adjusted_image',
          'main_image.alternate_images.image',
        ].join();
        this.queryParams['fields[media--alternate_images]'] = 'image';
        break;
      case 'image':
      case 'structured_image':
        this.queryParams.include = ['image', 'adjusted_image'].join();
        this.queryParams['fields[structured_image]'] = 'image';
        this.queryParams['fields[media--alternate_images]'] = 'image';
        break;
      case 'audio':
        this.queryParams.include = 'audio';
        break;
      case 'video':
        this.queryParams.include = 'video,poster_image';
        break;
      case 'document':
        this.queryParams.include = 'file';
        break;
      case 'capture':
        this.queryParams.include = ['player_config'].join();
        break;
    }
  }

  get data$(): Observable<FavoriteContentDataInterface> {
    if (!this.dataRequested) {
      this.dataRequested = true;
      const requests: Observable<ParsedEntryInterface>[] = [];
      if (this.type !== 'capture') {
        requests.push(this.apiService.getResource(API_TYPES_MAP[this.type] || this.type, this.id, this.queryParams));
        if (this.context) {
          requests.push(
            this.apiService.getResource(API_TYPES_MAP[this.context.type] || this.context.type, this.context.id)
          );
        }
      } else if (this.context) {
        requests.push(
          this.apiService.getResource(
            API_TYPES_MAP[this.context.type] || this.context.type,
            this.context.id,
            this.queryParams
          )
        );
      }
      combineLatest(requests)
        .pipe(
          take(1),
          map((data) => {
            const [content, context] = data;
            const adaptedContent = this.adapter.parse(content, context);
            let parsedContent: any = adaptedContent;
            switch (content.type) {
              case 'image':
              case 'structured_image':
              case 'video':
              case 'audio':
              case 'document':
                parsedContent = MediaFactory.create(adaptedContent);
                break;
            }
            if (this.type === 'capture') {
              return this.favoritesParser.parse({ id: this.id, type: this.type, ...this.data }, parsedContent);
            }
            return this.favoritesParser.parse(parsedContent, context, this.adapter.getSlugOfContext(context));
          })
        )
        .subscribe((data) => {
          this.DATA$.next(data);
        });
    }

    return this.DATA$.asObservable();
  }
}
