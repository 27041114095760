import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * A pipe which formats a given duration in seconds into
 *
 *    - xx:yy min
 *    - yy sec
 *
 * INFO: Extend this, if other formats are needed.
 */
@Pipe({
  name: 'durationFormat',
})
export class DurationFormatPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(durationInSeconds: any = 0) {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;

    if (minutes === 0) {
      return this.translate.instant('DURATION_SECONDS', { seconds });
    } else {
      return this.translate.instant('DURATION_MINUTES_SECONDS', {
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0'),
      });
    }
  }
}
