import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'mb-resolution-controller',
  templateUrl: './resolution-controller.component.html',
  styleUrls: ['./resolution-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResolutionControllerComponent {
  public open = false;
  public readonly infinity = Infinity;

  @Input() openBottom = false;
  @Input() resolutions: any[] = [];
  @Input() activeResolution = -1;
  @Input() isComposed = false;
  @Output() resolutionIndexChanged = new EventEmitter<number>();

  constructor(private cdr: ChangeDetectorRef) {}

  public toggleResolutions(e: any): void {
    e.stopPropagation();
    e.preventDefault();
    this.open = !this.open;
    this.cdr.detectChanges();

    const docClickCb = () => {
      this.open = false;
      this.cdr.detectChanges();
      document.removeEventListener('click', docClickCb);
    };
    document.addEventListener('click', docClickCb);
  }

  public changeResolution(bitrate: number): void {
    this.resolutionIndexChanged.emit(bitrate);
  }
}
