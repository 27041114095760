import { Injectable } from '@angular/core';
import { ContentAPIService } from '@modules/content-api/content-api.service';
import { map, Observable, ReplaySubject } from 'rxjs';

import { FavoritesAdapter } from '../adapter/favorites.adapter';
import { Favorite } from '../models/favorite.model';
import { FavoritesParser } from '../parser/favorites.parser';

import { FavoritesService } from './favorites.service';

// FIXME: combine this service with FavoritesService to remove these anti-patterns
@Injectable({ providedIn: 'root' })
export class FavoritesContentService {
  private FAVORITES: { [key: string]: Favorite } = {};
  private _favorites$ = new ReplaySubject<Favorite[]>(1);
  constructor(
    favoritesAdapter: FavoritesAdapter,
    favoritesService: FavoritesService,
    apiService: ContentAPIService,
    favoritesParser: FavoritesParser
  ) {
    favoritesService.favoritesInit$.subscribe((favorites) => {
      for (let favorite of favorites) {
        if (!this.FAVORITES[favorite.id]) {
          this.FAVORITES[favorite.id] = new Favorite(favorite, apiService, favoritesAdapter, favoritesParser);
        }
      }
      this._favorites$.next(Object.values(this.FAVORITES));
    });
    favoritesService.favoriteAdded$.subscribe((favorite) => {
      this.FAVORITES[favorite.id] = new Favorite(favorite, apiService, favoritesAdapter, favoritesParser);
      this._favorites$.next(Object.values(this.FAVORITES));
    });
    favoritesService.favoriteRemoved$.subscribe((favoriteId) => {
      delete this.FAVORITES[favoriteId];
      this._favorites$.next(Object.values(this.FAVORITES));
    });
    favoritesService.favoriteUpdated$.subscribe((favorite) => {
      this.FAVORITES[favorite.id].data = favorite.data;
    });
    favoritesService.favoritesCleared$.subscribe((cleared) => {
      if (cleared) {
        // clears the favorites state of this service
        this.FAVORITES = {};
        this._favorites$.next([]);
      }
    });
  }

  get favorites$(): Observable<Favorite[]> {
    return this._favorites$.asObservable();
  }

  get favoritesCount$(): Observable<number> {
    return this._favorites$.asObservable().pipe(map((items) => items.length));
  }
}
