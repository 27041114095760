import { ComponentType } from '@angular/cdk/portal';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ComponentRef,
  ElementRef,
  HostBinding,
  OnDestroy,
  ViewChild,
  Directive,
  ViewContainerRef,
  HostListener,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastService } from './toast.service';

@Directive({
  selector: '[mbToastContentHost]',
})
export class ToastContentHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
  selector: 'mb-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnInit, OnDestroy {
  public destroy$ = new Subject<boolean>();

  @HostListener('mouseleave')
  clearAfterDelay() {
    if (this.destroyed) {
      return;
    }
    if (this.clearTimeout) {
      clearTimeout(this.clearTimeout);
    }
    this.clearTimeout = setTimeout(() => {
      this.close();
    }, this.destroyTimer);
  }

  @HostListener('mouseenter')
  preventClearing() {
    if (this.destroyed) {
      return;
    }
    if (this.clearTimeout) {
      clearTimeout(this.clearTimeout);
    }
  }

  @Input() destroyTimer = 5000;

  @HostBinding('style.bottom.px')
  @Input()
  bottom: number | null = null;
  @HostBinding('style.top.px')
  @Input()
  top: number | null = null;

  @ViewChild(ToastContentHostDirective, { static: true }) contentTpl!: ToastContentHostDirective;

  @Input() fullContent = false;
  @Input() whiteCloseBtn = false;
  @Input() title = '';
  @Input() text = '';

  @HostBinding('class.has-trigger')
  @Input()
  trigger?: () => void;

  @Input() component?: ComponentType<any>;
  @Input() componentData: any = {};
  @Input() componentEvents: any = {};
  @Input() ref!: ComponentRef<ToastComponent>;
  // needed if toasts are moved into player when entering and exiting fullscreen
  @Input() parent?: HTMLElement;

  private clearTimeout: any = null;
  private destroyed = false;
  constructor(public elRef: ElementRef, private toastService: ToastService) {}

  checkTrigger() {
    if (this.trigger) {
      this.toastService.closeLightbox$.next(true);
      this.trigger();

      if (this.clearTimeout && !this.destroyed) {
        clearTimeout(this.clearTimeout);
        this.close();
      }
    }
  }

  close() {
    if (this.destroyed) {
      return;
    }
    this.elRef.nativeElement.classList.remove('show');
    setTimeout(() => {
      this.ref?.destroy();
    }, 400);
  }

  ngOnInit(): void {
    if (this.component) {
      const viewContainerRef = this.contentTpl.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(this.component);
      if (this.componentData) {
        for (let i in this.componentData) {
          componentRef.instance[i] = this.componentData[i];
        }
      }
      for (const i in this.componentEvents) {
        if (this.componentEvents.hasOwnProperty(i)) {
          componentRef.instance[i].pipe(takeUntil(this.destroy$)).subscribe((event: any) => {
            this.componentEvents[i](event);
          });
        }
      }
    }
    this.clearAfterDelay();
  }

  ngOnDestroy() {
    this.destroyed = true;
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
