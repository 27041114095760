import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnInit, HostListener } from '@angular/core';
import { MediaAudioModel } from '@modules/media/models/media-audio.model';
import { MediaDocumentModel } from '@modules/media/models/media-document.model';
import { MediaImageModel } from '@modules/media/models/media-image.model';
import { MediaVideoModel } from '@modules/media/models/media-video.model';
import { MediaType } from '@modules/media/models/media.types';
import { LightboxService } from '@modules/shared/services/lightbox.service';
import { TrackingService, TRACKING_TYPES } from '@modules/shared/services/tracking.service';
import { Screen } from '@modules/shared/services/window.service';

type mediaListType = MediaImageModel | MediaVideoModel | MediaAudioModel | MediaDocumentModel;

@Component({
  selector: 'mb-lightbox-media-gallery',
  templateUrl: './lightbox-media-gallery.component.html',
  styleUrls: ['./lightbox-media-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightboxMediaGalleryComponent implements OnInit {
  public mediaType = MediaType;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'ArrowRight') {
      this.next();
    } else if (event.key === 'ArrowLeft') {
      this.prev();
    }
  }

  @Input() activeIndex = 0;
  @Input() mediaList: mediaListType[] = [];
  @Input() isPreMedia: boolean = false;

  constructor(
    private lightboxService: LightboxService,
    private trackingService: TrackingService,
    private cdr: ChangeDetectorRef
  ) {}

  public prev() {
    if (this.activeIndex === 0) {
      this.activeIndex = this.mediaList.length - 1;
    } else {
      this.activeIndex--;
    }
    this.updateAfterChange();
  }

  public next() {
    if (this.activeIndex === this.mediaList.length - 1) {
      this.activeIndex = 0;
    } else {
      this.activeIndex++;
    }
    this.updateAfterChange();
  }

  public swipe(direction: 'left' | 'right') {
    if (window.screen.width >= Screen.MD) {
      return;
    }
    if (direction === 'left') {
      this.prev();
    } else {
      this.next();
    }
  }

  private updateAfterChange() {
    const metadata = this.mediaList[this.activeIndex].getLightboxMetadata();
    this.lightboxService.sidebarData$.next(metadata);
    this.lightboxService.activeResource = this.mediaList[this.activeIndex];
    this.lightboxService.activeMediaIndex = this.activeIndex;
    this.cdr.detectChanges();
    this.trackingService.trackEvent(this.mediaList[this.activeIndex], TRACKING_TYPES.VIEWED);
  }

  ngOnInit(): void {
    this.lightboxService.activeResource = this.mediaList[this.activeIndex];
  }
}
