import { MediaImageModel } from '@modules/media/models/media-image.model';
import { fullname } from 'src/helpers/strings';

import { ContactPersonInterface } from '../interfaces/contact-person.interface';

export class ContactPersonModel {
  id: string;
  type = 'contact-people';
  firstname: string;
  lastname: string;
  fullname: string;
  initials: string;
  title: string;
  position: string;
  email: string;
  phone: string;
  fax: string;
  image: MediaImageModel | null;
  x?: string;
  linkedIn?: string;

  constructor(data: ContactPersonInterface) {
    this.id = data.id || '';
    this.firstname = data.firstname || '';
    this.lastname = data.lastname || '';
    this.fullname = fullname(this.firstname, this.lastname);

    this.initials = `${this.firstname.charAt(0)}${this.lastname.charAt(0)}` || data.initials || '';
    this.title = data.title || '';
    this.position = data.position || '';
    this.email = data.email || '';
    this.phone = data.mobile || data.phone || '';
    this.fax = data.fax || '';
    this.image = data.image ? new MediaImageModel(data.image) : null;

    this.x = data.x;
    this.linkedIn = data.linkedIn;
  }
}
