import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollLockService } from '@modules/shared/services/scroll-lock.service';

@Component({
  selector: 'mb-header-search-input-field',
  templateUrl: './header-search-input-field.component.html',
  styleUrls: ['./header-search-input-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSearchInputFieldComponent implements AfterViewInit {
  @ViewChild('wbInput') wbInput!: ElementRef;

  @Output() closeSearchInputField = new EventEmitter<void>();

  public inputValue = '';

  constructor(private router: Router, private route: ActivatedRoute, private scrollLockService: ScrollLockService) {
    this.scrollLockService.lock();
  }

  ngAfterViewInit() {
    this.focusInput();
  }

  public closeSeachOverlay() {
    this.scrollLockService.unlock();
    this.closeSearchInputField.emit();
  }

  private async focusInput() {
    (await this.wbInput.nativeElement.getInputReference()).focus();
  }

  public setInputValue(event: any) {
    this.inputValue = event.detail.value;
  }

  public clearInputValue(): void {
    this.inputValue = '';
  }

  public goToSearchPage(): void {
    this.closeSeachOverlay();
    this.router.navigate(['search'], {
      queryParams: {
        q: this.inputValue,
      },
      relativeTo: this.route,
    });
  }
}
