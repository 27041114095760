import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppConfigService } from '@app/app-config.service';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { BaseModule } from '@modules/base/base.module';
import { AuthGuard } from '@modules/content-api/guard/auth.guard';
import { PreMediaGuard } from '@modules/content-api/guard/pre-media.guard';
import { RedirectGuard } from '@modules/content-api/guard/redirect.guard';
import { CsrfInterceptor } from '@modules/content-api/interceptor/csrf.interceptor';
import { ExpiredTokenInterceptor } from '@modules/content-api/interceptor/expired-token.interceptor';
import { FavoritesModule } from '@modules/favorites/favorites.module';
import { ScrollSpyModule } from '@modules/shared/scroll-spy/scroll-spy.module';
import { SharedModule } from '@modules/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LicenseNoticeService } from '@pages/license-notice/license-notice.service';
import * as hammer from 'hammerjs';
import { NgxMasonryModule } from 'ngx-masonry';
import { from, Observable } from 'rxjs';

import { AppErrorHandler } from './app-error-handler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BaseModule,
    HttpClientModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: LazyTranslateLoader },
    }),
    SharedModule.forRoot(),
    ScrollSpyModule.forRoot(),
    FavoritesModule,
    NgxMasonryModule,
  ],
  providers: [
    AppService,
    PreMediaGuard,
    AuthGuard,
    RedirectGuard,
    ApplicationinsightsAngularpluginErrorService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: AppConfigService) => () => configService.load(),
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (licenseNoticeService: LicenseNoticeService) => () => licenseNoticeService.load(),
      deps: [LicenseNoticeService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ExpiredTokenInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: AppErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
