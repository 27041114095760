<ng-container *ngIf="media">
  <ng-container [ngSwitch]="playerType">
    <mb-inline-player
      *ngSwitchCase="playerTypes.INLINE"
      [allowPictureInPicture]="allowPictureInPicture"
      [showBackButton]="showBackButton"
      [media]="media"
      [muted]="muted || hoverVideo"
      [hideTitle]="hideTitle"
      [hideBottomTitle]="hideBottomTitle"
      [autoplay]="autoplay"
      [controls]="controls"
      [loop]="loop"
      [hoverVideo]="hoverVideo"
      (capture)="setCapture($event)"
      (backButton)="backButton.emit()"
    ></mb-inline-player>
    <mb-simple-player
      *ngSwitchCase="playerTypes.SIMPLE"
      [media]="media"
      [autoplay]="autoplay"
      [loop]="loop"
      [controls]="controls"
      [hoverVideo]="hoverVideo"
      [playsInline]="playsInline"
    ></mb-simple-player>
  </ng-container>
</ng-container>
