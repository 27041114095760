import { Injectable } from '@angular/core';
import { ArticleInterface } from '@modules/article/interfaces/article.interface';
import { ArticleModel } from '@modules/article/models/article.model';
import { PressKitAdapter } from '@modules/content-api/adapter/press-kit.adapter';
import { CaptureModel } from '@modules/media/models/capture.model';
import { MediaAudioModel } from '@modules/media/models/media-audio.model';
import { MediaDocumentModel } from '@modules/media/models/media-document.model';
import { MediaImageModel } from '@modules/media/models/media-image.model';
import { MediaStreamConfigInterface, MediaStreamInterface } from '@modules/media/models/media-stream.model';
import { Camera, MediaVideoModel } from '@modules/media/models/media-video.model';
import { MediaModelType } from '@modules/media/models/media.model';
import { MediaType } from '@modules/media/models/media.types';
import { ActionIcons } from '@modules/ui/action-container/action-container.component';
import { MetaItemInterface } from '@modules/ui/meta-items/meta-items.component';

import { FavoriteContentDataInterface } from '../interfaces/favorite-content-data.interface';
import { FooterMeta } from '../interfaces/footer-meta.interface';

const ICON_MAP: any = {
  [MediaType.IMAGE]: 'mb-icon-picture',
  [MediaType.VIDEO]: 'mb-icon-video-movie',
  [MediaType.STREAM]: 'mb-icon-video-movie',
  [MediaType.DOCUMENT]: 'mb-icon-documents',
  [MediaType.AUDIO]: 'mb-icon-audio',
};

@Injectable({ providedIn: 'root' })
export class FavoritesParser {
  constructor(private pressKitAdapter: PressKitAdapter) {}

  public parse(data: any, context: any, slug = ''): FavoriteContentDataInterface {
    switch (data.type) {
      case 'image':
      case 'structured_image':
      case 'video':
      case 'audio':
      case 'document':
        return this.parseMedia(data, context, slug);
      case 'article':
        return this.parseArticle(data);
      case 'presskit':
        return this.parsePressKit(data);
      case 'capture':
        return this.parseCapture(data, context);
    }
    return this.parseMedia(data, context, slug); // TBD: remove this with logic
  }

  private parseMedia(media: MediaModelType, context: any, slug: string) {
    const footerMeta: FooterMeta = {};
    const headerMeta: MetaItemInterface[] = [];
    let poster = '';
    if (media) {
      if (media instanceof MediaVideoModel) {
        poster = media.poster || '';
      }
      if (media instanceof MediaVideoModel || media instanceof MediaAudioModel) {
        if (media.original.duration) {
          if (media.original.minutes > 1) {
            headerMeta.push({
              label: 'DURATION_MINUTES_SECONDS',
              minutes: `${media.original.minutes}`.padStart(2, '0'),
              seconds: `${media.original.seconds}`.padStart(2, '0'),
            });
          } else {
            headerMeta.push({
              label: 'DURATION_SECONDS',
              seconds: `${media.original.seconds}`.padStart(2, '0'),
            });
          }
        }
      }
      if (media instanceof MediaDocumentModel) {
        headerMeta.push({
          label: 'PAGES_N',
          count: media.pageCount,
        });
      }
      footerMeta.type = media.filetypeName;
      footerMeta.size = media.filesize;
      if (context) {
        if (context.type === 'article') {
          media.connectedData = {
            label: 'CONNECTED_ARTICLE',
            text: context.title,
            link: {
              url: `/article/${context.id}`,
            },
          };
        } else if (context.type === 'page') {
          media.connectedData = {
            label: 'CONNECTED_PAGE',
            text: context.title,
            link: {
              url: (slug === '/page/home' ? '/' : slug) || '/',
            },
          };
        } else if (context.type === 'stream') {
          media.connectedData = {
            label: 'CONNECTED_STREAM',
            text: context.title,
            link: {
              url: '/',
              queryParams: {
                video: context.id,
              },
            },
          };
        }
      }
    }
    return {
      headline: media.title,
      icon: media ? ICON_MAP[media.type] : '',
      actions: [
        { icon: ActionIcons.SHARE, class: 'btn btn-plain hidden-sm' },
        { icon: ActionIcons.DOWNLOAD },
        { icon: ActionIcons.DELETE },
      ],
      data: media,
      image: media instanceof MediaImageModel ? media : null,
      imageUrl: poster,
      headerMeta,
      footerMeta: footerMeta,
    };
  }

  private parseArticle(data: ArticleInterface) {
    const article = new ArticleModel(data);
    const footerMeta: FooterMeta = { type: 'ZIP' };
    return {
      headline: data.title,
      icon: 'mb-icon-article',
      actions: [
        { icon: ActionIcons.SHARE, class: 'btn btn-plain hidden-sm' },
        { icon: ActionIcons.DOWNLOAD },
        { icon: ActionIcons.DELETE },
      ],
      data: article,
      image: article.headerImage,
      imageUrl: null,
      headerMeta: article.metaItems,
      footerMeta,
    };
  }

  private parsePressKit(data: any) {
    const pressKit = this.pressKitAdapter.parse(data);
    const footerMeta: FooterMeta = { type: 'ZIP' };
    return {
      headline: data.title,
      icon: 'mb-icon-article',
      actions: [
        { icon: ActionIcons.SHARE, class: 'btn btn-plain hidden-sm' },
        { icon: ActionIcons.DOWNLOAD },
        { icon: ActionIcons.DELETE },
      ],
      data: pressKit,
      image: pressKit.mainImage,
      imageUrl: null,
      headerMeta: pressKit.metaItems,
      footerMeta,
    };
  }

  private parseCapture(data: any, context: MediaStreamInterface) {
    const playerConfig = context.playerConfig as MediaStreamConfigInterface;
    const capture = new CaptureModel({
      id: data.id,
      type: data.type,
      activeCamera: data.activeCanvas,
      cameraOptions: playerConfig.cameras as Camera[],
      originalVideoTime: data.originalTime,
      videoTime: data.time,
      screenshotOffset: playerConfig.screenshotOffset || 0,
      screenshotUrl: playerConfig.screenshotUrl as unknown as string,
      fuelLabel: context.fuelLabel,
      additionalData: {
        label: 'CONNECTED_STREAM',
        text: context.title,
        link: {
          url: '/',
          queryParams: {
            video: context.id,
          },
        },
      },
    });
    const footerMeta = { type: capture.filetypeName, time: capture.time };
    return {
      headline: context.title,
      icon: 'mb-icon-image-gallery',
      actions: [
        { icon: ActionIcons.SHARE, class: 'btn btn-plain hidden-sm' },
        { icon: ActionIcons.DOWNLOAD },
        { icon: ActionIcons.DELETE },
      ],
      data: capture,
      image: null,
      imageUrl: capture.originUrl,
      headerMeta: [],
      footerMeta: footerMeta,
    };
  }
}
