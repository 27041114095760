import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConsentModule } from '@modules/consent/consent.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { ShareModalService } from '@modules/shared/services/share.service';
import { AppTranslationModule } from '@modules/translation/app-translation.module';
import { UiModule } from '@modules/ui/ui.module';

import { AudioComponent } from './audio/audio.component';
import { CaptureComponent } from './capture/capture.component';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { DurationComponent } from './duration/duration.component';
import { FullscreenService } from './fullscreen.service';
import { ImageComponent } from './image/image.component';
import { MediaFlyoutComponent } from './media-flyout/media-flyout.component';
import { MediaComponent } from './media.component';
import { PdfPreviewComponent } from './pdf-preview/pdf-preview.component';
import { AudioService } from './service/audio.service';
import { PlayerService } from './service/player.service';
import { ExternalPlayerComponent } from './video/external-player/external-player.component';
import { InlinePlayerComponent } from './video/inline-player/inline-player.component';
import { SimplePlayerComponent } from './video/simple-player/simple-player.component';
import { ResolutionControllerComponent } from './video/video-controls/resolution-controller/resolution-controller.component';
import { VideoControlsComponent } from './video/video-controls/video-controls.component';
import { VideoPreviewComponent } from './video/video-controls/video-preview/video-preview.component';
import { VideoScrubberComponent } from './video/video-controls/video-scrubber/video-scrubber.component';
import { VideoComponent } from './video/video.component';

@NgModule({
  declarations: [
    ImageComponent,
    VideoComponent,
    DurationComponent,
    ResolutionControllerComponent,
    VideoPreviewComponent,
    VideoScrubberComponent,
    MediaFlyoutComponent,
    ExternalPlayerComponent,
    VideoControlsComponent,
    InlinePlayerComponent,
    CaptureComponent,
    PdfPreviewComponent,
    AudioComponent,
    MediaComponent,
    SimplePlayerComponent,
    DocumentPreviewComponent,
  ],
  exports: [
    MediaComponent,
    ImageComponent,
    VideoComponent,
    AudioComponent,
    PdfPreviewComponent,
    DocumentPreviewComponent,
    ExternalPlayerComponent,
    VideoControlsComponent,
  ],
  imports: [CommonModule, DragDropModule, UiModule, AppTranslationModule.forChild(), PipesModule, ConsentModule],
  providers: [FullscreenService, PlayerService, ShareModalService, AudioService],
})
export class MediaModule {}
