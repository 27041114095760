import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { MediaStreamModel } from '@modules/media/models/media-stream.model';
import { PlayerPageService } from '@pages/player-page/player-page.service';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PlayerResolver implements Resolve<Observable<MediaStreamModel | null>> {
  constructor(private appService: AppService, private service: PlayerPageService, private router: Router) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<MediaStreamModel | null> {
    this.appService.darkBackgroundPage();

    const playerId = route.paramMap.get('playerId');
    if (!playerId) {
      this.router.navigate(['404'], { skipLocationChange: true });
      return EMPTY;
    }

    return this.service.getStreamData(playerId).pipe(
      map((data) => {
        if (!data) {
          this.router.navigate(['404'], { skipLocationChange: true });
          return null;
        } else {
          return data;
        }
      })
    );
  }
}
