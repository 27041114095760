import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArticleModel } from '@modules/article/models/article.model';
import { ArticleAdapter } from '@modules/content-api/adapter/article.adapter';
import { ParsedEntryInterface } from '@modules/content-api/api.interface';
import { ContentAPIService } from '@modules/content-api/content-api.service';
import { of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { isUUID } from 'src/helpers/uuid';

export const ARTICLE_PAGE_PARAMS = new HttpParams().appendAll({
  include: [
    'header_image.image',
    'header_image.adjusted_image',
    'header_image.alternate_images.image',
    'header_image.alternate_images.adjusted_image',
    'press',
    'images.image',
    'images.adjusted_image',
    'documents.file',
    'videos.video',
    'videos.poster_image',
    'audios.audio',
    'topic_tree',
  ].join(),
  'fields[media--alternate_images]': 'image',
});

@Injectable({
  providedIn: 'root',
})
export class ArticlePageService {
  constructor(private apiService: ContentAPIService, private articleAdapter: ArticleAdapter) {}

  public getArticle(slugOrId: string) {
    if (isUUID(slugOrId)) {
      return this.apiService.getResource('articles', slugOrId, ARTICLE_PAGE_PARAMS).pipe(
        catchError(() => of(null)),
        map((article) => {
          if (!article) return null;

          return new ArticleModel(this.articleAdapter.parse(article));
        })
      );
    }
    // if the article is requested via slug, we have to use a filtered list request
    return this.apiService
      .getResources('articles', ARTICLE_PAGE_PARAMS.append('filter[slug.value]', `/article/${slugOrId}`))
      .pipe(
        map((data: ParsedEntryInterface[]) => {
          if (!data.length) return null;

          return new ArticleModel(this.articleAdapter.parse(data[0])) ?? null;
        }),
        shareReplay(1)
      );
  }
}
