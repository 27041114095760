import { Injectable } from '@angular/core';
import { ArticleAdapter } from '@modules/content-api/adapter/article.adapter';
import { StreamAdapter } from '@modules/content-api/adapter/stream.adapter';
import { FavoriteCardImagesStyle } from '@modules/content-api/domain/image-styles/card-image.style';
import { TranslateService } from '@ngx-translate/core';

import { Adapter } from '../../content-api/adapter/adapter';
import { MediaAdapter } from '../../content-api/adapter/media.adapter';

@Injectable({
  providedIn: 'root',
})
export class FavoritesAdapter implements Adapter<any> {
  constructor(
    private mediaAdapter: MediaAdapter,
    private articleAdapter: ArticleAdapter,
    private streamAdapter: StreamAdapter,
    private translateService: TranslateService
  ) {}

  public parse(data: any, context?: any) {
    switch (data.type) {
      case 'image':
      case 'structured_image':
      case 'video':
      case 'audio':
      case 'document':
        return this.mediaAdapter.parse(data, [], context, this.getSlugOfContext(context));
      case 'article':
        const article = this.articleAdapter.parse(data);
        // for favorites, we need to parse the header image using different image styles
        // IMPROVE: split data handling and rendering logic (which image sizes to use)
        if (data.header_image) {
          article.headerImage = this.mediaAdapter.parseImage(data.header_image, FavoriteCardImagesStyle, {
            id: data.id,
            type: 'articles',
          });
        }
        return article;
      case 'stream':
        return this.streamAdapter.parse(data);
    }
    // console.log('return default data', data);
    return data;
  }

  public getSlugOfContext(context: any) {
    if (!context || !context.slug) {
      return '';
    }
    return (
      context.slug.find(({ lang }: { lang: string }) => lang === this.translateService.currentLang.split('-')[0])
        ?.value ||
      context.slugs[0].value ||
      ''
    );
  }
}
