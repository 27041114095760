import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { ConsentModule } from '@modules/consent/consent.module';
import { MaterialFormsModule } from '@modules/forms/material-forms.module';
import { MediaModule } from '@modules/media/media.module';
import { PipesModule } from '@modules/pipes/pipes.module';
import { ToastModule } from '@modules/toast/toast.module';
import { TranslateModule } from '@ngx-translate/core';

import { UiModule } from '../ui/ui.module';

import { ActionButtonGroupComponent } from './action-button-group/action-button-group.component';
import { BaseModalComponent } from './base-modal/base-modal.component';
import { DialogComponent } from './dialog/dialog.component';
import { ElementRevealDirective } from './directives/element-reveal.directive';
import { ParallaxDirective } from './directives/parallax.directive';
import { ScreenDirective } from './directives/screen.directive';
import { LightboxMediaGalleryComponent } from './lightbox/lightbox-media-gallery/lightbox-media-gallery.component';
import { LightboxPageComponent } from './lightbox/lightbox-page.component';
import { ContentHostDirective, LightboxComponent } from './lightbox/lightbox.component';
import { BaseConfirmationComponent } from './modal/base-confirmation/base-confirmation.component';
import { ContactFormComponent } from './modal/contact-form/contact-form.component';
import { DownloadModalComponent } from './modal/download-modal/download-modal.component';
import { DownloadPrepareModalComponent } from './modal/download-prepare-modal/download-prepare-modal.component';
import { EmbargoConfirmModalComponent } from './modal/embargo-confirm-modal/embargo-confirm-modal.component';
import { EmbedCodeComponent } from './modal/embed-code/embed-code.component';
import { ErrorModalComponent } from './modal/error-modal/error-modal.component';
import { FirstFavoriteModalComponent } from './modal/first-favorite-modal/first-favorite-modal.component';
import { IcsModalComponent } from './modal/ics-modal/ics-modal.component';
import { MyMBMediaModalComponent } from './modal/my-mb-media-modal/my-mb-media-modal..component';
import { ShareModalComponent } from './modal/share-modal/share-modal.component';
import { SharedDownloadModalComponent } from './modal/shared-download-modal/shared-download-modal.component';
import { UserProfileModalComponent } from './modal/user-profile-modal/user-profile-modal.component';
import { ScrollSpyModule } from './scroll-spy/scroll-spy.module';
import { ContactService } from './services/contact.service';
import { DataLayerService } from './services/data-layer.service';
import { DialogService } from './services/dialog.service';
import { DownloadService } from './services/download.service';
import { EventsService } from './services/events.service';
import { GlobalGTMEventsService } from './services/global-gtm-events.service';
import { LightboxService } from './services/lightbox.service';
import { ModalService } from './services/modal.service';
import { ScrollToLastPositionService } from './services/scroll-to-last-position.service';
import { ShareModalService } from './services/share.service';
import { TrackingService } from './services/tracking.service';
import { WindowService } from './services/window.service';

@NgModule({
  declarations: [
    LightboxComponent,
    LightboxPageComponent,
    DialogComponent,
    ContentHostDirective,
    LightboxMediaGalleryComponent,
    BaseModalComponent,
    BaseConfirmationComponent,
    ScreenDirective,
    ParallaxDirective,
    EmbedCodeComponent,
    EmbargoConfirmModalComponent,
    IcsModalComponent,
    ErrorModalComponent,
    ContactFormComponent,
    ElementRevealDirective,
    DownloadModalComponent,
    DownloadPrepareModalComponent,
    FirstFavoriteModalComponent,
    SharedDownloadModalComponent,
    ActionButtonGroupComponent,
    UserProfileModalComponent,
    MyMBMediaModalComponent,
    ShareModalComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    ClipboardModule,
    MatDialogModule,
    MaterialFormsModule,
    TranslateModule,
    ToastModule.forRoot(),
    PipesModule,
    UiModule,
    MediaModule,
    ConsentModule,
  ],
  exports: [
    PipesModule,
    MaterialFormsModule,
    UiModule,
    BaseModalComponent,
    TranslateModule,
    ScrollSpyModule,
    MediaModule,
    ScreenDirective,
    ParallaxDirective,
    ElementRevealDirective,
    ConsentModule,
    ActionButtonGroupComponent,
    MyMBMediaModalComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        WindowService,
        ShareModalService,
        DownloadService,
        LightboxService,
        DialogService,
        EventsService,
        ContactService,
        ScrollToLastPositionService,
        TrackingService,
        DataLayerService,
        GlobalGTMEventsService,
        ModalService,
      ],
    };
  }
}
