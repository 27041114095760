import { ContactPersonModel } from '@models/contact-person.model';
import { FavorableContent } from '@modules/favorites/interfaces/favorable-content.interface';
import { FavoriteDataInterface } from '@modules/favorites/interfaces/favorite-data.interface';
import { MediaAudioModel } from '@modules/media/models/media-audio.model';
import { MediaDocumentModel } from '@modules/media/models/media-document.model';
import { MediaImageModel } from '@modules/media/models/media-image.model';
import { MediaVideoModel } from '@modules/media/models/media-video.model';
import { TrackingItem } from '@modules/shared/gtm/data-layer.types';
import { DownloadableContent, DownloadData } from '@modules/shared/services/download.service';
import { SharableContent, SharingData } from '@modules/shared/services/share.service';
import { MetaItemInterface } from '@modules/ui/meta-items/meta-items.component';

import { ArticleInterface } from '../interfaces/article.interface';

export class ArticleModel implements SharableContent, DownloadableContent, FavorableContent {
  public id: string;
  public marsOriginId?: string;
  public type: string;
  public title: string;
  public slug: string;
  public headerImage: MediaImageModel | null;
  public content: string;
  public slugs: { value: string; lang: string }[];
  public fuelLabel: string;
  public displayDate: string;
  public location: string;
  public contacts: ContactPersonModel[];

  public images: MediaImageModel[];
  public audios: MediaAudioModel[];
  public documents: MediaDocumentModel[];
  public videos: MediaVideoModel[];

  public imageCount: number = 0;
  public videoCount: number = 0;
  public audioCount: number = 0;
  public documentCount: number = 0;

  public metaItems: MetaItemInterface[];

  public favoriteData: FavoriteDataInterface;
  public downloadData: DownloadData;
  public shareData: SharingData;
  public trackingData: TrackingItem;

  public topicId?: string;

  constructor(data: ArticleInterface) {
    this.id = data.id;
    this.marsOriginId = data.marsOriginId;
    this.topicId = data.topicId;
    this.type = data.type;
    this.title = data.title;
    this.slug = data.slug;
    this.slugs = data.slugs;
    this.fuelLabel = data.fuelLabel;
    this.displayDate = data.displayDate;
    this.location = data.location;
    this.headerImage = data.headerImage ? new MediaImageModel(data.headerImage) : null;
    this.content = data.content;
    this.contacts = data.contacts?.map((contact) => new ContactPersonModel(contact)) || [];

    this.images = data.images.map((images) => new MediaImageModel(images)) || [];
    this.documents = data.documents?.map((document) => new MediaDocumentModel(document)) || [];
    this.audios = data.audios?.map((audio) => new MediaAudioModel(audio)) || [];
    this.videos = data.videos?.map((video) => new MediaVideoModel(video)) || [];

    this.imageCount = data.imageCount || this.images.length;
    this.videoCount = data.videoCount || this.videos.length;
    this.audioCount = data.audioCount || this.audios.length;
    this.documentCount = data.documentCount || this.documents.length;
    this.metaItems = [
      {
        label: this.imageCount === 1 ? 'IMAGE_N' : 'IMAGES_N',
        count: this.imageCount,
        data: 'article_image_section',
      },
      {
        label: this.videoCount === 1 ? 'VIDEO_N' : 'VIDEOS_N',
        count: this.videoCount,
        data: 'article_video_section',
      },
      {
        label: this.audioCount === 1 ? 'AUDIO_N' : 'AUDIOS_N',
        count: this.audioCount,
        data: 'article_audio_section',
      },
      {
        label: this.documentCount === 1 ? 'DOCUMENT_N' : 'DOCUMENTS_N',
        count: this.documentCount,
        data: 'article_document_section',
      },
    ];

    this.favoriteData = {
      id: this.id,
      type: 'article',
    };

    const shareUrl =
      this.type === 'presskit_article' ? `/press-kit/${data.pressKitId}/article/${data.id}` : `/article/${data.id}`;

    this.shareData = {
      title: data.title,
      content: data.content,
      url: location.origin + shareUrl,
    };

    this.downloadData = {
      id: data.id,
      type: 'article',
      fileType: 'article',
      format: 'ZIP',
      title: data.title,
      assets: {
        imageCount: data.imageCount,
        videoCount: data.videoCount,
        audioCount: data.audioCount,
        documentCount: data.documentCount,
      },
    };

    this.trackingData = {
      id: this.id,
      type: this.type === 'presskit_article' ? 'press_kit_article' : 'press_information',
      title: this.title,
      marsOriginId: this.marsOriginId,
      shelfNumber: undefined,
    };
  }
}
