// export const LANGUAGE_MAP = {
//   'de-DE': 'Deutsch',
//   'en-US': 'English',
//   'en-GB': 'English',
//   'zh-HANS': '中文',
//   'ko-KR': '한국어',
//   'ja-JP': '日本語',
//   'fr-FR': 'Français',
//   'it-IT': 'Italiano',
//   'es-ES': 'Español',
//   'ru-RU': 'Pусский',
//   'pl-PL': 'Polskie',
//   'nl-NL': 'Nederlands',
//   'pt-PT': 'Português',
// };
export const LANGUAGE_MAP = {
  de: 'Deutsch',
  en: 'English',
  'en-gb': 'English',
  'zh-hans': '中文',
  ko: '한국어',
  ja: '日本語',
  fr: 'Français',
  it: 'Italiano',
  es: 'Español',
  ru: 'Pусский',
  pl: 'Polskie',
  nl: 'Nederlands',
  pt: 'Português',
};

const _LANG_MAP_KEYS = Object.keys(LANGUAGE_MAP);

export type LANGUAGE_MAP_TYPE = typeof _LANG_MAP_KEYS;
