<div *ngIf="!controls; else templateWithControls" class="wrapper flyout-wrapper" (click)="openMedia()">
  <mb-buffering></mb-buffering>
  <img [src]="capture.thumbUrl" [alt]="'Capture ' + capture.time" />
</div>

<ng-template #templateWithControls>
  <div class="wrapper">
    <img [src]="capture.originUrl" [alt]="'capture' + capture.time" />
    <div class="capture-controls">
      <div class="control-toggle" [class.hide]="showControls">
        <div class="btn btn-quaternary" (click)="toggleControls()">
          <span class="mb-icon mb-icon-time"></span>
          {{ 'CAPTURE_ADJUST_TIME' | translate }}
        </div>
      </div>
      <div class="control-controls" [class.show]="showControls">
        <mb-dropdown
          *ngIf="cameraOptions.length > 1"
          [openTop]="true"
          [options]="cameraOptions"
          [value]="capture.activeCamera"
          (selectionChanged)="changePosition($event)"
        ></mb-dropdown>

        <div class="capture-center-inner" [class.pt-10]="cameraOptions.length > 0">
          <button class="btn btn-round" (click)="capture.prev()" [disabled]="!capture.hasPrev">
            <span class="mb-icon mb-icon-double-arrow-back"></span>
          </button>
          <div
            class="capture-selector first"
            (click)="capture.seekTo(-1.5)"
            [class.disabled]="!capture.possibleSeekPositions[0]"
            [class.active]="capture.seekPosition === -1.5"
          ></div>
          <div
            class="capture-selector"
            (click)="capture.seekTo(-1)"
            [class.disabled]="!capture.possibleSeekPositions[1]"
            [class.active]="capture.seekPosition === -1"
          ></div>
          <div
            class="capture-selector"
            (click)="capture.seekTo(-0.5)"
            [class.disabled]="!capture.possibleSeekPositions[2]"
            [class.active]="capture.seekPosition === -0.5"
          ></div>
          <div
            class="capture-selector"
            (click)="capture.seekTo(0)"
            [class.disabled]="!capture.possibleSeekPositions[3]"
            [class.active]="capture.seekPosition === 0"
          ></div>
          <div
            class="capture-selector"
            (click)="capture.seekTo(0.5)"
            [class.disabled]="!capture.possibleSeekPositions[4]"
            [class.active]="capture.seekPosition === 0.5"
          ></div>
          <div
            class="capture-selector"
            (click)="capture.seekTo(1)"
            [class.disabled]="!capture.possibleSeekPositions[5]"
            [class.active]="capture.seekPosition === 1"
          ></div>
          <div
            class="capture-selector last"
            (click)="capture.seekTo(1.5)"
            [class.disabled]="!capture.possibleSeekPositions[6]"
            [class.active]="capture.seekPosition === 1.5"
          ></div>
          <button class="btn btn-round" (click)="capture.next()" [disabled]="!capture.hasNext">
            <span class="mb-icon mb-icon-double-arrow-forward"></span>
          </button>
        </div>

        <div class="capture-control-buttons">
          <button class="btn btn-quaternary mb-capture-button" [disabled]="!capture.isEdited" (click)="saveEdit()">
            <span class="mb-icon mb-icon-marked-ok"></span>
            <span>{{ 'Confirm' | translate }}</span>
          </button>
          <button class="btn btn-quaternary mb-capture-button" (click)="cancelEdit()">
            <span class="mb-icon mb-icon-close"></span>
            <span>{{ 'Cancel' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
