import { ComponentRef } from '@angular/core';
import { DataLayerService } from '@modules/shared/services/data-layer.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import * as videojs from 'video.js';

import { FullscreenService } from '../fullscreen.service';
import { MediaFlyoutComponent } from '../media-flyout/media-flyout.component';

import { MediaVideoModel } from './media-video.model';

declare const document: any;

export interface PlayerModelOptionsInterface {
  muted?: boolean;
  autoplay?: boolean;
  loop?: boolean;
}
videojs.default.log.level('error'); // disable warn logs from videojs
const VIDEO_DEFAULT_VOLUME = 100;
export class PlayerModel {
  private seekDisabled = false;
  private fullscreenTarget: any;
  private previousVolume = VIDEO_DEFAULT_VOLUME;
  private currentPlayerTimeHolder = 0;
  public playing$ = new BehaviorSubject(false);
  public muted$ = new BehaviorSubject<boolean>(false);

  public player: any;
  public videoEl: any;
  public flyoutRef?: ComponentRef<MediaFlyoutComponent> | null = null;

  public currentTime$ = new BehaviorSubject<number>(0);
  public duration$ = new BehaviorSubject<number>(0);
  public volume$ = new BehaviorSubject<number>(VIDEO_DEFAULT_VOLUME);
  public audioTracks$ = new BehaviorSubject([]);
  public hasVolume$ = new BehaviorSubject<boolean>(true);
  public resolutions$ = new BehaviorSubject<any[]>([]);
  public play$ = new ReplaySubject();
  public pause$ = new ReplaySubject();

  public activeBitrate: number = Infinity;
  public canFullscreen = false;
  public position = 0;

  public pictrueInPicture: any;

  constructor(
    public playerData: MediaVideoModel,
    private fullscreenService: FullscreenService,
    private dataLayerService: DataLayerService,
    options?: PlayerModelOptionsInterface
  ) {
    this.canFullscreen = this.fullscreenService.isSupported();
    this.init(options);
  }

  public get playing() {
    return this.playing$.value;
  }

  private init(options: PlayerModelOptionsInterface = {}) {
    this.videoEl = document.createElement('video');
    if (this.playerData.poster) {
      this.videoEl.setAttribute('poster', this.playerData.poster);
    }
    if (this.playerData.isComposed) {
      this.setPosition(0);
    }
    const sources = this.playerData.sources;
    this.player = videojs.default(this.videoEl, {
      defaultVolume: VIDEO_DEFAULT_VOLUME,
      textTrackSettings: false,
      errorDisplay: false,
      autoplay: !!options.autoplay,
      preload: 'metadata',
      controls: false,
      loop: !!options.loop,
      sources: sources,
      playsinline: true,
      loadingSpinner: false,
      bigPlayButton: false,
      muted: !!options.muted,
      controlBar: false,
    } as any);

    const canPlayListener = () => {
      const el = this.videoEl;
      const hasAudio =
        el.mozHasAudio || el.webkitAudioDecodedByteCount > 0 || (el.audioTracks && el.audioTracks.length);
      this.hasVolume$.next(hasAudio);
      this.audioTracks$.next(this.player.tech_.audioTracks().tracks_);
      this.playerData.isStream
        ? this.resolutions$.next(this.filterDuplicateResolutions(this.player.tech_.vhs?.representations()) || [])
        : this.resolutions$.next(this.player.tech_.vhs?.representations() || []);

      this.duration$.next(this.duration as number);
      this.off('loadmetadata', canPlayListener);
    };
    this.on('canplay', canPlayListener);
    this.on('play', () => {
      this.playing$.next(true);
    });
    this.on('stop', () => {
      this.playing$.next(false);
    });
    this.on('pause', () => {
      this.playing$.next(false);
    });
    this.on('ended', () => {
      this.stop();
    });
    this.on('mute', () => {
      this.muted$.next(true);
    });
    this.on('unmute', () => {
      this.muted$.next(false);
    });
    this.on('canplay', canPlayListener);

    const durationListener = () => {
      this.duration$.next(this.duration as number);
      this.off('durationchange', durationListener);
    };
    this.on('durationchange', durationListener);
    const metadataLoadFn = () => {
      if (!this.playerData.showScrubberBar && this.videoEl.duration !== Infinity) {
        setTimeout(() => {
          // need a timeout for ios
          // otherwise the events wont register on the element
          this.disableSeek();
        }, 100);
      }
      this.off('loadmetadata', metadataLoadFn);
    };
    this.on('loadedmetadata', metadataLoadFn);
    this.on('timeupdate', () => {
      this.currentTime$.next(this.currentTime);
    });
  }

  public setPosition(pos: number = 0) {
    const vid = this.videoEl;
    switch (pos) {
      case 0:
        vid.style.transformOrigin = 'top left';
        break;
      case 1:
        vid.style.transformOrigin = 'top right';
        break;
      case 2:
        vid.style.transformOrigin = 'bottom left';
        break;
      case 3:
        vid.style.transformOrigin = 'bottom right';
        break;
    }
    this.position = pos;
  }

  public setFullscreenTarget(el: any) {
    this.fullscreenTarget = el;
  }

  public toggleFullscreen(open?: boolean) {
    const toggle = (previousState: boolean) => {
      if (previousState) {
        this.fullscreenService.cancel();
      } else {
        this.fullscreenService.request(this.fullscreenTarget);
      }
    };
    if (this.fullscreenService) {
      toggle(open !== undefined ? !open : this.isFullscreen);
    }
  }

  public requestPictureInPicture() {
    this.player.requestPictureInPicture();
  }

  public togglePlay() {
    if (!this.playing) {
      this.play();
    } else {
      this.pause();
    }
  }

  public play() {
    this.player.play();
    this.play$.next(true);
    this.trackEvent('Play');
  }

  public pause() {
    this.player.pause();
    this.pause$.next(true);
    this.trackEvent('Pause');
  }

  public stop() {
    this.trackEvent('Stop');
    this.setTime(0);
    this.playing$.next(false);
  }

  public setTime(time: number) {
    this.player.currentTime(time);
    this.currentTime$.next(time);
  }

  public setVolume(volume: number, setPreviousVolume = true) {
    this.videoEl.volume = volume / 100;
    if (!volume && !this.muted$.value) {
      this.mute();
    } else if (volume && this.muted$.value) {
      this.unmute();
    }
    if (setPreviousVolume) {
      this.previousVolume = volume;
    }
    this.volume$.next(this.videoEl.volume * 100);
  }

  public setBitrate(bitrate: number) {
    this.activeBitrate = bitrate;
    this.player
      .tech()
      .vhs.representations()
      .forEach((representation: any) => {
        // automatic quality (bitrate === infinity) should enable all representations,
        // this will allow the adaptive bitrate algorithm to select the representation
        representation.enabled(representation.bandwidth === bitrate || bitrate === Infinity);
      });

    this.trackResolutionChangeEvent();
  }

  public mute() {
    this.player.muted(true);
    this.muted$.next(true);
    this.setVolume(0, false);
  }

  public unmute() {
    this.player.muted(false);
    this.muted$.next(false);
    this.setVolume(this.previousVolume || 20);
  }

  public get currentTime(): number {
    return this.player.currentTime();
  }
  public get duration(): number {
    return this.player.duration();
  }

  public get isFullscreen() {
    return !!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement);
  }

  on(e: string, cb: any) {
    this.player.on(e, cb);
  }
  off(e: string, cb: any) {
    this.player.off(e, cb);
  }

  /**
   * Catch seek events and reset video to last stored timestamp.
   */
  private disableSeek() {
    if (!this.seekDisabled) {
      return;
    }
    this.seekDisabled = true;
    const video = this.videoEl;
    const _this = this;
    // store current time

    video.addEventListener('timeupdate', function () {
      if (!video.seeking) {
        _this.currentPlayerTimeHolder = video.currentTime;
      }
    });
    // prevent user from seeking
    video.addEventListener('seeking', function (e: any) {
      e.preventDefault();
      // guard agains infinite recursion:
      // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
      const delta = video.currentTime - _this.currentPlayerTimeHolder;
      if (Math.abs(delta) > 0.01) {
        video.currentTime = _this.currentPlayerTimeHolder;
      }
    });
  }

  private filterDuplicateResolutions(resolutions: any[]): any[] {
    // Resolutions come sorted in descending order by both height and bandwidth
    let i = 0;
    for (let j = 1; j < resolutions.length; j++) {
      if (resolutions[i].height === resolutions[j].height) {
        resolutions.splice(i, 1);
      }
      i++;
    }

    return resolutions;
  }

  private currentResolution(): string {
    const activeResolution = this.resolutions$.value.find((resolution) => resolution.bandwidth === this.activeBitrate);
    if (!activeResolution) {
      return 'auto';
    }
    return `${this.playerData.isComposed ? activeResolution.height / 2 : activeResolution.height}p`;
  }

  private trackEvent(event: 'Play' | 'Pause' | 'Stop') {
    if (this.playerData.isStream) {
      this.trackStreamEvent(('stream' + event) as 'streamPlay' | 'streamPause' | 'streamStop');
    } else {
      this.trackVideoEvent(('video' + event) as 'videoPlay' | 'videoPause' | 'videoStop');
    }
  }

  private trackVideoEvent(event: 'videoPlay' | 'videoPause' | 'videoStop') {
    this.dataLayerService.pushEvent({
      event,
      video: {
        id: this.playerData.id,
        title: this.playerData.title,
        marsOriginId: this.playerData.marsOriginId ?? undefined,
        shelfNumber: this.playerData.marsShelfNumber ?? undefined,
      },
      currentTime: this.player.currentTime(),
    });
  }

  private trackStreamEvent(event: 'streamPlay' | 'streamPause' | 'streamStop') {
    this.dataLayerService.pushEvent({
      event,
      stream: {
        id: this.playerData.id,
        title: this.playerData.title,
        status: this.playerData.status,
        isMultiAngle: this.playerData.isComposed,
        hasTranscript: !!this.playerData.transcript,
      },
      currentTime: this.player.currentTime(),
    });
  }

  private trackResolutionChangeEvent() {
    this.dataLayerService.pushEvent({
      event: 'streamResolutionChanged',
      stream: {
        id: this.playerData.id,
        title: this.playerData.title,
        status: this.playerData.status,
        isMultiAngle: this.playerData.isComposed,
        hasTranscript: !!this.playerData.transcript,
      },
      resolution: this.currentResolution(),
    });
  }
}
