import { JSONAPIObjectRelationshipDataList, JsonApiResult, ParsedEntryInterface } from '../api.interface';

export class ContentAPIParser {
  public static parseEntry(entry: JsonApiResult, included: JsonApiResult[] = []): ParsedEntryInterface {
    const attributes = entry.attributes || {};
    const relationshipData: {
      [key: string]: ParsedEntryInterface | ParsedEntryInterface[] | null;
    } = {};
    const res: any = {
      id: entry.id,
      type: entry.type,
      ...attributes,
    };
    const _relationships: any = {};

    if (entry.relationships) {
      for (let key in entry.relationships) {
        _relationships[key] = entry.relationships[key].data;
        if ((entry.relationships[key] as JSONAPIObjectRelationshipDataList).data) {
          const relationships = entry.relationships[key] as JSONAPIObjectRelationshipDataList;
          relationshipData[key] = this._getRelationships(relationships.data, included);
        } else {
          relationshipData[key] = this._getRelationships(entry.relationships[key], included);
        }
      }
      if (entry.relationships.data) {
        entry.relationships.data;
      }
    }
    return {
      ...res,
      ...relationshipData,
      relationships: _relationships,
    };
  }

  public static getRelationships(
    entry: { id: string; type: string },
    included: JsonApiResult[]
  ): ParsedEntryInterface | null {
    const foundRelationship = included.find((include) => include.id === entry.id && include.type === entry.type);
    if (!foundRelationship) {
      return null;
    }
    return this.parseEntry(foundRelationship, included);
  }

  private static _getRelationships(data: ParsedEntryInterface | ParsedEntryInterface[], included: JsonApiResult[]) {
    if (Array.isArray(data)) {
      const items: ParsedEntryInterface[] = [];
      data.forEach((_rel) => {
        const relationshipItem = this.getRelationships({ id: _rel.id, type: _rel.type }, included);
        if (relationshipItem) {
          if (_rel.meta) {
            relationshipItem.meta = _rel.meta;
          }
          items.push(relationshipItem);
        }
      });
      return items;
    }
    if (data.id) {
      const foundRelationship = this.getRelationships({ id: data.id, type: data.type }, included);
      if (foundRelationship) {
        if (!foundRelationship.relationships) {
          foundRelationship.relationships = {};
        }
        foundRelationship.relationships.meta = data.meta;
      }
      return foundRelationship;
    }
    return null;
  }
}
