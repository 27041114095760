import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Filetype } from '@modules/card/enums/filetype.enum';

@Component({
  selector: 'mb-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewComponent {
  @Input()
  set url(v: any) {
    const x = this.sanitizer.bypassSecurityTrustResourceUrl(v);
    this._url = x;
  }
  get url() {
    return this._url;
  }

  @Input() title!: string;
  @Input() filetype!: Filetype;
  @Input() filetypeName!: string;
  @Input() showOpenButton: boolean = true;

  private _url!: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}
}
